import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import Chip from "@mui/material/Chip";

const CustomMultiTextFieldAutocompleteForFixedObject = ({ name, label, options, rules, multiple, size, fixObj, fixGroup, selectedRow, notEditGroups,  ...rest }) => {
  const { control, formState } = useFormContext();

  const error = formState.errors[name];
  const req = !!rules?.required;
  const fixedOptions = [{name: 'base', description: 'Доступ к посещению портала'}]

  return (
    <div style={{ position: 'relative', marginBottom: '12px', width: '100%' }}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple={multiple}
            value={value ? options.filter(option => value.some(val => val.name === option.name)) : []}
            id={name}
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={(_, selectedOptions) => {
                if(fixGroup.indexOf(selectedRow) !== -1){
                    onChange([...fixedOptions,
                        ...selectedOptions.filter((option) => fixedOptions.indexOf(option) === -1)] || []);
                } else{
                    onChange(selectedOptions || []);
                }

            }}
            readOnly={!!rest.disabled}
            size={size}
            // disabled={!!rest.disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                variant="outlined"
                label={`${label}${req ? ' *' : ''}`}
                error={!!error}
                size={size}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        disabled={(fixGroup.indexOf(selectedRow) !== -1 && fixObj.indexOf(option.name) !== -1) || !!rest.disabled}
                    />
                ))
            }

          />
        )}
      />
      <span
        style={{
          position: 'absolute',
          bottom: '4px',
          fontSize: '12px',
          color: 'red',
          left: 14,
        }}
      >
        {error && (error?.message || 'Unknown error!')}
      </span>
    </div>
  );
};

export default CustomMultiTextFieldAutocompleteForFixedObject;
