import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    tree:[],
    selectedNodes: [],
    lastId: 105,
    isCtrlPressing: false,
    chosenNodes: [],
    isMoveMode: false,
    moveNode: null
};

const docsSlice = createSlice({
    name: "docs",
    initialState,
    reducers: {
        logOut() { return initialState },
        setTree(state, { payload }) {
            state.tree = [];
            payload.map((node) => {
                state.tree = [...state.tree, {
                    id: node._id,
                    parent: node.parent === "0" ? 0 : node.parent,
                    droppable: node.droppable,
                    name: node.name,
                    size: node?.size,
                    created_at: node.created_at,
                    description: node.description
                }]
            })
        },
        setMoveNode(state, { payload }) { state.moveNode = payload },
        setIsMoveMode(state, { payload }) { state.isMoveMode = payload },
        setLastId(state, { payload }) { state.lastId = payload },
        setIsCtrlPressing(state, { payload }) { state.isCtrlPressing = payload },
        setChosenNodes(state, { payload }) { state.chosenNodes = payload },
        setChosenNodesById(state, { payload }) {
            state.chosenNodes = [];
            state.chosenNodes = state.tree.filter((node) => payload.includes(node.id))
        },
        setSelectedNodes(state, { payload }) {
            state.selectedNodes = payload
        },
        updateNode(state, { payload }) {
            const {id, text, description } = payload;
            state.tree = state.tree.map((node) => {
                return (node.id === id)
                    ? {
                        ...node,
                        text: text,
                        description: description
                    }
                    : node
            })
        },
        updateParentToNote(state, { payload }) {
            const {id, parent } = payload;
            state.tree = state.tree.map((node) => {
                return (node.id === id)
                    ? {
                        ...node,
                        parent: parent
                    }
                    : node
            })

        },
        addNode(state, { payload }) {
            state.tree = [...state.tree, payload];
        },
        deleteNode(state, { payload }) {
            state.tree = state.tree.filter((node) => !payload.includes(node.id))
        }

    },
});

export const { actions: docsActions, reducer: docsReducer } = docsSlice
// export const { addNewMessage, addChat } = dialoguesSlice
export default docsSlice.reducer;
