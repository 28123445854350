import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import {Grid, IconButton, InputAdornment, Link} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { $host } from '../../http'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../hook/useActions'
import AlertMessage from '../Alert/AlertMessage'
import { FormProvider, useForm } from 'react-hook-form'
import CustomTextField from '../Fields/CustomTextField'
import { useSelector } from 'react-redux'

export function LoginForm () {
  const { t } = useTranslation()

  const {
    logIn,
    setToken,
    setInfoAlertMessage,
    resetAllAlertMessage,
  } = useActions()

  const [showPassword, setShowPassword] = React.useState(false)
  const {
    userId,
  } = useSelector(state => state.authReducer)

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
  } = methods

  const handleClickShowPassword = () => setShowPassword(prevState => !prevState)

  const onSubmit = async (data) => {
    try {
      const response = await $host.post('/user/login', data)
      const utcResponseTime = new Date(response.headers['date'])
      const startTime = utcResponseTime.getTime() //localTimeMilliseconds
      const { token } = response.data
      setToken({ token, startTime })
    } catch (e) {
      if (e.response.status === 403) {
        setInfoAlertMessage(t('src.sections.auth.login.wrongLoginPassword'))
      }
    }
  }

  useEffect(() => {
    resetAllAlertMessage()
    if (userId) {
      return <Navigate to="/"/>
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 2 }}>
          <CustomTextField
            name="email"
            label={t('src.sections.auth.login.login')}
            registerOptions={{
              required: t('main.another.requiredField'),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('main.another.wrongInput'),
              },
            }}
            required
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            autoComplete="email"
            autoFocus
          />
          <CustomTextField
            name="password"
            label={t('src.sections.auth.login.password')}
            registerOptions={{
              required: t('main.another.requiredField'),
              pattern: {
                value: /^[!-~]{1,32}$/,
                message: t('main.another.wrongInput'),
              },
            }}
            required
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mb: 2 }}
          >
            {t('src.sections.auth.login.LoginForm.goInto')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2">
                {t('src.pages.Register.forgotPassword')}
              </Link>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Link href="/register" variant="body2">*/}
            {/*    {t('src.pages.Login.noAccountYet')}*/}
            {/*    {t('src.pages.Login.registerNow')}*/}
            {/*  </Link>*/}
            {/*</Grid>*/}
          </Grid>
          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}
