import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete, Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton, Popover,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {useActions} from '../../hook/useActions'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {faker} from '@faker-js/faker'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/Add'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {$authHost} from '../../http'
import CloseIcon from '@mui/icons-material/Close'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimeField} from "@mui/x-date-pickers";
import {ChromePicker} from "react-color";
import WidgetPreview from "../../components/WidgetPreview";
import dayjs from "dayjs";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {acceptFileType} from "../../constants";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomViewField from "../../components/Fields/CustomViewField";
import {baseUrl} from "../../http/baseRoute";
import {LoadingButton} from "@mui/lab";
import BlockFormModal from "../../components/BlockFormModal";
import useResponsive from "../../hook/useResponsive";
import LoadingSpinner from "../../components/modals/loadingSpinner";

const ChannelsForm = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false);

  // const {setDisableAvailability, setIsTelegramType} = props;

  const {
    setChannels,
    setChannelsTypeFormIsAdd,
    setSelectedChannel,
    addQuestions,
    deleteQuestions,
    setSelectedQuestions,
    updateQuestionInChannelsQuestions,
    setExpanded,
    addExpandedPanel,
    setDisableAvailability,
    setIsTelegramType
  } = useActions()

  const {
    channels,
    channelsTypeFormIsAdd,
    selectedChannel,
    questions,
    expanded = [],
    selectedQuestions,
    disableAvailability,
    isTelegramType
  } = useSelector(state => state.channelsReducer)

  const {
    setIntelligens,
  } = useActions()
  const {
    intelligences,
  } = useSelector(state => state.intelligensReducer)

  const isMobile = useResponsive('down', 'md')

  const ChannelsType = ['Telegram', 'Web', 'WhatsApp', 'Teams', 'Discord', 'GetCourse']

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      color_window: '#0AA89A',
      color_message: '#0AA89A',
      color_chat_button: '#0AA89A',
      color_send_button: '#0AA89A',
      color_background: '#FFFFFF',
    }
  })
  const {
    register: registerUpdate,
    formState: {errors: errorsUpdate, isDirty, isSubmitSuccessful},
    handleSubmit: handleSubmitUpdate,
    reset: resetUpdate,
    setValue: setValueUpdate,
    control: controlUpdate,
    watch,
    setError
  } = methods


  const onError = (errors, e) => {
    // console.log(errors)
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((key) => {
        switch (key) {
          case 'name':
          case 'intellect':
          case 'channel_type':
            addExpandedPanel('panel1');
            break;
          case 'approval_domains':
          case 'chat_title':
          case 'color_background':
          case 'color_window':
          case 'color_message':
          case 'color_chat_button':
          case 'color_send_button':
            addExpandedPanel('panel2');
            break;
          case 'token':
            addExpandedPanel('panel3');
            break;
          case 'discord_token':
            addExpandedPanel('panel4');
            break;
          case 'wapp_token':
          case 'wapp_id':
            addExpandedPanel('panel5');
            break;
          case 'az_client_id':
          case 'az_tenant_id':
          case 'az_username':
          case 'az_password':
            addExpandedPanel('panel6');
            break;
          case 'is_around_the_clock':
          case 'time_start':
          case 'time_end':
          case 'time_zone':
          case 'absence_message':
            addExpandedPanel('panel7');
            break;
          case 'introduction':
          case 'auto_switch_message':
          case 'manual_switch_message':
            addExpandedPanel('panel8');
            break;
          default:
            break;
        }
      })
    }
  }

  const watchIntellect = watch('intellect');

  const watchChannelType = watch('channel_type');

  const [showButtonTogglerColor, setShowButtonTogglerColor] = useState(false);
  const watchButtonTogglerColor = watch('color_chat_button');

  const [showMessageColor, setShowMessageColor] = useState(false);
  const watchMessageColor = watch('color_message');

  const [showMainColor, setShowMainColor] = useState(false);
  const watchMainColor = watch('color_window');

  const [showBackgroundColor, setShowBackgroundColor] = useState(false);
  const watchBackgroundColor = watch('color_background');

  const [showButtonSendColor, setShowButtonSendColor] = useState(false);
  const watchButtonSendColor = watch('color_send_button');

  const watchAllTimeWork = watch('is_around_the_clock');

  useEffect(() => {
    if (watchChannelType === 'Telegram') {
      setIsTelegramType(true);

      if (channelsTypeFormIsAdd) {
        setDisableAvailability(true);
      } else {
        setDisableAvailability(false);
      }
    } else {
      setIsTelegramType(false);

    }
  }, [watchChannelType])

  useEffect(() => {
    if (!watchIntellect) {
      setValueUpdate('is_run', false)
    }
  }, [watchIntellect])

  const getIntellegences = useCallback(() => {
    try {
      const newIntellegencesList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newIntellegencesList().then((data) => {
        setIntelligens(data)
      })
    } catch (e) {
    }
  }, [])


  const AnswerType = [
    t('src.pages.forms.ChannelsForm.username'),
    t('src.pages.forms.ChannelsForm.text'),
    t('src.pages.forms.ChannelsForm.numbers'),
    t('src.pages.forms.ChannelsForm.date'),
    t('src.pages.forms.ChannelsForm.telephone'),
    t('src.pages.forms.ChannelsForm.address'),
    t('src.pages.forms.ChannelsForm.email'),
    t('src.pages.forms.ChannelsForm.site'),
  ]

  const handleDeletQuestion = (index) => {
    deleteQuestions(index)
  }

  const pluseHandler = event => {
    addQuestions({index: faker.datatype.uuid(), question: '', type: AnswerType[0]})
  }

  const onSubmit = async (data) => {
    setLoading(true)
    switch (channelsTypeFormIsAdd) {
      case true:
        var add_data = data
        add_data.work_days = workingDays.sort().map((d) => days[d].value)
        add_data.time_zone = data.time_zone.value;
        add_data.time_start = dayjs(data.time_start).format('HH:mm');
        add_data.time_end = dayjs(data.time_end).format('HH:mm');
        add_data['custom_count'] = selectedQuestions.length
        if (uploadedAvatar) {
          add_data.logo = uploadedAvatar;
        }
        if (data.intellect && data.intellect.name === undefined) {
          add_data['intellect'] = data.intellect
        } else if (data.intellect && data.intellect.name !== undefined) {
          add_data['intellect'] = data.intellect.name
        }
        selectedQuestions.map((question, index) => {
          add_data[`custom_${index + 1}`] = question.question
          add_data[`custom_${index + 1}_type`] = question.type

        })
        // console.log("add_data", add_data)
        try {
          await $authHost.post(`/channel/insert_one`, add_data)
            .then(response => {
              if (watchChannelType === 'Web') {
                setWidgetID(response.data)
              }
            })
        } catch (e) {
          setError('root.serverError', {
            type: 'server',
            message: e.message,
          });
        } finally {
          setLoading(false)
        }
        break
      case false:
        var update_data = data
        update_data.work_days = workingDays.sort().map((d) => days[d].value)
        update_data.time_zone = data.time_zone.value;
        update_data.time_start = dayjs(data.time_start).format('HH:mm');
        update_data.time_end = dayjs(data.time_end).format('HH:mm');
        update_data['custom_count'] = selectedQuestions.length
        update_data['id'] = selectedChannel.id
        update_data.logo = uploadedAvatar || selectedChannel.logo;
        if (data.intellect && data.intellect.name === undefined) {
          update_data['intellect'] = data.intellect
        } else if (data.intellect && data.intellect.name !== undefined) {
          update_data['intellect'] = data.intellect.name
        }
        selectedQuestions.map((question, index) => {
          update_data[`custom_${index + 1}`] = question.question
          update_data[`custom_${index + 1}_type`] = question.type

        })
        try {
          await $authHost.patch(`/channel/update_one`, update_data)
        } catch (e) {
          setError('root.serverError', {
            type: 'server',
            message: e.message,
          });
        } finally {
          setLoading(false)
        }
        break
      default:
        break
    }

  }

  useEffect(() => {
    if (isSubmitSuccessful && (!channelsTypeFormIsAdd || (channelsTypeFormIsAdd && watchChannelType !== 'Web'))) {
      navigate('/channels');
    }
  }, [isSubmitSuccessful]);


  function readLocation() {
    var pathArray = window.location.pathname.split('/')
    switch (pathArray[2]) {
      case 'new':
        resetUpdate()
        setChannelsTypeFormIsAdd(true)
        setSelectedChannel(null)
        setSelectedQuestions(null)
        setShowGeneratedCode(false)
        setValueUpdate('time_zone', myTimeZone)
        break
      case 'edit':
        setChannelsTypeFormIsAdd(false)
        setSelectedChannel(pathArray[3])
        setSelectedQuestions(pathArray[3])
        setValueUpdate('name', selectedChannel.name)
        setValueUpdate('intellect', selectedChannel.intellect)
        setValueUpdate('is_run', selectedChannel.is_run)
        setValueUpdate('channel_type', selectedChannel.channel_type)
        setValueUpdate('token', selectedChannel.token)
        setValueUpdate('introduction', selectedChannel.introduction)
        setValueUpdate('auto_switch_message', selectedChannel.auto_switch_message)
        setValueUpdate('manual_switch_message', selectedChannel.manual_switch_message)
        setValueUpdate('is_around_the_clock', selectedChannel.is_around_the_clock)
        setValueUpdate('approval_domains', selectedChannel.approval_domains)
        setValueUpdate('time_start', dayjs(`2023-10-10 ${selectedChannel.time_start}:00`))
        setValueUpdate('time_end', dayjs(`2023-10-10 ${selectedChannel.time_end}:00`))
        setValueUpdate('time_zone', {label: `UTC${selectedChannel.time_zone}`, value: `${selectedChannel.time_zone}`})
        setValueUpdate('absence_message', selectedChannel.absence_message)
        setValueUpdate('chat_title', selectedChannel.chat_title)
        setValueUpdate('color_background', selectedChannel.color_background)
        setValueUpdate('color_window', selectedChannel.color_window)
        setValueUpdate('color_message', selectedChannel.color_message)
        setValueUpdate('color_chat_button', selectedChannel.color_chat_button)
        setValueUpdate('color_send_button', selectedChannel.color_send_button)
        setValueUpdate('wapp_token', selectedChannel.wapp_token)
        setValueUpdate('wapp_id', selectedChannel.wapp_id)
        setValueUpdate('az_client_id', selectedChannel.az_client_id)
        setValueUpdate('az_tenant_id', selectedChannel.az_tenant_id)
        setValueUpdate('az_username', selectedChannel.az_username)
        setValueUpdate('az_password', selectedChannel.az_password)
        setValueUpdate('discord_token', selectedChannel.discord_token)
        selectedQuestions.map((question, index) => {
          setValueUpdate(`custom_${index + 1}`, question.question);
          setValueUpdate(`custom_${index + 1}_type`, question.type);
        })
        setShowGeneratedCode(true)
        if (Array.isArray(selectedChannel?.work_days)) {
          setWorkingDays(selectedChannel.work_days.map((d) => days.findIndex((day) => day.value === d)) || [])
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    readLocation()
    getIntellegences()

    return () => {
      setExpanded(['panel1'])
    }
  }, [])

  const [widgetID, setWidgetID] = useState('');
  const [showGeneratedCode, setShowGeneratedCode] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);

  const inputRef = useRef(null);

  const GeneratedCode = () => (
    <Stack sx={{p: 3}}>
      <Box sx={{pb: 2}}>
        {t('src.pages.forms.ChannelsForm.helperText')}
      </Box>
      <Box sx={{backgroundColor: 'layout.default', fontWeight: 600, width: 'max-content', borderRadius: '6px'}} p={2}>
          <pre>
            {`<script type="text/javascript">
                document.widgetID = '${widgetID}';
                const s = document.createElement('script');
                s.src = 'https://ax7static7aspex7cloud.blob.core.windows.net/$web/scripts/loader.js';
                s.type = 'text/javascript';
                s.async = true;
                document.body.appendChild(s);
              </script>`}
          </pre>
      </Box>
    </Stack>
  );

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (fileObj && (acceptFileType.indexOf(fileObj.type) !== -1) && (fileObj.size / (1024 ** 2) < 3.6)) {
      const FR = new FileReader()
      FR.addEventListener('load', function (evt) {
        setUploadedAvatar(evt.target.result)
      })
      FR.readAsDataURL(event.target.files[0])
    }
  }

  const handleClose = () => {
    navigate('/channels');
  }


  const openWidget = () => {
    if (!isMobile) {
      const body = document.getElementById('widget-body');
      body.classList.add("show-chatbot");
    }
  }

  const timezones = [
    {label: "UTC-12:00", value: "-12:00"},
    {label: "UTC-11:00", value: "-11:00"},
    {label: "UTC-10:00", value: "-10:00"},
    {label: "UTC-09:00", value: "-09:00"},
    {label: "UTC-08:00", value: "-08:00"},
    {label: "UTC-07:00", value: "-07:00"},
    {label: "UTC-06:00", value: "-06:00"},
    {label: "UTC-05:00", value: "-05:00"},
    {label: "UTC-04:00", value: "-04:00"},
    {label: "UTC-03:00", value: "-03:00"},
    {label: "UTC-02:00", value: "-02:00"},
    {label: "UTC-01:00", value: "-01:00"},
    {label: "UTC+00:00", value: "+00:00"},
    {label: "UTC+01:00", value: "+01:00"},
    {label: "UTC+02:00", value: "+02:00"},
    {label: "UTC+03:00", value: "+03:00"},
    {label: "UTC+04:00", value: "+04:00"},
    {label: "UTC+05:00", value: "+05:00"},
    {label: "UTC+06:00", value: "+06:00"},
    {label: "UTC+07:00", value: "+07:00"},
    {label: "UTC+08:00", value: "+08:00"},
    {label: "UTC+09:00", value: "+09:00"},
    {label: "UTC+10:00", value: "+10:00"},
    {label: "UTC+11:00", value: "+11:00"},
    {label: "UTC+12:00", value: "+12:00"},
    {label: "UTC+13:00", value: "+13:00"},
    {label: "UTC+14:00", value: "+14:00"},
  ];

  const myTimeZone = {label: `UTC${dayjs().format('Z')}`, value: dayjs().format('Z')};

  const [workingDays, setWorkingDays] = useState([]);

  const days = [
    {
      key: "monday",
      value: "ПН",
      label: t('src.pages.forms.ChannelsForm.monday'),
    },
    {
      key: "tuesday",
      value: "ВТ",
      label: t('src.pages.forms.ChannelsForm.tuesday'),
    },
    {
      key: "wednesday",
      value: "СР",
      label: t('src.pages.forms.ChannelsForm.wednesday'),
    },
    {
      key: "thursday",
      value: "ЧТ",
      label: t('src.pages.forms.ChannelsForm.thursday'),
    },
    {
      key: "friday",
      value: "ПТ",
      label: t('src.pages.forms.ChannelsForm.friday'),
    },
    {
      key: "saturday",
      value: "СБ",
      label: t('src.pages.forms.ChannelsForm.saturday'),
    },
    {
      key: "sunday",
      value: "ВС",
      label: t('src.pages.forms.ChannelsForm.sunday'),
    },
  ];

  const [instructionPopover, setInstructionPopover] = useState(null);

  const handleOpenInstruction = (e) => {
    setInstructionPopover(e.currentTarget);
  };

  const handleCloseInstruction = () => {
    setInstructionPopover(null);
  };


  const openInstruction = Boolean(instructionPopover);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [...expanded, panel] : [...expanded.filter((el) => el !== panel)]);
  };

  return (
    <Card sx={{mx: 0, my: 3, px: 2, py: 1}}>
      {loading && <LoadingSpinner />}
      <FormProvider {...methods}>
        {widgetID ? (<GeneratedCode/>) : (
          <form onSubmit={handleSubmitUpdate(onSubmit, onError)}>
            {isMobile &&
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{m1: 2}}
                >
                  <Grid item>
                    <Typography variant='subtitle1' color="primary">
                      {channelsTypeFormIsAdd ?
                          t('main.another.add')
                          :
                          t('main.another.edit')
                      }
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                        onClick={handleClose}
                    >
                      <CloseIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
            }
            <Grid
              container
              spacing={2}
              sx={{padding: isMobile? "0px 0px 16px 0px" : "0px 18px 18px 18px"}}
              xs={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item sx={{width: '100%'}}>
                  <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel1')}
                             onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      sx={{p: 0}}
                    >
                      <Typography variant="headerTable" color="text.primary">
                        {t('src.pages.forms.ChannelsForm.commonSettings')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0, width: '100%'}}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item sx={{width: '100%'}}>
                          <TextField
                            id="name"
                            {...registerUpdate('name', {
                              required: true,
                            })}
                            sx={{mb: 2}}
                            error={errorsUpdate?.name}
                            helperText={errorsUpdate?.name && (errorsUpdate?.name?.message ||
                              t('src.pages.forms.ChannelsForm.required'))}
                            label={t('src.pages.forms.ChannelsForm.channelName')}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item sx={{width: '100%'}}>
                          <Controller
                            control={controlUpdate}
                            name="intellect"
                            defaultValue={selectedChannel ? selectedChannel.intellect : ''}
                            render={({field}) =>
                              <Autocomplete
                                {...field}
                                id="intellect"
                                size="small"
                                sx={{mb: 2}}
                                options={intelligences && intelligences}
                                getOptionLabel={(option) => option.name ?? option}
                                isOptionEqualToValue={(option, value) => String(option.name) === String(value)}
                                onChange={(event, data) => {
                                  field.onChange(data)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="outlined"
                                    label={t('src.pages.forms.ChannelsForm.connectedAI')}
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={!!errorsUpdate.intellect}
                                  />
                                )}
                              />
                            }
                          />
                        </Grid>

                        <Grid item sx={{width: '100%'}}>
                          <Controller
                            control={controlUpdate}
                            name="channel_type"
                            rules={{required: true}}
                            defaultValue={selectedChannel ? selectedChannel.channel_type : ''}
                            render={({field}) =>
                              <Autocomplete
                                {...field}
                                id="channel_type"
                                size="small"
                                sx={{mb: 1}}
                                options={ChannelsType}
                                getOptionLabel={(option) => option}
                                isOptionEqualToValue={(option, value) => option === value}
                                onChange={(event, data) => {
                                  field.onChange(data)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="outlined"
                                    label={t('src.pages.forms.ChannelsForm.channelType')}
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={!!errorsUpdate.channel_type}
                                    helperText={errorsUpdate?.channel_type && (errorsUpdate?.channel_type?.message ||
                                      t('src.pages.forms.ChannelsForm.required'))}
                                  />
                                )}
                              />
                            }
                          />
                        </Grid>

                        <Grid item sx={{width: '100%'}}>
                          <Stack direction="row" alignItems="baseline" justifyContent="flex-start" mb={2}>
                            <Typography variant="body" sx={{ml: "5px"}}>
                              {(watchChannelType === 'Web' || watchChannelType === 'GetCourse') ?
                                t('src.components.Intelligence.stateWeb') : t('src.components.Intelligence.state')}
                            </Typography>
                            <Controller
                              name="is_run"
                              defaultValue={false}
                              control={controlUpdate}
                              render={({field}) => (
                                <Switch
                                  onChange={(e) => field.onChange(e.target.checked)}
                                  checked={field.value}
                                  disabled={!watchIntellect}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {(watchChannelType === 'Web' || watchChannelType === 'GetCourse') && (
                  <>
                    <Grid item sx={{width: '100%'}}>
                      <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel2')}
                                 onChange={handleChange('panel2')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          sx={{p: 0}}
                        >
                          <Typography variant="headerTable" color="text.primary">
                            {t('src.pages.forms.ChannelsForm.widgetSettings')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0, width: '100%'}}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('approval_domains', {
                                  required: true,
                                })}
                                size="small"
                                error={errorsUpdate?.approval_domains}
                                helperText={errorsUpdate?.approval_domains && errorsUpdate?.approval_domains?.message}
                                sx={{mb: 2}}
                                label={t('src.pages.forms.ChannelsForm.approvalDomains')}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('chat_title', {
                                  required: true,
                                })}
                                size="small"
                                error={errorsUpdate?.chat_title}
                                helperText={errorsUpdate?.chat_title && errorsUpdate?.chat_title?.message}
                                sx={{mb: 2}}
                                label={t('src.pages.forms.ChannelsForm.chat_title')}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            {/*<Grid item sx={{width: '100%'}}>*/}
                            {/*  <Typography variant="subtitle2" sx={{mb: 2}}>*/}
                            {/*    {t('src.pages.forms.ChannelsForm.work_time')}:*/}
                            {/*  </Typography>*/}
                            {/*  <Stack direction="row" alignItems="center" justifyContent="start" mb={0}>*/}
                            {/*    <Typography variant="body">*/}
                            {/*      {t('src.pages.forms.ChannelsForm.is_around_the_clock')}*/}
                            {/*    </Typography>*/}
                            {/*    <Controller*/}
                            {/*      name="is_around_the_clock"*/}
                            {/*      control={controlUpdate}*/}
                            {/*      render={({field}) => (*/}
                            {/*        <Switch*/}
                            {/*          onChange={(e) => field.onChange(e.target.checked)}*/}
                            {/*          checked={field.value}*/}
                            {/*        />*/}
                            {/*      )}*/}
                            {/*    />*/}
                            {/*  </Stack>*/}
                            {/*</Grid>*/}
                            {/*{!watchAllTimeWork && (*/}
                            {/*  <>*/}
                            {/*    <Grid item sx={{width: '100%'}}>*/}
                            {/*      <Grid container spacing={2} flexDirection='row'>*/}
                            {/*        <Grid item sx={{width: '50%'}}>*/}
                            {/*          <Controller*/}
                            {/*            name='time_start'*/}
                            {/*            control={controlUpdate}*/}
                            {/*            rules={*/}
                            {/*              {*/}
                            {/*                required: true,*/}
                            {/*              }*/}
                            {/*            }*/}
                            {/*            render={({*/}
                            {/*                       field: {onChange, value},*/}
                            {/*                       fieldState: {error},*/}
                            {/*                     }) => (*/}
                            {/*              <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                            {/*                <TimeField*/}
                            {/*                  label={t('src.pages.forms.ChannelsForm.time_start')}*/}
                            {/*                  value={dayjs(value)}*/}
                            {/*                  onChange={(e) => {*/}
                            {/*                    onChange(e);*/}
                            {/*                  }}*/}
                            {/*                  format="HH:mm"*/}
                            {/*                  size="small"*/}
                            {/*                  sx={{width: '100%', mb: 2}}*/}
                            {/*                />*/}
                            {/*              </LocalizationProvider>*/}
                            {/*            )}*/}
                            {/*          />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item sx={{width: '50%'}}>*/}
                            {/*          <Controller*/}
                            {/*            name='time_end'*/}
                            {/*            control={controlUpdate}*/}
                            {/*            rules={*/}
                            {/*              {*/}
                            {/*                required: true,*/}
                            {/*              }*/}
                            {/*            }*/}
                            {/*            render={({*/}
                            {/*                       field: {onChange, value},*/}
                            {/*                       fieldState: {error},*/}
                            {/*                     }) => (*/}
                            {/*              <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                            {/*                <TimeField*/}
                            {/*                  label={t('src.pages.forms.ChannelsForm.time_end')}*/}
                            {/*                  value={dayjs(value)}*/}
                            {/*                  onChange={(e) => {*/}
                            {/*                    onChange(e);*/}
                            {/*                  }}*/}
                            {/*                  format="HH:mm"*/}
                            {/*                  size="small"*/}
                            {/*                  sx={{width: '100%', mb: 2}}*/}
                            {/*                />*/}
                            {/*              </LocalizationProvider>*/}
                            {/*            )}*/}
                            {/*          />*/}
                            {/*        </Grid>*/}
                            {/*      </Grid>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item sx={{width: '100%'}}>*/}
                            {/*      <Controller*/}
                            {/*        control={controlUpdate}*/}
                            {/*        name="time_zone"*/}
                            {/*        rules={{required: true}}*/}
                            {/*        // defaultValue={selectedChannel ? selectedChannel.time_zone : ''}*/}
                            {/*        render={({field}) =>*/}
                            {/*          <Autocomplete*/}
                            {/*            {...field}*/}
                            {/*            size="small"*/}
                            {/*            sx={{mb: 2}}*/}
                            {/*            options={timezones}*/}
                            {/*            getOptionLabel={(option) => option.label}*/}
                            {/*            isOptionEqualToValue={(option, value) => option.value === value.value}*/}
                            {/*            onChange={(event, data) => {*/}
                            {/*              field.onChange(data)*/}
                            {/*            }} renderInput={(params) => (*/}
                            {/*            <TextField*/}
                            {/*              variant="outlined"*/}
                            {/*              label={t('src.pages.forms.ChannelsForm.timezone')}*/}
                            {/*              {...params}*/}
                            {/*              InputLabelProps={{*/}
                            {/*                shrink: true,*/}
                            {/*              }}*/}
                            {/*              error={!!errorsUpdate.time_zone}*/}
                            {/*              helperText={errorsUpdate?.time_zone && (errorsUpdate?.time_zone?.message ||*/}
                            {/*                t('src.pages.forms.ChannelsForm.required'))}*/}
                            {/*            />*/}
                            {/*          )}*/}
                            {/*          />*/}
                            {/*        }*/}
                            {/*      />*/}
                            {/*    </Grid>*/}
                            {/*  </>*/}
                            {/*)}*/}

                            {/*<Grid item sx={{width: '100%'}}>*/}
                            {/*  <ToggleButtonGroup*/}
                            {/*    size="small"*/}
                            {/*    value={workingDays}*/}
                            {/*    onChange={(event, value) => setWorkingDays(value)}*/}
                            {/*  >*/}
                            {/*    {days.map((day, index) => (*/}
                            {/*      <ToggleButton key={day.key} value={index}*/}
                            {/*        // selected={true}*/}
                            {/*                    sx={{*/}
                            {/*                      mr: 1,*/}
                            {/*                      '&:last-child': {m: 0},*/}
                            {/*                      borderRadius: '50% !important',*/}
                            {/*                      border: '1px solid rgba(145, 158, 171, 0.24) !important',*/}
                            {/*                      width: '40px',*/}
                            {/*                      height: '40px',*/}
                            {/*                      // '&.Mui-selected': {backgroundColor: '#000 !important'},*/}
                            {/*                      // '&:hover': {backgroundColor: 'pallete.layout.default'}*/}
                            {/*                    }}>*/}
                            {/*        {day.label}*/}
                            {/*      </ToggleButton>*/}
                            {/*    ))}*/}
                            {/*  </ToggleButtonGroup>*/}
                            {/*</Grid>*/}

                            {/*<Grid item sx={{width: '100%', mt: 2}}>*/}
                            {/*  <TextField*/}
                            {/*    {...registerUpdate('absence_message', {*/}
                            {/*      required: true,*/}
                            {/*    })}*/}
                            {/*    error={errorsUpdate?.introduction}*/}
                            {/*    helperText={errorsUpdate?.introduction && (errorsUpdate?.introduction?.message ||*/}
                            {/*      t('src.pages.forms.ChannelsForm.required', 'Поле обязательно к заполнению', 'ru'))}*/}
                            {/*    label={t('src.pages.forms.ChannelsForm.absence_message')}*/}
                            {/*    variant="outlined"*/}
                            {/*    InputLabelProps={{*/}
                            {/*      shrink: true,*/}
                            {/*    }}*/}
                            {/*    multiline*/}
                            {/*    rows={3}*/}
                            {/*    fullWidth*/}
                            {/*  />*/}
                            {/*</Grid>*/}

                            <Grid item sx={{width: '100%'}}>
                              <Typography variant="subtitle2" sx={{mb: 2}}>
                                {t('src.pages.forms.ChannelsForm.widget_logo')}:
                              </Typography>
                              <>
                                {(uploadedAvatar || selectedChannel?.logo) ? (
                                  <div className='containerImgWidget'>
                                    <img
                                      src={uploadedAvatar || selectedChannel?.logo}
                                      alt="logo"
                                    />
                                    <div className="overlayWidget" onClick={() => {
                                      inputRef.current.click()
                                    }}>
                                      <IconButton className="icon">
                                        <DriveFolderUploadIcon fontSize="inherit"/>
                                      </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <Box sx={{
                                    width: '100%',
                                    height: '200px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                    cursor: 'pointer'
                                  }}
                                       onClick={() => {
                                         inputRef.current.click()
                                       }}>
                                    <Stack sx={{width: '100%', height: '100%'}} alignItems='center'
                                           justifyContent='center'>
                                      <IconButton className="iconWidget">
                                        <DriveFolderUploadIcon fontSize="inherit"/>
                                      </IconButton>
                                    </Stack>
                                  </Box>
                                )}
                                <input
                                  style={{display: 'none'}}
                                  ref={inputRef}
                                  type="file"
                                  onChange={handleFileChange}
                                />
                              </>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <Stack direction="column" alignItems='start'>
                                <Typography variant="subtitle2" sx={{mb: 2}}>
                                  {t('src.pages.forms.ChannelsForm.widget_colors')}:
                                </Typography>
                                <Grid container direction='column' spacing={2}>
                                  <Grid item>
                                    <Button onClick={() => {
                                      !showBackgroundColor && openWidget();
                                      setShowBackgroundColor((prev) => !prev)
                                    }} variant='contained'
                                            sx={{
                                              backgroundColor: `${watchBackgroundColor} !important`,
                                              width: '32px',
                                              height: '32px'
                                            }}></Button>
                                    <Typography variant="body" color="text.secondary" sx={{pl: 2}}>
                                      {t('src.pages.forms.ChannelsForm.background')}
                                    </Typography>
                                    {showBackgroundColor && (
                                      <Controller
                                        name='color_background'
                                        control={controlUpdate}
                                        rules={
                                          {
                                            // required: 'Обязательное поле',
                                          }
                                        }
                                        render={({
                                                   field: {onChange, value},
                                                   fieldState: {error},
                                                 }) => (
                                          <Box pt={2}>
                                            <ChromePicker color={value} onChange={(value) => onChange(value.hex)}/>
                                          </Box>
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Button onClick={() => {
                                      !showMainColor && openWidget();
                                      setShowMainColor((prev) => !prev);
                                    }} variant='contained' sx={{
                                      backgroundColor: `${watchMainColor} !important`,
                                      width: '32px',
                                      height: '32px'
                                    }}></Button>
                                    <Typography variant="body" color="text.secondary" sx={{pl: 2}}>
                                      {t('src.pages.forms.ChannelsForm.window_color')}
                                    </Typography>
                                    {showMainColor && (
                                      <Controller
                                        name='color_window'
                                        control={controlUpdate}
                                        rules={
                                          {
                                            // required: 'Обязательное поле',
                                          }
                                        }
                                        render={({
                                                   field: {onChange, value},
                                                   fieldState: {error},
                                                 }) => (
                                          <Box pt={2}>
                                            <ChromePicker color={value} onChange={(value) => onChange(value.hex)}/>
                                          </Box>
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Button onClick={() => {
                                      !showMessageColor && openWidget();
                                      setShowMessageColor((prev) => !prev);
                                    }} variant='contained' sx={{
                                      backgroundColor: `${watchMessageColor} !important`,
                                      width: '32px',
                                      height: '32px'
                                    }}></Button>
                                    <Typography variant="body" color="text.secondary" sx={{pl: 2}}>
                                      {t('src.pages.forms.ChannelsForm.message_color')}
                                    </Typography>
                                    {showMessageColor && (
                                      <Controller
                                        name='color_message'
                                        control={controlUpdate}
                                        rules={
                                          {
                                            // required: 'Обязательное поле',
                                          }
                                        }
                                        render={({
                                                   field: {onChange, value},
                                                   fieldState: {error},
                                                 }) => (
                                          <Box pt={2}>
                                            <ChromePicker color={value} onChange={(value) => onChange(value.hex)}/>
                                          </Box>
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Button onClick={() => {
                                      !showButtonTogglerColor && openWidget();
                                      setShowButtonTogglerColor((prev) => !prev);
                                    }} variant='contained'
                                            sx={{
                                              backgroundColor: `${watchButtonTogglerColor} !important`,
                                              width: '32px',
                                              height: '32px'
                                            }}></Button>
                                    <Typography variant="body" color="text.secondary" sx={{pl: 2}}>
                                      {t('src.pages.forms.ChannelsForm.chat_button')}
                                    </Typography>
                                    {showButtonTogglerColor && (
                                      <Controller
                                        name='color_chat_button'
                                        control={controlUpdate}
                                        rules={
                                          {
                                            // required: 'Обязательное поле',
                                          }
                                        }
                                        render={({
                                                   field: {onChange, value},
                                                   fieldState: {error},
                                                 }) => (
                                          <Box pt={2}>
                                            <ChromePicker color={value} onChange={(value) => onChange(value.hex)}/>
                                          </Box>
                                        )}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item sx={{mb: 3}}>
                                    <Button onClick={() => {
                                      !showButtonSendColor && openWidget();
                                      setShowButtonSendColor((prev) => !prev);
                                    }} variant='contained'
                                            sx={{
                                              backgroundColor: `${watchButtonSendColor} !important`,
                                              width: '32px',
                                              height: '32px'
                                            }}></Button>
                                    <Typography variant="body" color="text.secondary" sx={{pl: 2}}>
                                      {t('src.pages.forms.ChannelsForm.send_button')}
                                    </Typography>
                                    {showButtonSendColor && (
                                      <Controller
                                        name='color_send_button'
                                        control={controlUpdate}
                                        rules={
                                          {
                                            // required: 'Обязательное поле',
                                          }
                                        }
                                        render={({
                                                   field: {onChange, value},
                                                   fieldState: {error},
                                                 }) => (
                                          <Box pt={2}>
                                            <ChromePicker color={value} onChange={(value) => onChange(value.hex)}/>
                                          </Box>
                                        )}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                )}
                {watchChannelType === 'Telegram' && (
                  <Grid item sx={{width: '100%'}}>
                    <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel3')}
                               onChange={handleChange('panel3')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{p: 0}}
                      >
                        <Typography variant="headerTable" color="text.primary">
                          {t('src.pages.forms.ChannelsForm.telegramSettings')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{p: 0, width: '100%'}}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item sx={{width: '100%'}}>
                            <TextField
                              id="token"
                              {...registerUpdate('token', {
                                required: true,
                              })}
                              error={errorsUpdate?.token}
                              helperText={errorsUpdate?.token && (errorsUpdate?.token?.message ||
                                t('src.pages.forms.ChannelsForm.required'))}
                              label="BOT_TOKEN"
                              sx={{mb: 2}}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined" fullWidth/>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {watchChannelType === 'Discord' && (
                  <Grid item sx={{width: '100%'}}>
                    <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel4')}
                               onChange={handleChange('panel4')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{p: 0}}
                      >
                        <Typography variant="headerTable" color="text.primary">
                          {t('src.pages.forms.ChannelsForm.discordSettings')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{p: 0, width: '100%'}}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item sx={{width: '100%'}}>
                            <TextField
                              {...registerUpdate('discord_token', {
                                required: true,
                              })}
                              error={errorsUpdate?.discord_token}
                              helperText={errorsUpdate?.discord_token && (errorsUpdate?.token?.discord_token ||
                                t('src.pages.forms.ChannelsForm.required'))}
                              label="DISCORD_TOKEN"
                              sx={{mb: 2}}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined" fullWidth/>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {(watchChannelType === 'WhatsApp' || watchChannelType === 'Teams') && (
                  <Grid item sx={{width: '100%', mb: 2}}>
                    <Button variant="outlined" onClick={handleOpenInstruction}>
                      {t('src.pages.forms.ChannelsForm.connectionInstructions')}
                    </Button>
                    <Popover
                      open={openInstruction}
                      anchorEl={instructionPopover}
                      onClose={handleCloseInstruction}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Typography sx={{p: 2}}>
                        {watchChannelType === 'WhatsApp' && t('src.pages.forms.ChannelsForm.whatsappInstructions')}
                        {watchChannelType === 'Teams' && t('src.pages.forms.ChannelsForm.teamsInstructions')}
                      </Typography>
                    </Popover>
                  </Grid>
                )}
                {watchChannelType === 'WhatsApp' && (
                  <>
                    <Grid item sx={{width: '100%'}}>
                      <CustomViewField label={t('src.pages.forms.ChannelsForm.webhookWhatsapp')}
                                       text={`${baseUrl}/whatsapp_webhook`}
                                       copyButton/>
                    </Grid>
                    <Grid item sx={{width: '100%'}}>
                      <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel5')}
                                 onChange={handleChange('panel5')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          sx={{p: 0}}
                        >
                          <Typography variant="headerTable" color="text.primary">
                            {t('src.pages.forms.ChannelsForm.whatsappSettings')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0, width: '100%'}}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('wapp_token', {
                                  required: true,
                                })}
                                error={errorsUpdate?.wapp_token}
                                helperText={errorsUpdate?.wapp_token && (errorsUpdate?.wapp_token?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="WHATSAPP_TOKEN"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('wapp_id', {
                                  required: true,
                                })}
                                error={errorsUpdate?.wapp_id}
                                helperText={errorsUpdate?.wapp_id && (errorsUpdate?.wapp_id?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="PHONE_ID"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                )}
                {watchChannelType === 'Teams' && (
                  <>
                    <Grid item sx={{width: '100%'}}>
                      <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel6')}
                                 onChange={handleChange('panel6')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          sx={{p: 0}}
                        >
                          <Typography variant="headerTable" color="text.primary">
                            {t('src.pages.forms.ChannelsForm.teamsSettings')}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0, width: '100%'}}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('az_client_id', {
                                  required: true,
                                })}
                                error={errorsUpdate?.az_client_id}
                                helperText={errorsUpdate?.az_client_id && (errorsUpdate?.az_client_id?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="CLIENT_ID"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('az_tenant_id', {
                                  required: true,
                                })}
                                error={errorsUpdate?.az_tenant_id}
                                helperText={errorsUpdate?.az_tenant_id && (errorsUpdate?.az_tenant_id?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="TENANT"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('az_username', {
                                  required: true,
                                })}
                                error={errorsUpdate?.az_username}
                                helperText={errorsUpdate?.az_username && (errorsUpdate?.az_username?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="EMAIL"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <TextField
                                {...registerUpdate('az_password', {
                                  required: true,
                                })}
                                error={errorsUpdate?.az_password}
                                helperText={errorsUpdate?.az_password && (errorsUpdate?.az_password?.message ||
                                  t('src.pages.forms.ChannelsForm.required'))}
                                label="PASSWORD"
                                sx={{mb: 2}}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined" fullWidth/>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </>
                )}
                <Grid item sx={{width: '100%'}}>
                  <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel7')}
                             onChange={handleChange('panel7')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      sx={{p: 0}}
                    >
                      <Typography variant="headerTable" color="text.primary">
                        {t('src.pages.forms.ChannelsForm.work_time')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0, width: '100%'}}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item sx={{width: '100%'}}>
                          <Stack direction="row" alignItems="center" justifyContent="start" mb={0}>
                            <Typography variant="body">
                              {t('src.pages.forms.ChannelsForm.is_around_the_clock')}
                            </Typography>
                            <Controller
                              {...registerUpdate('is_around_the_clock')}
                              error={errorsUpdate?.is_around_the_clock}
                              name="is_around_the_clock"
                              control={controlUpdate}
                              render={({field}) => (
                                <Switch
                                  onChange={(e) => field.onChange(e.target.checked)}
                                  checked={field.value}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                        {!watchAllTimeWork && (
                          <>
                            <Grid item sx={{width: '100%'}}>
                              <Grid container spacing={2} flexDirection='row'>
                                <Grid item sx={{width: '50%'}}>
                                  <Controller
                                    name='time_start'
                                    control={controlUpdate}
                                    rules={
                                      {
                                        required: true,
                                      }
                                    }
                                    render={({
                                               field: {onChange, value},
                                               fieldState: {error},
                                             }) => (
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeField
                                          label={t('src.pages.forms.ChannelsForm.time_start')}
                                          value={value ? dayjs(value) : null}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          format="HH:mm"
                                          size="small"
                                          sx={{width: '100%', mb: 2}}
                                          slotProps={{
                                            textField: {
                                              error: !!error,
                                              helperText: error?.message,
                                              InputLabelProps: {
                                                shrink: true,
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                </Grid>
                                <Grid item sx={{width: '50%'}}>
                                  <Controller
                                    name='time_end'
                                    control={controlUpdate}
                                    rules={
                                      {
                                        required: true,
                                      }
                                    }
                                    render={({
                                               field: {onChange, value},
                                               fieldState: {error},
                                             }) => (
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeField
                                          label={t('src.pages.forms.ChannelsForm.time_end')}
                                          value={value ? dayjs(value) : null}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          format="HH:mm"
                                          size="small"
                                          sx={{width: '100%', mb: 2}}
                                          slotProps={{
                                            textField: {
                                              error: !!error,
                                              helperText: error?.message,
                                              InputLabelProps: {
                                                shrink: true,
                                              },
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item sx={{width: '100%'}}>
                              <Controller
                                control={controlUpdate}
                                name="time_zone"
                                {...registerUpdate('time_zone', {
                                  required: true,
                                })}
                                // rules={{required: true}}
                                defaultValue={selectedChannel ? {
                                  label: `UTC${selectedChannel.time_zone}`,
                                  value: `${selectedChannel.time_zone}`
                                } : null}
                                render={({field}) =>
                                  <Autocomplete
                                    {...field}
                                    size="small"
                                    sx={{mb: 2}}
                                    options={timezones}
                                    getOptionLabel={option => String(option.label)}
                                    isOptionEqualToValue={(option, value) => String(option.value) === String(value.value)}
                                    onChange={(event, data) => {
                                      field.onChange(data)
                                    }} renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      label={t('src.pages.forms.ChannelsForm.timezone')}
                                      {...params}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={!!errorsUpdate.time_zone}
                                      helperText={errorsUpdate?.time_zone && (errorsUpdate?.time_zone?.message ||
                                        t('src.pages.forms.ChannelsForm.required'))}
                                    />
                                  )}
                                  />
                                }
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item sx={{width: '100%'}}>
                          <ToggleButtonGroup
                            size="small"
                            value={workingDays}
                            onChange={(event, value) => setWorkingDays(value)}
                          >
                            {days.map((day, index) => (
                              <ToggleButton key={day.key} value={index}
                                // selected={true}
                                            sx={{
                                              mr: 1,
                                              '&:last-child': {m: 0},
                                              borderRadius: '50% !important',
                                              border: '1px solid rgba(145, 158, 171, 0.24) !important',
                                              width: '40px',
                                              height: '40px',
                                              // '&.Mui-selected': {backgroundColor: '#000 !important'},
                                              // '&:hover': {backgroundColor: 'pallete.layout.default'}
                                            }}>
                                {day.label}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </Grid>

                        <Grid item sx={{width: '100%', mt: 2}}>
                          <TextField
                            {...registerUpdate('absence_message', {
                              required: true,
                            })}
                            error={errorsUpdate?.absence_message}
                            helperText={errorsUpdate?.absence_message && (errorsUpdate?.absence_message?.message ||
                              t('src.pages.forms.ChannelsForm.required'))}
                            label={t('src.pages.forms.ChannelsForm.absence_message')}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline
                            rows={3}
                            fullWidth
                          />
                        </Grid>

                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item sx={{width: '100%'}}>
                  <Accordion sx={{width: '100%'}} expanded={expanded.includes('panel8')}
                             onChange={handleChange('panel8')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      sx={{p: 0, my: 0}}
                    >
                      <Typography variant="headerTable" color="text.primary">
                        {t('src.pages.forms.ChannelsForm.systemMessages')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0, width: '100%'}}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item sx={{width: '100%'}}>
                          <TextField
                            id="introduction"
                            {...registerUpdate('introduction', {
                              required: true,
                            })}
                            error={errorsUpdate?.introduction}
                            helperText={errorsUpdate?.introduction && (errorsUpdate?.introduction?.message ||
                              t('src.pages.forms.ChannelsForm.required'))}
                            label={t('src.pages.forms.ChannelsForm.greetings', 'Приветствие', 'ru')}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{mb: 4}}
                            multiline
                            rows={3}
                            fullWidth
                          />
                          <TextField
                            id="auto_switch_message"
                            {...registerUpdate('auto_switch_message', {
                              required: true,
                            })}
                            error={errorsUpdate?.auto_switch_message}
                            helperText={errorsUpdate?.auto_switch_message ? (errorsUpdate?.auto_switch_message?.message ||
                              t('src.pages.forms.ChannelsForm.required')) : t('src.pages.forms.ChannelsForm.auto_switch_message_helper')}
                            label={t('src.pages.forms.ChannelsForm.auto_switch_message')}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{mb: 4}}
                            multiline
                            rows={2}
                            fullWidth
                          />
                          <TextField
                            id="manual_switch_message"
                            {...registerUpdate('manual_switch_message', {
                              required: true,
                            })}
                            error={errorsUpdate?.manual_switch_message}
                            helperText={errorsUpdate?.manual_switch_message ? (errorsUpdate?.manual_switch_message?.message ||
                              t('src.pages.forms.ChannelsForm.required')) : t('src.pages.forms.ChannelsForm.manual_switch_message_helper')}
                            label={t('src.pages.forms.ChannelsForm.manual_switch_message')}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline
                            sx={{mb: 2}}
                            rows={2}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {(watchChannelType === 'Web' && showGeneratedCode) && (
                  <Grid item sx={{width: '100%'}}>
                    <Stack>
                      <Box pb={1} pt={2}>
                        <Typography variant='body2'>{t('src.pages.forms.ChannelsForm.helperText')}</Typography>
                      </Box>
                      <Box sx={{
                        backgroundColor: 'layout.default',
                        fontWeight: 600,
                        // width: 'max-content',
                        borderRadius: '6px'
                      }} p={2} mb={2}>
          <pre style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
            {`<script type="text/javascript">
                document.widgetID = '${selectedChannel?.id}';
                const s = document.createElement('script');
                s.src = 'https://ax7static7aspex7cloud.blob.core.windows.net/$web/scripts/loader.js';
                s.type = 'text/javascript';
                s.async = true;
                document.body.appendChild(s);
              </script>`}
          </pre>
                      </Box>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <Grid item
                      sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 2}}
                >
                  <Stack direction="row" alignItems="center" justifyContent="start" mb={1}>
                    <Typography variant="h6">
                      {t('src.pages.forms.ChannelsForm.questions', 'Вопросы', 'ru')}
                    </Typography>
                    <Chip
                      label={`${selectedQuestions && selectedQuestions.length}/5`}
                      color="primary"
                      sx={{ml: 1}}
                    />
                    <Button
                      sx={{ml: 2}}
                      onClick={pluseHandler}
                      disabled={selectedQuestions && selectedQuestions.length >= 5}
                      startIcon={<AddIcon/>}
                    >
                      {t('src.pages.forms.ChannelsForm.addQuestion', 'Добавить вопрос', 'ru')}
                    </Button>
                  </Stack>

                  {selectedQuestions && selectedQuestions.map((question, index) =>
                    <Grid sx={{width: '100%', mb: 1}}>
                      <Stack direction="row" sx={{}} alignItems="center" justifyContent="space-between">
                        <div>
                          <Typography variant="body2" gutterBottom>
                            {t('src.pages.forms.ChannelsForm.question', 'Вопрос', 'ru')} {index + 1}
                          </Typography>

                        </div>
                        <div>
                          <IconButton
                            onClick={() =>
                              handleDeletQuestion(question.index)
                            }
                          >
                            <CloseIcon/>
                          </IconButton>
                        </div>
                      </Stack>
                      <TextField
                        required
                        value={selectedQuestions[index].question}
                        onChange={(event) => {
                          updateQuestionInChannelsQuestions({
                            id: question.index,
                            field: 'question',
                            value: event.target.value,
                          })
                        }}
                        label={t('src.pages.forms.ChannelsForm.question', 'Вопрос', 'ru')}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{mb: 2}}
                      />
                      <Autocomplete
                        size="small"
                        sx={{mb: 2}}
                        options={AnswerType}
                        value={selectedQuestions[index].type}
                        onChange={(event, value) => {
                          updateQuestionInChannelsQuestions({id: question.index, field: 'type', value: value})
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            label={t('src.pages.forms.ChannelsForm.responseType', 'Тип ответа', 'ru')}
                            required
                            {...params}
                          />
                        )}
                      />

                    </Grid>,
                  )}
                </Grid>
              </Grid>
              <Grid
                  item
                  sx={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'space-between', mt:isMobile? 2: 0}}
              >

                {isMobile ?
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{width: '100%'}}
                    >
                      <LoadingButton
                          variant="contained"
                          type="submit"
                          loading={loading}
                          sx={{width: '100%'}}
                      >
                        {t('main.another.save')}
                      </LoadingButton >
                      <Button
                          variant="outlined"
                          disabled={!isDirty}
                          onClick={()=> readLocation()}
                          sx={{width: '100%'}}
                      >
                        {channelsTypeFormIsAdd ? t('main.another.clear') : t('main.another.reset')}
                      </Button>

                    </Stack>
                :
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{width: '100%'}}
                    >
                      <Box>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading}
                        >
                          {t('main.another.save')}
                        </LoadingButton >
                        {!isMobile &&
                            <Button variant="text" sx={{ml: 2}} onClick={handleClose}>
                              {t('main.another.close')}
                            </Button>
                        }
                      </Box>
                      <Box>
                        <Button
                            variant="outlined"
                            disabled={!isDirty}
                            onClick={()=> readLocation()}
                        >
                          {channelsTypeFormIsAdd ? t('main.another.clear') : t('main.another.reset')}
                        </Button>
                      </Box>
                    </Stack>
                }
                {(watchChannelType === 'Web' || watchChannelType === 'GetCourse') &&
                  <WidgetPreview channelType={watchChannelType} buttonTogglerColor={watchButtonTogglerColor}
                                 buttonSendColor={watchButtonSendColor} messageColor={watchMessageColor}
                                 mainColor={watchMainColor} backgroundColor={watchBackgroundColor}/>
                }
              </Grid>
            </Grid>
          </form>
        )}
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </Card>
  )
}
export default ChannelsForm;