import React, {useCallback, useState} from 'react';
import { Handle, Position } from 'reactflow';
import {Badge, Grid, TextField, Typography} from "@mui/material";
import {Forum} from "@mui/icons-material";

function ChatGptNode({ data, isConnectable }) {
    const [text, setText] = useState(data.text ?? "");

    const onChange = useCallback((evt) => {
        data.text = evt.target.value
    }, []);

    return (
        <Badge
            badgeContent={<Forum sx={{transform: 'translate(-5px, 25px)', fontSize: '15px'}} /> }
        >
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="text-updater-node-puzzle"
        >
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Typography sx={{fontSize: '6pt'}} align="center">
                    ChatGPT
                </Typography>
            </div>
            <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
        </Grid>
        </Badge>
    );
}

export default ChatGptNode;
