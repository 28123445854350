// Импорт иконок Material Symbols
const a = document.createElement("link"),
  b = document.createElement("link"),
  c = document.createElement("link");
a.rel = "stylesheet";
b.rel = "stylesheet";
c.rel = "stylesheet";
a.href = "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0";
b.href = "https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0";
c.href = "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0";
document.head.appendChild(a);
document.head.appendChild(b);
document.head.appendChild(c);