import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useActions } from '../../hook/useActions'
import { $authHost } from '../../http'

const MessageWrapper = ({ message, sandbox, children }) => {

  const {
    setReadMessage,
  } = useActions()

  const { is_read: isRead, text } = message

  const { ref, inView, entry } = useInView({
    threshold: 0.8,
  })

  useEffect(() => {
    if (inView && !isRead && sandbox === false) {
      const timer = setTimeout(() => {
        try {
          $authHost.post(`dialogues/message/read/${message.id}`).then(response => {
            setReadMessage(message)
          })
        } catch (e) {
        }
      }, 2000)

      return () => {
        clearTimeout(timer)  // Сброс таймера при удалении компонента или изменении зависимостей
      }
    }
  }, [inView, isRead, text])

  return (
    <Box
      ref={ref}
      sx={{
        display: 'inline-block',
        maxWidth: '80%',
      }}
    >
      {children}
    </Box>
  )
}

export default React.memo(MessageWrapper)