import { authActions } from '../slice/auth.slice'
import { $authHost } from '../../http'
import { call, put, takeEvery } from 'redux-saga/effects'

function * fetchUserData () {
	try {
		const response = yield call($authHost.get, '/user/me')
		const { id, last_name, email, avatar, name, roles, status, locked_flags, groups } = response.data
		yield put(
			authActions.updateUser({
				userId: id,
				last_name,
				email,
				avatar,
				name,
				roles,
				status,
				locked_flags,
				groups
			}),
		)
		// }
	} catch (error) {
		// Обработка ошибок, если необходимо
	}
}

export function * watchTokenChanges () {
	// while (true){
	yield takeEvery([authActions.setToken.type], fetchUserData)
	// }
}

// export function* authWatcher() {
// 	yield watchTokenChanges();
// 	// Другие саги, если есть
// }
