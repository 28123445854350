import React from 'react'
import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChangePasswordForm from './forms/ChangePasswordForm'
import {StyledContent, StyledRootUpMD} from "../theme/standarts_styles";

const ChangePassword = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledRootUpMD>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('src.pages.ChangePassword.changePasswordPageText', 'Смена пароля')}
            </Typography>
            <ChangePasswordForm/>
          </StyledContent>
        </Container>
      </StyledRootUpMD>
    </>
  )
}

export default ChangePassword
