import { createSlice } from '@reduxjs/toolkit'
// import { LOG_OUT } from "../auth/authReducer";

const initialState = {
  contexts_sandbox: [],
  openSandbox: false,
  sandBoxDataDialog: [],
  sandboxFieldForm: {
    id: null,
    name: '',
    manufacturer: [], //Array()
    duration: '',
    price: '',
    users: '',
    billing_plan: '',
    description: '',
    is_run: false,
    language: false,
  },
}

const sandboxDataSlice = createSlice({
  name: 'sandboxData',
  initialState,
  reducers: {
    logOut () {
      return initialState
    },
    setContexts_sandbox (state, { payload }) {
      state.contexts_sandbox = payload
    },
    setOpenSandbox (state, { payload }) {
      state.openSandbox = payload
    },
    setSandBoxDataDialog (state, { payload }) {
      state.sandBoxDataDialog = payload
    },
    addSandboxDataMessage (state, { payload }) {
      state.sandBoxDataDialog = [...state.sandBoxDataDialog, payload]
    },
    setSandboxFieldForm (state, { payload }) {
      state.sandboxFieldForm = payload
    },
    setSandboxFieldFormLanguage (state, { payload }) {
      state.sandboxFieldForm.language = payload
    },
    resetSandboxFieldForm (state) {
      state.sandboxFieldForm = initialState.sandboxFieldForm
      // for (let field in state.sandboxFieldForm) {
      //   delete state.sandboxFieldForm[field];
      // }

    },
  },
})

export const { actions: sandboxDataActions, reducer: sandboxDataReducer } = sandboxDataSlice
// export const { addNewMessage, addChat } = dialoguesSlice
export default sandboxDataSlice.reducer
