import {Button, Card, Grid, Switch, Tooltip, Typography} from "@mui/material";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import React, {useEffect, useRef, useState} from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import {$authHost} from "../../http";
import {useTranslation} from "react-i18next";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import AddUserForm from "./AddUserForm";
import {ButtonStack, RowOptionsStack} from "../../theme/standarts_styles";
import Iconify from "../../components/iconify";
import {useNavigate, useParams} from "react-router-dom";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import {FormProvider, useForm} from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../../components/Fields/CustomTextField";
import {LoadingButton} from "@mui/lab";
import useResponsive from "../../hook/useResponsive";

const ChannelsFormAvailability = () => {
  const {t, i18n} = useTranslation();
  const {id} = useParams();
  const {setUsers} = useActions();
  const {users} = useSelector((state) => state.usersReducer);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);
  const [openForm, setOpenForm] = useState('');
  const navigate = useNavigate()
  const isMobile = useResponsive('down', 'md')

  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      accessorKey: 'username',
      header: t('src.pages.forms.ChannelsForm.name'),
      accessorFn: (row) => row.username || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'allow',
      header: t('src.pages.forms.ChannelsForm.state'),
      accessorFn: (row) => row.allow ? t('src.pages.forms.ChannelsForm.userStatusEnabled') : t('src.pages.forms.ChannelsForm.userStatusDisabled'),
      Cell: ({renderedCellValue, row}) => {
        return row.original.allow ? (
          <Chip label={renderedCellValue} color="success"/>
        ) : (
          <Chip label={renderedCellValue} color="error"/>
        )
      },
    },
    {
      accessorKey: 'actions',
      size: 1,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title={t('src.pages.Channels.confirm')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('src.pages.Channels.cancel')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title={row.original.allow ? t('src.pages.Channels.turnOff') : t('src.pages.Channels.turnOn')}>
              <Switch
                checked={row.original.allow}
                onChange={() => handleChangeStatus(row.original)}
                inputProps={{'aria-label': 'controlled'}}
              />
            </Tooltip>
            <Tooltip title={t('src.pages.Channels.delete')}>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </RowOptionsStack>
        );
      },
    },
  ];

  const handleChangeStatus = async ({allow, ...row}) => {
    try {
      setLoading(true);

      await $authHost.patch(`/channel/user_white_list/update`, {allow: !allow, ...row});
      loadData();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteRow = async (id) => {
    try {
      setLoading(true);

      await $authHost.delete(`/channel/user_white_list/delete?id=${id}`);
      loadData();
    } catch (e) {
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = async () => {
    try {
      setLoading(true);

      const response = await $authHost.get(
        `/channel/user_white_list/?channel_id=${id}&search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      const data = response.data.items;
      setUsers(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  const handleClickExport = () => {
    exportInputRef.current && exportInputRef.current.click();
  }

  const handleExport = async (e) => {
    const file = e.target.files && e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      setLoading(true)

      await $authHost.post(`/channel/user_white_list/upload_file`, formData, {
        'Content-Type': 'multipart/form-data',
        headers: {
          'Channel-Id': id,
        }
      })
      loadData();
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const handleImport = async () => {
    try {
      const response = await $authHost.get(`/channel/user_white_list/download_file?channel_id=${id}`, {
        responseType: 'blob',
      })
      const outputFilename = `${Date.now()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const exportInputRef = useRef(null);

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === 'Add' ? '' : 'Add';
    });
  };


  const methods = useForm({
    mode: 'onBlur',
  });



  const {
    handleSubmit,
    reset,
    setError,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.post(`/channel/user_white_list/insert`, {channel_id: id, ...data});
      loadData();
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/channels');
    }
  }, [isSubmitSuccessful]);

  const handleReset = () => {
    reset();
  };

  const handleClose = () => {
    navigate('/channels');
  };

  return (
    <>
      <ButtonStack>
        <Button
          onClick={handleAdd}
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 3}}
        >
          {t('src.pages.forms.ChannelsForm.addUserButton')}
        </Button>
      </ButtonStack>
      {openForm === 'Add' && (
          <>
            {loading && <LoadingSpinner/>}
            <Card sx={{mb: 2, p: 3}}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {isMobile &&
                      <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{mb: 2}}
                      >
                        <Grid item>
                          <Typography variant='subtitle1' color="primary">
                            {t('main.another.add')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                              onClick={handleClose}
                          >
                            <CloseIcon/>
                          </IconButton>
                        </Grid>
                      </Grid>
                  }
                  <CustomTextField
                      name="username"
                      label={t('src.pages.forms.ChannelsForm.addUserName')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                      }}
                      required
                      sx={{width: '100%', mb: 3}}
                      size="small"
                  />
                  {isMobile ?
                      <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          sx={{width: '100%'}}
                      >
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading}
                            sx={{width: '100%'}}
                        >
                          {t('main.another.save')}
                        </LoadingButton >
                        <Button
                            variant="outlined"
                            disabled={!isDirty}
                            onClick={handleReset}
                            sx={{width: '100%'}}
                        >
                          {t('main.another.clear')}
                        </Button>

                      </Stack>
                      :
                      <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' spacing={2}>
                          <Button variant='contained' type='submit'>
                            {t('main.another.save')}
                          </Button>
                          <Button
                              variant='text'
                              onClick={handleClose}
                          >
                            {t('main.another.close')}
                          </Button>
                        </Stack>
                        <Button
                            variant='outlined'
                            disabled={!isDirty}
                            onClick={handleReset}
                        >
                          {t('main.another.clear')}
                        </Button>
                      </Stack>
                  }
                </form>
              </FormProvider>
            </Card>
          </>
      )}
      <Card sx={{mx: 0, my: 0}}>
        <Stack direction='row' spacing={2} sx={{p: 2}}>
          <Button variant='outlined' startIcon={<UploadIcon/>} onClick={handleClickExport}>
            {t('src.pages.forms.ChannelsForm.buttonExport')}
            <input type="file"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                   onChange={handleExport} ref={exportInputRef} style={{display: "none"}}/>
          </Button>
          <Button variant='outlined' startIcon={<DownloadIcon/>}
                  onClick={handleImport}>{t('src.pages.forms.ChannelsForm.buttonImport')}</Button>
        </Stack>
        <MaterialTablePagination
          data={users}
          columns={tableColumns}
          loading={loading}
          setPagination={setPagination}
          pagination={pagination}
          rowCount={rowCount}
          pageCount={pageCount}
          onGlobalFilterChange={onGlobalFilterChange}
          search={searchText}
        />
      </Card>
    </>
  )
}

export default ChannelsFormAvailability;