import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactFlow, {
    addEdge, applyEdgeChanges, applyNodeChanges,
    Background,
    Controls,
    MiniMap, Panel,
    ReactFlowProvider,
    useEdgesState, useNodes,
    useNodesState, useOnSelectionChange, useReactFlow, useStoreApi, useUpdateNodeInternals
} from "reactflow";
import 'reactflow/dist/style.css';
import Sidebar from './SideBar';
import './index.css';
import stylistNode from "./nodes/StylistNode";
import {Box, Button, Card, Grid} from "@mui/material";
import EditNodeForm from "./EditNodeForm";
import ParserNode from "./nodes/ParserNode";
import ChatGptNode from "./nodes/ChatgptNode.jsx";
import GateNode from "./nodes/GateNode";
import ResponseNode from "./nodes/ResponseNode";
import ChannelNode from "./nodes/ChannelNode";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {edgesInit, nodesInit} from "../../utils/initialElementsDiagram";
import ActionNode from "./nodes/ActionNode";
import InfoNode from "./nodes/InfoNode";
import i18next from "i18next";
import CustomEdge from "./edges/StartLabeleEdge";
import useResponsive from "../../hook/useResponsive";

const nodeTypes = {
    chatGpt: ChatGptNode,
    parser: ParserNode,
    gate: GateNode,
    channel: ChannelNode,
    response: ResponseNode,
    action: ActionNode,
    info: InfoNode
};

const edgeTypes = {
    'start-end': CustomEdge,
};


const Flow = () => {

    const reactFlowWrapper = useRef(null);
    const [nodesA, setNodesA] = useNodesState(nodesInit);
    const [edges, setEdges] = useEdgesState(edgesInit);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [lng, setLng] = useState(null);
    const isMobile = useResponsive('down', 'md')

    useOnSelectionChange({
        onChange: ({ nodes, edges }) => {
            setSelectedNodes(nodes.map((node) => node.id));
        },
    });

    i18next.on('languageChanged', function(lng) {
        setLng(lng)
    })

    useEffect(() => {
        setNodesA((nds) =>
            nds.map((node) => {
                node.data = {
                    ...node.data,
                    label: node.data.label,
                };
                return node;
            })
        );
    }, [lng]);

    return (
        <>

            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                {isMobile &&
                    <div className="updatenode__controls__mobile">
                        <EditNodeForm
                            selectedNodes={selectedNodes}
                            OnClearSelection={()=>{setSelectedNodes([])}}
                            nodes={nodesA}
                            edges={edges}
                        />
                    </div>
                }
                <ReactFlow
                    nodes={nodesA}
                    edges={edges}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    edgesFocusable={false}
                    disableKeyboardA11y={true}
                    deleteKeyCode={null}
                    multiSelectionKeyCode={null}
                    fitView
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                >
                    {!isMobile &&
                        <div className="updatenode__controls">
                            <EditNodeForm OnClearSelection={()=>{setSelectedNodes([])}} selectedNodes={selectedNodes} nodes={nodesA} edges={edges}/>
                        </div>
                    }
                    {isMobile ?
                        <MiniMap position="bottom-left" style={{marginLeft: "0px", marginTop: "0px"}}/>
                        :
                        <MiniMap position="top-left" style={{marginLeft: "45px", marginTop: "5px"}}/>
                    }
                    <Controls position="top-left" style={{marginTop: "5px", marginLeft: "5px"}}/>
                    <Background color="#aaa" gap={16}/>
                </ReactFlow>
            </div>
        </>
    );
};


export default () => (
    <div className="dndflow">
        <ReactFlowProvider>
            <Flow/>
        </ReactFlowProvider>
    </div>
);