export const widgetMainFn = () => {
  const chatbotToggler = document.querySelector(".chatbot-toggler");
  const closeBtn = document.querySelector(".close-btn");
  const chatbox = document.querySelector(".chatbox");
  const chatInput = document.querySelector(".chat-input textarea");
  const sendChatBtn = document.querySelector(".chat-input span");

  let userMessage = null; // Переменная для хранения сообщения пользователя
  const inputInitHeight = 55;
// const inputInitHeight = chatInput.scrollHeight;
  const body = document.getElementById('widget-body');
  const createChatLi = (message, className) => {
    // Создать элемент чата <li> с переданным сообщением и className
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", `${className}`);
    let chatContent = className === "outgoing" ? `<p></p>` : `<span class="material-symbols-outlined">smart_toy</span><p></p>`;
    chatLi.innerHTML = chatContent;
    chatLi.querySelector("p").textContent = message;
    return chatLi; // Вернуть элемент чата <li>
  }

  const generateResponse = (chatElement) => {
    const API_URL = "http://localhost:8888/";
    const messageElement = chatElement.querySelector("p");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: userMessage,
      })
    }

    // Делаем POST запрос, получаем ответ и и устанавливаем его как текст в messageElement <p>
    fetch(API_URL, requestOptions).then(res => res.json()).then(data => {
      messageElement.textContent = data.choices[0].message.content.trim();
    }).catch(() => {
      // В случае ошибки добавляем класс error и устанавливаем текст ошибки
      messageElement.classList.add("error");
      messageElement.textContent = "Что-то пошло не так... Пожалуйста, попробуйте еще раз.";
    }).finally(() => chatbox.scrollTo(0, chatbox.scrollHeight));
  }

  const handleChat = () => {
    userMessage = chatInput.value.trim(); // Получить введенное пользователем сообщение и удалить лишние пробелы
    if (!userMessage) return;

    // Очистить input textarea и установить его высоту по умолчанию
    chatInput.value = "";
    chatInput.style.height = `${inputInitHeight}px`;

    // Добавить сообщение пользователя в чат
    chatbox.appendChild(createChatLi(userMessage, "outgoing"));
    chatbox.scrollTo(0, chatbox.scrollHeight);

    setTimeout(() => {
      // Отображение сообщения "Думаю..." во время ожидания ответа с сервера
      const incomingChatLi = createChatLi("Думаю...", "incoming");
      chatbox.appendChild(incomingChatLi);
      chatbox.scrollTo(0, chatbox.scrollHeight);
      generateResponse(incomingChatLi);
    }, 500);
  }

  if (chatInput) {
    chatInput.addEventListener("input", () => {
      // Отрегулировать высоту input textarea в зависимости от его содержимого
      chatInput.style.height = `${inputInitHeight}px`;
      chatInput.style.height = `${chatInput.scrollHeight}px`;
    });

    chatInput.addEventListener("keydown", (e) => {
      // Если клавиша Enter нажата без клавиши Shift и ширина окна больше 800px, то отправить сообщение
      if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
        e.preventDefault();
        handleChat();
      }
    });
  }

  if (sendChatBtn) {
    sendChatBtn.addEventListener("click", handleChat);
  }

  if (closeBtn) {
    closeBtn.addEventListener("click", () => body.classList.remove("show-chatbot"));
  }

  if (chatbotToggler) {
    chatbotToggler.addEventListener("click", () => body.classList.toggle("show-chatbot"));
  }
}
