import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

// флаги блокировок и руты для перенаправления
const lockedFlagUrl = {
  NewEULA: '/new-eula',
  CompleteRegistration: '/primary-registration',
  ResetPassword: '/change-password',
}

// руты с доступом без авторизации
const unregisteredUrl = [
  '/login',
  '/register',
  '/reset-password',
]

// руты куда не могут попадать активные пользователи
// const authPaths = [...new Set([...Object.values(lockedFlagUrl), ...unregisteredUrl])]
// const authPaths = [...Object.values(lockedFlagUrl), ...unregisteredUrl]
const authPaths = [...new Set([...Object.values(lockedFlagUrl), ...unregisteredUrl])]

const RequireRedirect = ({ children }) => {

  const { pathname } = useLocation() // Используем хук useLocation для доступа к текущему местоположению
  const {
    status,
    locked_flags,
    roles
  } = useSelector(state => state.authReducer)

  // переадерсация при попытке обраиться к /edit, без объекта обращения
  if (pathname.endsWith('/edit') || pathname.endsWith('/edit/')) {
    const newPath = pathname.replace(/\/edit\/?$/, '')
    window.history.replaceState(null, null, newPath)
    return <Navigate to={newPath}/>
  }

  // при наличии lockedFlagUrl переадресация на соответствующий url
  if (status === 'Locked' && lockedFlagUrl[locked_flags?.name] && pathname !== lockedFlagUrl[locked_flags.name]) {
    return <Navigate to={lockedFlagUrl[locked_flags.name]}/>
  }


  // переадерсация активного пользователя с рутов куда не могут попадать активные пользователи
  // if (unregisteredUrl.includes(pathname) && status === 'Active') {
  //   return <Navigate to={'/'}/>
  // }
  // переадерсация активного пользователя с рутов куда не могут попадать активные пользователи и пользователи без флагов
  if (authPaths.includes(pathname) && status === 'Active') {
    return <Navigate to={'/'}/>
  }

  return children
}

export { RequireRedirect }
