import React, { } from 'react';
import { Autocomplete, Grid, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hook/useActions';
import { useTranslation } from 'react-i18next';



const FileDescription = ({ file, index }) => {
    const { t, i18n } = useTranslation()


    const {
        addUploadFile, deleteUploadFile } = useActions()
    // const selectedDocType = useSelector(state => state.fileReducer[file.path].docType)




    // addUploadFile(file)
    // console.log(file)

    const removeFile = selectedFile => {
        // console.log('selectedFile ', selectedFile.file.path);

        // const newFiles = [...files]
        // newFiles.splice(newFiles.indexOf(selectedFile), 1)
        // setFiles(newFiles)
        deleteUploadFile(file.file.path);
    }

    // const [personName, setPersonName] = React.useState([]);

    // const handleDocType = (event, value) => {
    //     // const id = event.target.id.split("-")[0];
    //     const id = 'docType';
    //     const path = file.path
    //     value = value.label ?? ''
    //     addDescriptionFile({ path, id, value })
    // };
    // const handleAddModel = (event, value) => {
    //     // const id = event.target.id.split("-")[0];
    //     const id = 'addModel';
    //     const path = file.path
    //     value = value.label ?? ''
    //     addDescriptionFile({ path, id, value })
    // };
    // const handleFileDescription = (event) => {
    //     const { value, id } = event.target
    //     const path = file.path
    //     addDescriptionFile({ path, id, value })
    // };

    return (
        <Box
            sx={{
                width: '100%',
                borderRadius: 3,
                boxShadow: 3,
                p: 1,
                mb: 1,
            }}
        >
            <ListItem key={index} disablePadding className="mx-1" sx={{ flexDirection: 'column' }}>
                <Grid container spacing={2}>
                    <Grid item container direction="row" xs={12} sm={12} md={12} spacing={2}>
                        <Grid item xs={1} sm={1} md={1}>
                            {file?.extention['icon']}
                        </Grid>
                        <Grid item xs={10} sm={10} md={10}>
                            <ListItemText primary={`${file.file['name']} (${file.file['size']} bytes)`} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} container justifyContent="flex-end">
                            <CloseIcon onClick={() => removeFile(file)} />
                        </Grid>
                    </Grid>

                    {/*<Grid item container direction="row" xs={12} sm={12} md={12} spacing={2}>*/}
                    {/*    <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*        <Typography > {t('src.components.FileDescription.docType', 'Тип документа', 'ru')} </Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={8} md={2} container direction="row" alignItems="center">*/}
                    {/*        <Autocomplete*/}
                    {/*            options={docType}*/}
                    {/*            getOptionLabel={(option) => option.label}*/}
                    {/*            defaultValue={docType[0]}*/}
                    {/*            disablePortal*/}
                    {/*            id="docType"*/}
                    {/*            onChange={handleDocType}*/}
                    {/*            size="small"*/}
                    {/*            fullWidth*/}
                    {/*            disableClearable*/}
                    {/*            renderInput={(params) => (*/}
                    {/*                <TextField*/}
                    {/*                    {...params}*/}
                    {/*                    size="small"*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    {/*<Grid item container direction="row" xs={12} sm={12} md={12} spacing={2}>*/}
                    {/*    <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*        <Typography > {t('src.components.FileDescription.modelAddWay', 'Способ добавлени в модель', 'ru')} </Typography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*        <Autocomplete*/}
                    {/*            options={addModel}*/}
                    {/*            getOptionLabel={(option) => option.label}*/}
                    {/*            // defaultValue={addModel[0]}*/}
                    {/*            disablePortal*/}
                    {/*            id="addModel"*/}
                    {/*            // value={personName}*/}
                    {/*            onChange={handleAddModel}*/}
                    {/*            size="small"*/}
                    {/*            fullWidth*/}
                    {/*            disableClearable*/}
                    {/*            renderInput={(params) => (*/}
                    {/*                <TextField*/}
                    {/*                    {...params}*/}
                    {/*                    size="small"*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    {selectedDocType === docType[0].label &&*/}
                    {/*        <Grid item container direction="row" xs={12} sm={12} md={12} spacing={2}>*/}
                    {/*            <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*                <Typography > {t('src.components.FileDescription.effectiveDate', 'Дата начала действия', 'ru')} </Typography>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*                <TextField*/}
                    {/*                    id='startDate'*/}
                    {/*                    onChange={handleFileDescription}*/}
                    {/*                    type="date"*/}
                    {/*                    InputLabelProps={{*/}
                    {/*                        shrink: true,*/}
                    {/*                    }}*/}
                    {/*                    size="small"*/}
                    {/*                    fullWidth*/}
                    {/*                />*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*                <Typography > {t('src.components.FileDescription.expiryDate', 'Дата окончания действия', 'ru')} </Typography>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={12} sm={4} md={2} container direction="row" alignItems="center">*/}
                    {/*                <TextField*/}
                    {/*                    id='expirationDate'*/}
                    {/*                    onChange={handleFileDescription}*/}
                    {/*                    type="date"*/}
                    {/*                    InputLabelProps={{*/}
                    {/*                        shrink: true,*/}
                    {/*                    }}*/}
                    {/*                    size="small"*/}
                    {/*                    fullWidth*/}
                    {/*                />*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>}*/}
                    {/*</Grid>*/}
                </Grid>
            </ListItem>
        </Box>
    );
};

export default React.memo(FileDescription);