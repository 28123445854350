import { Box, Button, Dialog } from '@mui/material';
import ReactRouterPrompt from 'react-router-prompt';
import Typography from '@mui/material/Typography';
import React from 'react';
import Stack from '@mui/material/Stack';

const BlockFormModal = ({ when, title='Есть несохраненные данные в форме' }) => (
  <ReactRouterPrompt when={when}>
    {({ isActive, onConfirm, onCancel }) => (
      <Dialog open={isActive} sx={{ zIndex: 9999 }}>
        <Box sx={{ p: 4 }}>
          <Typography variant='h5' sx={{ mb: 2 }}>
            {title}
          </Typography>
          <Typography variant='body1' sx={{ mb: 2 }}>
            Вы действительно хотите выйти? Все несохраненные данные будут
            утеряны.
          </Typography>
          <Stack direction='row' spacing={1}>
            <Button variant='contained' onClick={onCancel}>
              Отмена
            </Button>
            <Button variant='text' onClick={onConfirm}>
              Выйти
            </Button>
          </Stack>
        </Box>
      </Dialog>
    )}
  </ReactRouterPrompt>
);

export default BlockFormModal;
