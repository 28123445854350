import {Badge} from "@mui/material";
import {TypeIcon} from "./TypeIcon";
import styles from "./MultipleDragPreview.module.css";
import {MultipleDragPreviewDiv} from "../../theme/standarts_styles";

export const MultipleDragPreview = (props) => {
    return (
        <Badge
            classes={{ badge: styles.badge }}
            color="primary"
            badgeContent={props.dragSources.length}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <MultipleDragPreviewDiv
                data-testid="custom-drag-preview"
            >
                {props.dragSources.map((node) => (
                    <div className={styles.item}>
                        <div className={styles.icon}>
                            <TypeIcon
                                droppable={node.droppable || false}
                                fileType={node?.data?.fileType}
                            />
                        </div>
                        <div className={styles.label}>{node.text}</div>
                    </div>
                ))}
            </MultipleDragPreviewDiv>
        </Badge>
    );
};