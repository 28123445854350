import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
  selectedParserRule: null,
  parserRules: [],
  selectedRuleType: null,
  nodesState:[],
  edgesState:[],
  connectionsForGates:[],
  breakerPrompt:null,
  bouncerPrompt:null,
  stylistPrompt:null,
  retrieverPrompt:null,

};

export const diagramSlice = createSlice({
  name: "diagram",
  initialState,
  reducers: {
    delAllFile() { return initialState },
    setNodes(state, { payload }) { state.nodesState = payload },
    setBreakerPrompt(state, { payload }) { state.breakerPrompt = payload },
    setBouncerPrompt(state, { payload }) { state.bouncerPrompt = payload },
    setStylistPrompt(state, { payload }) { state.stylistPrompt = payload },
    setRetrieverPrompt(state, { payload }) { state.retrieverPrompt = payload },
    setEdgesState(state, { payload }) { state.edgesState = payload },
    setConnectionsForGates(state, { payload }) { state.connectionsForGates = payload },
    setParserRules(state, { payload }) { state.parserRules = payload },
    setSelectedRuleType(state, { payload }) { state.selectedRuleType = payload },
    setSelectedParserRule(state, { payload }) {
      const index = state.parserRules.findIndex(
          (rule) => rule.id === payload,
      )
      state.selectedParserRule = state.parserRules[index] || null
    },
    moveRules(state, { payload }) {
      const {dragIndex, hoverIndex} = payload;
      state.parserRules = update(state.parserRules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, state.parserRules[dragIndex]],
        ],
      })
    },
    deleteRule(state, { payload }) {
      state.parserRules = state.parserRules.filter((rule) => payload !== rule.id)
    }
  },
});

export const { actions: diagramActions, reducer: diagramReducer } = diagramSlice
export default diagramSlice.reducer;
