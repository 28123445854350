import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import useResponsive from "../../hook/useResponsive";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";

export const TypeIcon = (props) => {
    const isMobile = useResponsive('down', 'md');

    const {
        isMoveMode,
        moveNode
    } = useSelector(state => state.docsReducer)

    if (props.droppable) {
        return <FolderIcon sx={{ fontSize: isMobile ? 24 : 28, mr: isMobile ? 0 : 2 }} />;
    }
    return <ListAltIcon color={isMoveMode && moveNode.id !== props.id ? 'disabled': 'default'} sx={{ fontSize: isMobile ? 24 : 28, mr: isMobile ? 0 : 2}}/>;

};