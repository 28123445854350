import {combineReducers} from 'redux'
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {rootWatcher} from './saga'
import {dialoguesReducer} from './slice/dialogues.slice'
import {authReducer} from './slice/auth.slice'
import {fileReducer} from './slice/file.slice'
import {channelsReducer} from './slice/channels.slice'
import {intelligensReducer} from './slice/intelligens.slice'
import {usersReducer} from './slice/users.slice'
import {sandboxDataReducer} from './slice/sandboxData.slice'
import {sandboxReducer} from './slice/sandbox.slice'
import {serviceReducer} from './slice/service.slice'
import {knowledgesReducer} from "./slice/knowledges.slice";
import {docsReducer} from "./slice/dosc.slice";
import {diagrammSlice, diagramReducer} from "./slice/diaframm.slice";
import {tableStatesReducer} from "./slice/tableStates.slice";
import {statisticsReducer} from "./slice/statistics.slice";
//import sseApi from "./api/api";

const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
  authReducer,
  fileReducer,
  dialoguesReducer,
  channelsReducer,
  intelligensReducer,
  usersReducer,
  sandboxReducer,
  sandboxDataReducer,
  serviceReducer,
  knowledgesReducer,
  docsReducer,
  diagramReducer,
  tableStatesReducer,
  statisticsReducer,
  // [sseApi.reducerPath]: sseApi.reducer,
})

const persistConfig = {
  key: 'root',
  storage, // ~ storage: storage
  // blacklist: [''],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  // devTools: true, // new
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      sagaMiddleware, // добавляем sagaMiddleware в качестве middleware в магазин Redux
      // sseApi.middleware // Add the RTK-Query middleware to the middleware chain
    ),
})
// // Добавьте action creator для сброса состояния
// const resetState = () => ({
//   type: 'RESET_STATE',
// })
// // Слушаем событие reset от Redux DevTools
// window.__REDUX_DEVTOOLS_EXTENSION__.connect({ features: { state: true } }).subscribe((message) => { //действие при сбросе хранилища через redux devtools
//   // if (message.type === 'STOP') {  // вызывается при закрытии окна redux devtools
//   //   localStorage.removeItem('persist:root')
//   //   // store.dispatch({ type: 'RESET' })
//   //   // Принудительная перезагрузка страницы
//   //   window.location.reload() // или window.location.href = window.location.href;
//   // }
// })

sagaMiddleware.run(rootWatcher)

export const persistor = persistStore(store)
