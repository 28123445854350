import KnowledgeManagement from "../../pages/KnowledgeManagement";
import FileKnowledgesTree from "../FileKnowledgesTree";
import {useCallback, useRef} from "react";
import {$authHost} from "../../http";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";

const Knowledges = () => {

  const {
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)

  const {
    chosenNodes
  } = useSelector(state => state.docsReducer)

  const {
    setTree,
    setChosenNodesById,
    setIntelligens,
    setSelectedIntellegence
  } = useActions()


  const ref = useRef(null);

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/model_management/study_file/get_all');
        return response.data;
      }
      newChannelList().then((data) => {
        setTree(data);
        setChosenNodesById(selectedIntellegence.main_assistant.files)
      });
    } catch (e) {
    }
  }, []);

  const loadMainData = useCallback((selected = null) => {
    try {
      const newintellectsList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newintellectsList().then((data) => {
        setIntelligens(data)
        if(selected !== null){
          setSelectedIntellegence(selected)
        }
      })
    } catch (e) {
    }

  }, [])

  return (
      <div>
        {/*<p>*/}
        {/*  chosenNodes:*/}
        {/*  <span>*/}
        {/*        {chosenNodes.length === 0*/}
        {/*            ? "ничего"*/}
        {/*            : chosenNodes.map((n) => n.name).join(", ")}*/}
        {/*  </span>*/}
        {/*</p>*/}
        <div style={{marginBottom: '18px'}}/>
        <FileKnowledgesTree
            visualChoice={true}
            loadData={loadData}
            refTree={ref}
            canDrop={false}
            canDrag={false}
            loadMainData={loadMainData}
        />
      </div>


  )
}

export default Knowledges;
