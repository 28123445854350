// @mui
import { Container, Link, Typography } from '@mui/material'
// hooks
import useResponsive from '../hook/useResponsive'
// sections
import { LoginForm } from '../components/Forms/LoginForm'
import { useTranslation } from 'react-i18next'
import { StyledContent, StyledRoot } from '../theme/standarts_styles'
import React from 'react'

export default function Login () {
  const { t } = useTranslation()
  const mdUp = useResponsive('up', 'md')

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom align="center">
              {t('src.pages.Login.authorization')}
            </Typography>
            <LoginForm/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  )
}
