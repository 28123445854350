import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {ArrowRight} from "@mui/icons-material";
import {TypeIcon} from "./TypeIcon";
import dayjs from "dayjs";
import styles from "./CustomNode.module.css";
import {useDragOver} from "@minoru/react-dnd-treeview";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Tooltip
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-i18next";
import {CustomNodeDiv, CustomNodeEditGrid, RowOptionsStack} from "../../theme/standarts_styles";
import {formatFileSize} from "../../utils/formatSize";
import DownloadIcon from "@mui/icons-material/Download";
import {$authHost} from "../../http";
import {Skeleton} from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Iconify from "../iconify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useResponsive from "../../hook/useResponsive";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";


const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const utcOffset = dayjs().utcOffset();


export const CustomNode = ({testIdPrefix = "", ...props}) => {
  const {id, droppable, size, created_at, name, description} = props.node;
  const indent = props.depth * 24;
  const {t} = useTranslation()

  const [visibleInput, setVisibleInput] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [labelText, setLabelText] = useState(name);
  const [labelDescription, setLabelDescription] = useState(description);
  const [error, setError] = useState(false);

  const isMobile = useResponsive('down', 'md')
  // const isMobile = useResponsive('down', 'md')

  const {
    setIsMoveMode,
    setMoveNode
  } = useActions()

  const {
    isMoveMode,
    moveNode
  } = useSelector(state => state.docsReducer)


  const handleClick = (e) => {
    if (isMoveMode && !props.node.droppable) {
      return false;
    } else {
      switch (e.detail) {
        case 1: {
          if (isMoveMode) {
            moveNodeInMobile(props.node);
          } else {
            props.onClick(e, props.node);
          }
          break;
        }
        case 2: {
          if (!props.visualChoice && !isMoveMode) {
            handleShowMode()
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle();
  };

  const moveNodeInMobile = (parent) => {
    let newData = {
      id: moveNode.id,
      name: moveNode.name,
      description: moveNode.description,
      droppable: moveNode.droppable,
      created_at: moveNode.created_at,
      size: moveNode.size,
      parent: parent.id
    }
    props.onChangeNode({id: moveNode.id, name: moveNode.name, description: moveNode.description, node: newData})
    setIsMoveMode(false);
  };

  const handleShowMode = () => {
    setViewMode(true);
  };

  const handleShowInput = () => {
    setViewMode(false);
    setVisibleInput(true);
    setAnchorEl(null);
  };

  const handleDownLoad = async (id, name) => {
    setAnchorEl(null);
    try {
      const response = await $authHost.get(`/model_management/study_file/download/${id}`, {
        responseType: 'blob', // Устанавливаем тип ответа на blob
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name) // Задаем имя файла для скачивания
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (e) {
    } finally {
    }
  };

  const handleMove = (node) => {
    setAnchorEl(null);
    setIsMoveMode(true);
    setMoveNode(node);
  };

  const handleCancel = () => {
    setLabelText(name);
    setLabelDescription(description);
    setVisibleInput(false);
    setViewMode(false);
  };
  const handleChangeText = (e) => {
    if (e.target.value === "") {
      setError(true);
    } else {
      setError(false);
    }
    setLabelText(e.target.value);
  };
  const handleChangeDescription = (e) => {
    setLabelDescription(e.target.value);
  };

  const handleSubmit = () => {
    if (!error) {
      setVisibleInput(false);
      props.onChangeNode({id: id, name: labelText, description: labelDescription, node: props.node})
    }
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleSelect = () => props.onSelect(props.node);


  if (props.isSelected) {
    props.containerRef.current?.classList.add(styles.selected);
  } else {
    props.containerRef.current?.classList.remove(styles.selected);
  }

  if (props.isDragging) {
    props.containerRef.current?.classList.add(styles.dragging);
  } else {
    props.containerRef.current?.classList.remove(styles.dragging);
  }


  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <>
      {visibleInput || viewMode ?
        <Box sx={{
          p: {xl: 2, lg: 2, md: 2, sm: 1, xs: 1}
        }}>
          <CustomNodeEditGrid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              p: 3,
            }}
            boxShadow={3}
          >
            <Grid item xs={12} sx={{width: '100%', mb: 3}}>

              <Typography variant="subtitle1">
                {viewMode ?
                  t('src.pages.KnowledgeManagement.view')
                  :
                  t('src.pages.KnowledgeManagement.edit')
                }
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{width: '100%'}}
            >
              <Stack
                sx={{width: '100%'}}
                direction={{xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column'}} spacing={2}
              >

                <TextField
                  sx={{width: '100%'}}
                  label={t('src.pages.KnowledgeManagement.title')}
                  variant={viewMode ? "standard" : "outlined"}
                  error={!!error}
                  value={labelText}
                  InputProps={{
                    readOnly: viewMode
                  }}
                  helperText={!!error ? t('main.another.requiredField') : ''}
                  onChange={handleChangeText}
                />
                <TextField
                  sx={{width: '100%'}}
                  label={t('src.pages.KnowledgeManagement.description')}
                  variant={viewMode ? "standard" : "outlined"}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: viewMode
                  }}
                  value={labelDescription}
                  onChange={handleChangeDescription}
                />
              </Stack>
            </Grid>
            {viewMode ?
              <Grid item xs={12} sx={{width: '100%', mt: 3}}>
                <Button
                  onClick={handleShowInput}
                  variant="outlined"
                  sx={{mr: 2}}
                  size={isMobile ? 'small' : 'medium'}
                >
                  {t('src.pages.KnowledgeManagement.edit')}
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="text"
                  size={isMobile ? 'small' : 'medium'}
                >
                  {t('src.pages.KnowledgeManagement.close')}
                </Button>
              </Grid>
              :
              <Grid item xs={12} sx={{width: '100%', mt: 3}}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{mr: 2}}
                  size={isMobile ? 'small' : 'medium'}
                >
                  {t('src.pages.KnowledgeManagement.save')}
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="text"
                  size={isMobile ? 'small' : 'medium'}
                >
                  {t('src.pages.KnowledgeManagement.close')}
                </Button>
              </Grid>
            }

          </CustomNodeEditGrid>
        </Box>
        :
        <CustomNodeDiv
          // className={`tree-node ${styles.root}`}
          {...dragOverProps}
          onClick={handleClick}
          selected={props.isSelected}
        >

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={props.visualChoice ? 12 : 11} sx={{overflowX: 'hidden'}}
            >
              {isMobile ?
                <>
                  <Grid
                    item
                    // xs={props.visualChoice && !props.node.droppable ? 3 : 2}
                    xs="auto"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{height: '100%', my: 0}}
                    >
                      <div
                        className={`${styles.expandIconWrapper} ${
                          props.isOpen ? styles.isOpen : ""
                        }`}
                      >
                        {props.node.droppable ?
                          <div onClick={handleToggle}>
                            <ArrowRight/>
                          </div>
                          :
                          <div style={{width: '24px'}}/>
                        }
                      </div>
                      <div>
                        {props.node.parent !== 0 ?
                          <div style={{width: '24px'}}/>
                          :
                          <div style={{width: '0px'}}/>
                        }
                      </div>
                      {props.visualChoice && !props.node.droppable &&
                        <>
                          {props.Queryloading === true && props.EditedNodeId === props.node.id ?
                            <CircularProgress
                              size={39 - 16}
                              sx={{m: 1}}
                            />
                            :
                            <Checkbox
                              color="primary"
                              size="small"
                              checked={props.isSelected}
                              onClick={handleSelect}
                              disabled={props.Queryloading === true}
                            />
                          }
                        </>
                      }
                      <TypeIcon droppable={droppable} id={props.node.id}/>
                    </Stack>
                  </Grid>
                  <Grid item xs="auto" sx={{width: 'calc(100vw - 170px)'}}>
                    <ListItem
                      alignItems="center"
                    >
                      <Grid
                        container
                        sx={{
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          flexWrap: 'nowrap',
                        }}
                        alignItems="center"
                      >
                        <ListItemText
                          sx={{overflowX: 'hidden', width: '100%', m: 0}}
                          primary={
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              variant="body2"
                              color={props.node.droppable ? 'text.primary' : !isMoveMode ? 'text.primary' : isMoveMode && moveNode.id === props.node.id ? 'text.primary' : 'text.disabled'}
                            >
                              {props.node.name}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                              >
                                <Typography
                                  sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  variant="body3"
                                >
                                  {`${formatFileSize(props.node.size)} ${dayjs(props.node.created_at).add(utcOffset, 'm').format('DD.MM.YYYY') || ''}`}
                                </Typography>
                              </Stack>
                            </React.Fragment>
                          }
                        />
                      </Grid>
                    </ListItem>
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={4}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{height: '100%', my: 0}}
                    >
                      <div
                        className={`${styles.expandIconWrapper} ${
                          props.isOpen ? styles.isOpen : ""
                        }`}
                      >
                        {props.node.droppable ?
                          <div onClick={handleToggle}>
                            <ArrowRight/>
                          </div>
                          :
                          <div style={{width: '24px'}}/>
                        }
                      </div>
                      <div>
                        {props.node.parent !== 0 ?
                          <div style={{width: '24px'}}/>
                          :
                          <div style={{width: '0px'}}/>
                        }
                      </div>
                      {props.visualChoice && !props.node.droppable &&
                        <>
                          {props.Queryloading === true && props.EditedNodeId === props.node.id ?
                            <CircularProgress
                              size={39 - 16}
                              sx={{m: 1}}
                            />
                            :
                            <Checkbox
                              color="primary"
                              size="small"
                              checked={props.isSelected}
                              onClick={handleSelect}
                              disabled={props.Queryloading === true}
                            />
                          }
                        </>
                      }
                      <TypeIcon droppable={droppable}/>
                      <Typography
                        variant={props.node.droppable ? 'body1' : 'body2'}
                        // sx={{pr: 2}}
                        sx={{mr: 2}}
                        className="lessText"
                      >
                        {props.node.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
                  >
                    <Typography variant="body2" className="lessText">{props.node.description}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
                  >
                    <Typography variant="body2" sx={{my: 0}}>{formatFileSize(props.node.size)}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
                  >
                    <Typography variant="body2" sx={{my: 0}}>
                      {dayjs(props.node.created_at).add(utcOffset, 'm').format('DD.MM.YYYY') || ''}
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>

            {!props.visualChoice &&
              <Grid item xs={1} justifyContent={isMobile ? 'center' : 'flex-end'} display="flex">
                {isMobile ?
                  <>
                    <IconButton disabled={isMoveMode}>
                      <MoreVertIcon onClick={handleOpenMenu} color={isMoveMode ? 'disabled' : 'default'}/>
                    </IconButton>
                    <Popover
                      id="opendialogOption"
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{zIndex: '10000'}}
                      PaperProps={{
                        sx: {
                          p: 1,
                          mt: 1.5,
                          ml: 0.75,
                          width: 180,
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        },
                      }}
                    >
                      {!props.node.droppable &&
                        <MenuItem onClick={() => handleMove(props.node)}>
                          <Iconify icon={'eva:folder-outline'} sx={{mr: 1}}/>
                          {t('src.pages.Channels.move')}
                        </MenuItem>
                      }
                      {!props.node.droppable &&
                        <MenuItem onClick={() => handleDownLoad(id, name)}>
                          <Iconify icon={'eva:download-outline'} sx={{mr: 1}}/>
                          {t('src.pages.Channels.download')}
                        </MenuItem>
                      }
                      <MenuItem onClick={handleShowInput}>
                        <Iconify icon={'eva:edit-fill'} sx={{mr: 1}}/>
                        {t('src.pages.Channels.edit')}
                      </MenuItem>
                      <MenuItem onClick={() => props.onDelete(id)}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{mr: 1}}/>
                        {t('src.pages.Channels.delete')}
                      </MenuItem>
                    </Popover>
                  </>
                  :
                  <RowOptionsStack>
                    {!props.node.droppable &&
                      <Tooltip title={t('src.pages.Channels.download')}>
                        <IconButton size="large" onClick={() => handleDownLoad(id, name)}>
                          <DownloadIcon sx={{color: 'layout.accent_element'}}/>
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip title={t('src.pages.Channels.edit')}>
                      <IconButton size="large" onClick={handleShowInput}>
                        <EditIcon sx={{color: 'layout.accent_element'}}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('src.pages.Channels.delete')}>
                      <IconButton size="large" onClick={() => props.onDelete(id)}>
                        <DeleteIcon sx={{color: 'layout.accent_element'}}/>
                      </IconButton>
                    </Tooltip>
                  </RowOptionsStack>
                }
              </Grid>
            }
          </Grid>
        </CustomNodeDiv>
      }
    </>
  );
};
