import React, {useCallback, useEffect} from 'react'
import Stack from '@mui/material/Stack'
import {Breadcrumbs, Container, Link, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import CommonSettings from '../../components/Intelligence/CommonSettings'
import Knowledges from '../../components/Intelligence/Knowledges'
import Education from '../../components/Intelligence/Education'
import ProductCatalog from '../../components/Intelligence/ProductCatalog'
import ProductDescription from '../../components/Intelligence/ProductDescription'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useActions} from '../../hook/useActions'
import {useSelector} from 'react-redux'
import DynamicTabs from '../../components/Tabs/DynamicTabs'
import {TitleStack} from "../../theme/standarts_styles";
import BreakerPrompt from "../../components/Intelligence/BreakerPrompt";
import MainPrompt from "../../components/Intelligence/MainPrompt";
import KnowledgeManagement from "../KnowledgeManagement";
import Settings from "../../components/Intelligence/Settings";
import {$authHost} from "../../http";
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";

const ModelManagement = () => {

  const {t} = useTranslation()
  const location = useLocation()

  const {
    setModelTypeFormIsAdd,
    setSelectedIntellegence,
    setIntelligens
  } = useActions()

  const {
    modelTypeFormIsAdd,
    selectedIntellegence
  } = useSelector(state => state.intelligensReducer)

  function readLocation() {
    var pathArray = window.location.pathname.split('/')
    switch (pathArray[2]) {
      case 'new':
        setModelTypeFormIsAdd(true)
        setSelectedIntellegence(null)
        break
      case 'edit':
        setModelTypeFormIsAdd(false)
        setSelectedIntellegence(pathArray[3])
        break
      default:
        break
    }
  }

  useEffect(() => {
    readLocation()
  }, [])

  const tabs = [
    {label: t('src.pages.ModelManagement.settings'), path: 'settings', disabled_tab: false},
    {label: t('src.pages.ModelManagement.knowledge'), path: 'knowledge', disabled_tab: selectedIntellegence === null},
    {label: t('src.pages.ModelManagement.training'), path: 'training', disabled_tab: selectedIntellegence === null},
  ]

  const loadData = useCallback((selected = null) => {
    try {
      const newintellectsList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newintellectsList().then((data) => {
        setIntelligens(data)
        if(selected !== null){
          setSelectedIntellegence(selected)
        }
      })
    } catch (e) {
    }

  }, [])

  //Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (
      !tabs.some((tab) => location.pathname.endsWith(tab.path)) &&
      !location.pathname.endsWith('new')
  ) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith('/')) {
      redirectPath += '/';
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }


  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {modelTypeFormIsAdd ? t('src.pages.ModelManagement.newModel') : t(
            'src.pages.ModelManagement.modelManagement')}
        </Typography>
      </TitleStack>
      <Breadcrumbs separator="-" aria-label="breadcrumb" sx={{mb: 2}}>
        {/*<Link underline="hover" href="/">*/}
        {/*  <Typography color="text.secondary" variant="body2">*/}
        {/*    {t('src.pages.MainPage.head')}*/}
        {/*  </Typography>*/}
        {/*</Link>*/}
        <Link underline="hover" href="/intelligence">
          <Typography color="text.secondary" variant="body2">
            {t('src.pages.Intelligence.header')}
          </Typography>
        </Link>
        {modelTypeFormIsAdd ?
          <Typography color="text.primary" variant="body2">{t('src.pages.ModelManagement.newModel')}</Typography>
          :
          <Typography color="text.primary" variant="body2">{t('src.pages.ModelManagement.modelManagement')}</Typography>
        }
      </Breadcrumbs>
      <RemoteDynamicTabs tabs={tabs}/>
      <Outlet/>
      {/*<DynamicTabs*/}
      {/*  components={[*/}
      {/*    {label: t('src.pages.ModelManagement.settings'), component: <Settings/>, disabled: false},*/}
      {/*    {label: t('src.pages.ModelManagement.knowledge'), component: <Knowledges/>, disabled: selectedIntellegence === null},*/}
      {/*    {label: t('src.pages.ModelManagement.training'), component: <Education/>, disabled: selectedIntellegence === null},*/}
      {/*  ]}*/}
      {/*/>*/}
    </Container>
  )
}

export default ModelManagement