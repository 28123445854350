import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './header/Header'
import { useSelector } from 'react-redux'
import Nav from './nav/Nav'
import { Main, StyledRoot } from '../theme/standarts_styles'
import useResponsive from '../hook/useResponsive'
import { useActions } from '../hook/useActions'

export default function DashboardLayout () {

  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')

  const { status } = useSelector(state => state.authReducer)
  const { openNav } = useSelector(state => state.serviceReducer)
  const {
    setOpenNav,
    toggleOpenNav,
  } = useActions()

  useEffect(() => {  //закрываем боковое меню при навигации с мобильного устройства
    if (openNav && !isDesktop) {
      toggleOpenNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {  //закрываем боковое меню при входе в режим мобильного устройства
    if (!isDesktop) {
      setOpenNav(false)
    }
  }, [isDesktop])

  return (
    <StyledRoot>
      <Header/>
      {
        status === 'Active' &&
        <Nav/>
      }
      <Main>
        <Outlet/>
      </Main>
    </StyledRoot>
  )
}
