import React from 'react';
import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import {useTranslation} from "react-i18next";

const LoaderTraining = ({...props}) => {

    const { t } = useTranslation()

    const {

    } = useSelector(state => state.intelligensReducer)

    switch (props.asist.status) {
        case 'validating_files':
            return (
                <>
                    <Box sx={{px:2, py:0, mb:4}}>
                        <Typography variant="body1" color="text.secondary">
                            {t('src.components.Intelligence.startStudy')} {props.asist.events}
                        </Typography>
                        <LinearProgress color="disabled"/>
                    </Box>
                </>
            )
        case 'queued':
            return (
                <>
                    <Box sx={{px:2, py:0, mb:4}}>
                        <Typography variant="body1" color="text.secondary">
                            {t('src.components.Intelligence.startStudy')} {props.asist.events}
                        </Typography>
                        <LinearProgress color="inherit" value={100}/>
                    </Box>
                </>
            )
        case 'running':
            return (
                <>
                    <Box sx={{px:2, py:0, mb:4}}>
                        <Typography variant="body1" color="text.secondary">
                            {t('src.components.Intelligence.startStudy')} {props.asist.events}
                        </Typography>
                        <LinearProgress />
                    </Box>
                </>
            )
        default:
            break
    }

        //     return (
        // <>
        //     {/*{*/}
        //     {/*    studiedAssistants.map((x, index)=>{*/}
        //     {/*        if(x.id === props.asist_id){*/}
        //     {/*            return(*/}
        //                     <Box sx={{px:2, py:0, mb:4}}>
        //                         <Typography variant="body1" color="text.secondary">
        //                             {t('src.components.Intelligence.startStudy')} {props.asist.events}
        //                         </Typography>
        //                         <LinearProgress />
        //                     </Box>
        //                 {/*)*/}
        //             {/*}*/}
        //         {/*})*/}
        //     {/*}*/}
        //
        // </>

};

export default LoaderTraining;