import React from 'react';
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import UserData from "../components/Account/UserData";
import ChangePassword from "../components/Account/ChangePassword";
import DynamicTabs from '../components/Tabs/DynamicTabs';
import {TitleStack} from "../theme/standarts_styles";

const AccountPage = () => {

  const {t} = useTranslation()

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t('src.pages.AccountPage.head')}
        </Typography>
      </TitleStack>
      <DynamicTabs
        components={[
          {label: t('src.pages.AccountPage.tab1'), component: <UserData/>},
          {label: t('src.pages.AccountPage.tab2'), component: <ChangePassword/>},
        ]}
      />
    </Container>
  );
};

export default AccountPage;
