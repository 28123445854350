import React from 'react'
import CustomTextField from '../../Fields/CustomTextField'
import { useTranslation } from 'react-i18next'
import CustomMultiTextFieldAutocompleteForObject from '../../Fields/CustomMultiTextFieldAutocompleteForObject'

const AccessUserFields = ({ autocompleteOptions }) => {

  const { t } = useTranslation()

  return (
    <>
      <CustomTextField
        name="email"
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_email', 'E-mail')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        disabled={true}
        sx={{ width: '100%', fontSize: '12px', mb: 3 }}
        size="small"
      />
      <CustomTextField
        name="name"
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_name', 'Имя')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        sx={{ width: '100%', fontSize: '12px', mb: 3 }}
        size="small"
      />
      <CustomTextField
        name="last_name"
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Фамилия')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        sx={{ width: '100%', fontSize: '12px', mb: 3 }}
        size="small"
      />
        {/*<CustomTextField*/}
        {/*    name="department"*/}
        {/*    label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Отдел')}*/}
        {/*    sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
        {/*    size="small"*/}
        {/*/>*/}
        {/*<CustomTextField*/}
        {/*    name="job_title"*/}
        {/*    label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Должность')}*/}
        {/*    sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
        {/*    size="small"*/}
        {/*/>*/}
      <CustomMultiTextFieldAutocompleteForObject
        multiple={true}
        name="groups"
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_groups', 'Группа')}
        options={autocompleteOptions}
        rules={{
          required: t('main.another.requiredField'),
        }}
        sx={{ width: '100%', fontSize: '12px', mb: 3 }}
        size="small"
      />
    </>
  )
}

export default AccessUserFields