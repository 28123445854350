import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  knowledges: [
    {
      id: "05843906",
      knowledge_type: "продуктовый каталог",
      source_type: "database",
      description:"yhyh fvfddb gddg fg r  h h hrnth"
    }
  ],
  selectedKnowledge: null,
  fields:[],
  selectedFields:[],
  uploadFile: []
};

const knowledgesSlice = createSlice({
  name: "knowledges",
  initialState,
  reducers: {
    logOut() { return initialState },
    setKnowledges(state, { payload }) { state.channels = payload },
    setSelectedKnowledge(state, { payload }) {
      const index = state.knowledges.findIndex(
          (project) => project.id === payload,
      );
      state.selectedKnowledge = state.knowledges[index];
    },
    addFields(state, { payload }) {
      state.selectedFields = [...state.selectedFields, payload];
    },
    updateQuestionInSelectedQuestions(state, {payload}){
      const FindIndex = state.selectedFields.findIndex(
          (question) => question.index === payload.id
      );
      switch (payload.name) {
        case "name": {
          state.selectedFields[FindIndex].name = payload.value;
          break;
        }
        case "type": {
          state.selectedFields[FindIndex].type = payload.value;
          break;
        }
        default :
          break;
      }
    },
    deleteFields(state, { payload }) {
      const FindIndex = state.selectedFields.findIndex(
          (question) => question.index === payload
      );
      state.selectedFields = state.selectedFields.slice(0, FindIndex).concat(state.selectedFields.slice(FindIndex + 1));
    },
    setSelectedFields(state, {payload}) {
      state.selectedFields = payload;
    },
    setUploadFile(state, {payload}) {
      state.uploadFile = payload;
    },
    addUploadFile(state, {payload}){
      state.uploadFile = [...state.uploadFile, payload];
    },
    deleteUploadFile(state, { payload }) {
      const FindIndex = state.uploadFile.findIndex(
          (file) => file.file.path === payload
      );
      state.uploadFile = state.uploadFile.slice(0, FindIndex).concat(state.uploadFile.slice(FindIndex + 1));
    },
  },
});

export const { actions: knowledgesActions, reducer: knowledgesReducer } = knowledgesSlice
// export const { addNewMessage, addChat } = dialoguesSlice
export default knowledgesSlice.reducer;
