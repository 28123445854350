import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    sandboxDialog:[],
    sandboxIntellect:"",
    sandboxChannel: null,
};


const sandboxSlice = createSlice({
    name: "sandbox",
    initialState,
    reducers: {
        logOut() { return initialState },
        setSandboxDialog(state, { payload }) { state.sandboxDialog = payload },
        addSandboxMessage(state, {payload}) {state.sandboxDialog = [...state.sandboxDialog, payload]},
        selectSandboxIntellect(state, {payload}) {state.sandboxIntellect = payload},
        selectSandboxChannel(state, {payload}) {state.sandboxChannel = payload},
    },
});

export const { actions: sandboxActions, reducer: sandboxReducer } = sandboxSlice
// export const { addNewMessage, addChat } = sandboxSlice
export default sandboxSlice.reducer;