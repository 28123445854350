export const formatFileSize = function(bytes) {
    const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if(bytes === '' || bytes === 0){
        return ''
    }else
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const chekMaxSize = function (bytes) {
    let mb = (bytes / 536870912).toFixed(2);
    if(bytes === '' || bytes === 0){
        return ''
    }else
        return mb <= 1;
}