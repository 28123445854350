import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  Switch, Tooltip,
  Typography,
} from '@mui/material';
import Iconify from '../components/iconify';
import {$authHost} from "../http";
import {useActions} from "../hook/useActions";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingSpinner from "../components/modals/loadingSpinner";
import {TableCellsWrap} from "../components/TableCellsWrap";
import MaterialTable from "../components/MaterialTable";
import {RowOptionsStack, TitleStack} from "../theme/standarts_styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export default function Channels() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [confirmationId, setConfirmationId] = useState(null);

  const tableColumns = [
    {
      accessorKey: 'name',
      header: t('src.pages.Channels.name'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intellect',
      header: t('src.pages.Channels.intellect'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'channel_type',
      header: t('src.pages.Channels.channelType'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'token',
    //   header: t('src.pages.Channels.token'),
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'introduction',
      header: t('src.pages.Channels.introduction'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title={t('src.pages.Channels.confirm')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('src.pages.Channels.cancel')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title={t('src.pages.Channels.edit')}>
              <IconButton size="large" color="inherit" onClick={() => handleGoToEditPage(row.original.id)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title={row.original.is_run ? t('src.pages.Channels.turnOff') : t('src.pages.Channels.turnOn')}>
              <Switch checked={String(row.original.is_run) === 'true'} size="small"
                      disabled={!row.original.intellect}
                      onChange={(event, checked) => handleIsRun(row.original.id, checked)}/>
            </Tooltip>
            <Tooltip title={t('src.pages.Channels.delete')}>
              <IconButton size="large" color="inherit" onClick={() => setConfirmationId(row.original.id)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </RowOptionsStack>
        )
      }
    },
  ]

  const [loading, setLoading] = useState(false);

  const {
    setChannels,
    setChannelsTypeFormIsAdd,
    setSelectedChannel,
    setSelectedQuestions
  } = useActions();

  const {
    channels,
    channelsTypeFormIsAdd,
    selectedChannel,
    questions,
    selectedQuestions
  } = useSelector(state => state.channelsReducer)

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/channel/');
        return response.data;
      }
      newChannelList().then((data) => {
        setChannels(data)
      });
    } catch (e) {
    }
  }, []);

  function handleGoToAddPage(selectedChannel = null) {
    navigate('/channels/new/general')
  }

  async function handleIsRun(id_row, is_run) {
    setLoading(true);
    try {
      await $authHost.patch(`/channel/set_is_run?id=${id_row}&bool=${is_run}`)
        .then(response => {
          loadData();
        })
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      await $authHost.delete(`/channel/delete_row?id=${id_row}`)
        .then(response => {
          loadData();
          setConfirmationId(null);
        })
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  function handleGoToEditPage(selectedChannel) {
    navigate(`/channels/edit/${selectedChannel}`);
    setSelectedChannel(selectedChannel);
    setSelectedQuestions(selectedChannel);
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      {loading && <LoadingSpinner/>}
      <Container>
        <TitleStack>
          <Typography variant="h4">
            {t('src.pages.Channels.channel')}
          </Typography>
        </TitleStack>

        <Stack direction="row" sx={{height: '70px'}} alignItems="center" justifyContent="start" mb={0}>
          <Button
            variant="contained"
            onClick={handleGoToAddPage}
            startIcon={<Iconify icon="eva:plus-fill"/>}>
            {t('src.pages.Channels.newChannel')}
          </Button>
        </Stack>
        <Card sx={{pt: 1}}>
          <MaterialTable data={channels} columns={tableColumns}/>
        </Card>
      </Container>
    </>
  );
};
