import React, {useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Autocomplete, Button, Card, Grid, IconButton, TextField, Typography} from "@mui/material";
import CustomTextField from "../Fields/CustomTextField";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import CustomTextFieldAutocomplete from "../Fields/CustomTextFieldAutocomplete";
import {$authHost} from "../../http";
import {useSelector} from "react-redux";
import useResponsive from "../../hook/useResponsive";
import CloseIcon from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";

const EducationAddForm = ({handleClose, loadData}) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation()
  const isMobile = useResponsive('down', 'md')

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    register,
    formState: {errors, isDirty, isSubmitSuccessful},
    handleSubmit,
    control,
    reset,
    setError,
  } = methods;

  const {
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleClose()
    }
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {

    setLoading(true);

    var new_data = {
      question: data.question,
      answer: data.answer,
      assistant_type: data.assistant_type,
      assistant_id:
        selectedIntellegence.breaker_assistant.type === data.assistant_type ?
          selectedIntellegence.breaker_assistant.id
          :
          selectedIntellegence.output_assistant.id,
      is_run: false
    }
    try {
      await $authHost.post(`/model_management/add_row`, new_data).then((response) => {
        if (response.status === 201) {
          handleClose();
          loadData();
        }
      })
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false)
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card sx={{p: 3, mb: 2}}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isMobile &&
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{mb: 2}}
                >
                  <Grid item>
                    <Typography variant='subtitle1' color="primary">
                      {t('main.another.add')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={handleClose}
                    >
                      <CloseIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
              }
              <Grid
                container
                direction='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                <CustomTextField
                  name="question"
                  label={t('src.pages.ModelManagement.question')}
                  registerOptions={{
                    required: t('main.another.requiredField'),
                  }}
                  required
                  sx={{width: '100%', fontSize: '12px', mb: 3}}
                  size="small"
                />
                <CustomTextField
                  name="answer"
                  label={t('src.pages.ModelManagement.answer')}
                  registerOptions={{
                    required: t('main.another.requiredField'),
                  }}
                  required
                  sx={{width: '100%', fontSize: '12px', mb: 3}}
                  size="small"
                />
                <CustomTextFieldAutocomplete
                  name="assistant_type"
                  label={t('src.pages.ModelManagement.assistant_type')}
                  options={[
                    {name: t('src.pages.Intelligence.breaker'), value: "breaker"},
                    {name: t('src.pages.Intelligence.stylist'), value: "output"},
                  ]}
                  rules={{
                    required: t('main.another.requiredField'),
                  }}
                  sx={{width: '100%', fontSize: '12px', mb: 3}}
                  size="small"
                />
              </Grid>
              <Grid item sx={{width: '100%', mb: 2}}>
                {isMobile ?
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{width: '100%', mb: 2}}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{width: '100%'}}
                    >
                      {t('main.another.save')}
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={!isDirty || loading}
                      onClick={() => reset()}
                      sx={{width: '100%'}}
                    >
                      {t('main.another.clear')}
                    </Button>

                  </Stack>
                  :
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Grid item>
                      <Button
                        variant='contained'
                        type='submit'
                        sx={{mr: 2}}
                        disabled={loading}
                      >
                        {t('main.another.save')}
                      </Button>
                      <Button variant='text' onClick={handleClose}>
                        {t('main.another.close')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='text'
                        onClick={() => reset()}
                        disabled={loading}
                      >
                        {t('main.another.clear')}
                      </Button>
                    </Grid>
                  </Stack>
                }
              </Grid>
            </form>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EducationAddForm;