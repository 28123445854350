import React, { Suspense } from 'react'
import { AuthProvider } from './hoc/authProvider'
import { LoadProvider } from './hoc/loadProvider'
import ThemeProvider from './theme'
import { HelmetProvider } from 'react-helmet-async'
import LoadingSpinner from './components/modals/loadingSpinner'
import Router, { getRoutes } from './routes';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';


const router = createBrowserRouter(getRoutes());

function App () {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Suspense fallback={<LoadingSpinner/>}>
          {/*<AuthProvider>*/}
          {/*  <LoadProvider>*/}
          <RouterProvider router={router} />
          {/*    <Router/>*/}
          {/*  </LoadProvider>*/}
          {/*</AuthProvider>*/}
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>

  )
}

export default App
