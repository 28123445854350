import React from 'react';
import {
    Avatar, Chip,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import {useActions} from "../hook/useActions";
import {useSelector} from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import BackHandIcon from "@mui/icons-material/BackHand";
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";
import {generateColor, generateManagerColor} from "../utils/ColoredChips";
import {StyledChipForChats} from "../theme/standarts_styles";
import ChatListElement from "./ChatListElement";
import useResponsive from "../hook/useResponsive";

const SearchResult = (props) => {

    const {t} = useTranslation()
    const isMobile = useResponsive('down', 'md')

    const {
        chats,
        foundChats,
        foundMessages,
        OpenSearchInsideChat,
        selectedCustomer,
        showSearch
    } = useSelector(state => state.dialoguesReducer)

    const {
        setFoundChats,
        setFoundMessages,
        setSearchValue,
        setSearchInsideValue,
        setOpenSearchInsideChat,
        setSearchMessage,
        setShowSearch
    } = useActions()



    const CloseInsideChatSearch = () => {
        setFoundChats([]);
        setFoundMessages([]);
        setSearchMessage(null);
        setOpenSearchInsideChat(false);
        setSearchValue("");
        setSearchInsideValue("");
        if (isMobile){
            setShowSearch(false)
        }
    }



    return (
        <>
            {(foundChats && foundChats.length > 0) &&
                <>
                    <ListSubheader sx={{backgroundColor: 'background.neutral'}}>
                        {t('src.components.searchResult.foundChats')}
                    </ListSubheader>
                    {
                        foundChats.map((chat, index) => {
                            let currentUser = chats.find(el => el.id === chat._id )
                            return (
                                ((currentUser)
                                    && (!props.favoriteFilter || String(currentUser.is_favorite) === "true")
                                    && (!props.waitFilter || currentUser.is_waiting_response)
                                    && (!props.handFilter || !currentUser.is_handler_bot)
                                    && (!props.unreadFilter || currentUser.msg_count > 0)) &&
                                    <ChatListElement index={index} chat={currentUser} listRef={props.listRef} searchMess={null}/>
                            )
                        })
                    }
                </>
            }
            {OpenSearchInsideChat &&
                <>
                    <ListSubheader sx={{backgroundColor: 'background.neutral'}}>
                        {t('src.components.searchResult.searchInChat')}
                    </ListSubheader>
                    <ListItem
                        alignItems="flex-start"
                        sx={{py:1}}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={CloseInsideChatSearch}
                            >
                                <ClearIcon />
                            </IconButton>
                        }
                    >
                        <Grid
                            container
                            className='chats'
                            sx={{
                                overflowY: 'auto',
                                maxHeight: 'calc(99vh - 230px)',
                                flexWrap: 'nowrap',
                            }}
                            alignItems="center"
                        >
                            <ListItemAvatar>
                                <Avatar alt={selectedCustomer?.full_name} />
                            </ListItemAvatar>
                            <ListItemText primary={selectedCustomer?.full_name}/>
                        </Grid>
                    </ListItem>
                </>
            }
            { (foundMessages && foundMessages.length > 0) &&
                <>
                    <ListSubheader sx={{backgroundColor: 'background.neutral'}}>
                        {t('src.components.searchResult.foundMessages')}
                    </ListSubheader>
                    {
                        foundMessages.map( (mess, index) => {
                            let currentChat = chats.find(el => el.id === mess.sender || el.id === mess.recipient )
                            return (
                                ((currentChat)
                                    &&(!props.favoriteFilter || String(currentChat.is_favorite) === "true")
                                    && (!props.waitFilter || currentChat.is_waiting_response)
                                    && (!props.handFilter || !currentChat.is_handler_bot)
                                    && (!props.unreadFilter || currentChat.msg_count > 0)) &&
                                <ChatListElement index={index} chat={currentChat} searchMess={mess} listRef={props.listRef}/>
                            )

                        })
                    }
                </>
            }
        </>
    );
};

export default SearchResult;