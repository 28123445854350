import {hexToRgb} from "@mui/material";

export function generateColor(typeChat) {
    switch (typeChat) {
        case "Web":
            return "warning"
        case "Telegram":
            return "info"
        case "WhatsApp":
            return "success"
        case 'Teams':
            return "primary"
        case 'TeamsBot':
            return "primary"
        case "Discord":
            return "secondary"
        default: return "default"
    }
}

export function generateManagerColor(typeChat) {
    // console.log(hexToRgb('#838383'));
    let color = {R: 131, G:131, B:131}
    return color;
}