import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './Dropzone.css'
import { Box, Button, Card, List, Typography } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FileDescription from './FileDescription'
import { useActions } from '../../../../hook/useActions'
import { useTranslation } from 'react-i18next'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SvgColor from "../../../svg-color/SvgColor";
import {useSelector} from "react-redux";

const icon = (name) => <SvgColor src={`/assets/icons/doctypes/${name}.svg`} sx={{ width: 1, height: 1, color:"text.secondary" }} />;


const docType = [
  { type:'xls', extension: 'application/vnd.ms-excel', icon: icon('file-xls-fill') },
  { type:'xls', extension: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: icon('file-xls-fill') },
  { type:'pptx', extension: 'application/vnd.ms-powerpoint', icon: icon('file-ppt-fill')},
  { type:'pptx', extension: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', icon: icon('file-ppt-fill') },
  { type:'pdf', extension: 'application/pdf', icon: icon('file-pdf-fill') },
  { type:'word', extension: 'application/msword', icon:icon('file-doc-fill') },
  { type:'word', extension: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon:icon('file-doc-fill') },
]


function Dropzone ({ open }) {
  const { t } = useTranslation()

  // const docType = [
  //   { id: 1, label: t(`src.components.Dropzone.price`, 'Прайс лист', 'ru') },
  //   { id: 2, label: t('src.components.Dropzone.subscription', 'Описание продукции', 'ru') },
  //   { id: 3, label: t(`src.components.Dropzone.balances`, 'Торговые остатки', 'ru') },
  // ]

  const {
    uploadFile
  } = useSelector(state => state.knowledgesReducer)

  const {
    // addDescriptionFile,
    // delAllFile,
    addUploadFile,
    setUploadFile,
    // deleteFile
  } = useActions()

  // const [files, setFiles] = useState([])
  const maxFiles = 3

  const findFileExtention = (file) => {
    return docType.find(type => type.extension === file?.type);
  };


  const onDrop = useCallback(acceptedFiles => {
    let remainder = maxFiles
    let rejectedFiles = []

    acceptedFiles.map(file => {

      if (remainder > 0) {
        // console.log(file)

        // setUploadFile(prevFiles => [...prevFiles, file])
        //addDescriptionFile({ path: file.path, id: 'docType', value: docType[0].label })
        addUploadFile({file: file, extention: findFileExtention(file)})
        --remainder
      } else {
        rejectedFiles.unshift(file.path)
      }
    })
    rejectedFiles.length > 0 && alert(
      `${t('src.components.Dropzone.reject', 'Следующие файлы были отклонены из-за превышения лимита файлов:',
        'ru')} ${rejectedFiles}`)

    uploadFile.forEach(existingFile => { // проверяем на совпадение имен уже имеющихся и скинутых файлов
      acceptedFiles = acceptedFiles.filter(file => file.name !== existingFile.file.name)
    });
    // if (acceptedFiles.length > remainder && remainder > 0) {
    //   console.log('acceptedFiles', acceptedFiles);
    //   acceptedFiles.length = remainder
    //   // addFile(acceptedFiles)
    //   setFiles([...acceptedFiles, ...files])
    // } else if (remainder > 0) {
    //   // addFile(acceptedFiles)
    //   setFiles([...acceptedFiles, ...files])
    // }
  }, [uploadFile, maxFiles])

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: maxFiles,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.ms-application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    onDrop,
  })





  // const saveHandler = async (event) => {
  //   var csvData = [];
  //   if (files.length === 1) {
  //     const file = files[0];
  //     const reader = new FileReader();
  //     reader.onload = async (event) => {
  //       const wb = read(event.target.result);
  //       const sheets = wb.SheetNames;
  //       const anti_bug = []
  //       if (sheets.length) {
  //         csvData = utils.sheet_to_json(wb.Sheets[sheets[0]]);
  //         try {
  //           const data = await $authHost.post(`/substitution-add?id_user=${userId}`, { substitution: csvData, reference: anti_bug })
  //             .then(response => {
  //               Progress_alert(response.data);
  //             });
  //         } catch (e) {
  //         } finally {
  //         }
  //       }
  //     }
  //     reader.readAsArrayBuffer(file);
  //   }
  // }

  // async function Progress_alert(uuid) {
  //   let Response_message = "";
  //   let Response_progress = 0
  //   if (uuid != null) {

  //     const container = document.createElement('div');
  //     container.classList.add("progress-container");
  //     container.id = "progress-container-id";
  //     document.getElementById("root").append(container);
  //     const wrapper = document.createElement('div');
  //     wrapper.classList.add("progress-wrapper");
  //     wrapper.id = "alert_place";
  //     wrapper.innerHTML = [
  //       `<div class="alert alert-success alert-dismissible fade show mt-3" id="alert_progress" role="alert">`,
  //       `   <div id="before_status"><strong>Сохранение изменений</strong></div>`,
  //       `   <div class="progress">`,
  //       `   <div class="progress-bar bg-success progress-bar-striped" role="progressbar" id="query_progress" style=" width: 0%"></div>`,
  //       `</div>`,
  //       '</div>'
  //     ].join('')
  //     container.appendChild(wrapper);

  //     console.log(Response_message);
  //     while (1) {
  //       const data = await $authHost.get(`/task-status-get?task=${uuid}`).then(response => {
  //         Response_message = response.data.status
  //         Response_progress = response.data.progress
  //       });
  //       console.log(Response_message);
  //       const bar = document.getElementById('query_progress');
  //       bar.style.width = String(Response_progress) + "%";
  //       if (Response_message === "queued") {
  //         if (!document.getElementById('status_mess')) {
  //           const mess = document.createElement('div');
  //           mess.innerHTML = [
  //             `<div id="status_mess">Ваш запрос в очереди....</div>`,
  //           ].join('')
  //           document.getElementById('before_status').after(mess);
  //         }
  //       }
  //       if (Response_message === "started") {
  //         if (document.getElementById('status_mess')) {
  //           document.getElementById('status_mess').remove();
  //         }
  //       }
  //       if (Response_message === "failed") {
  //         const appendAlert_u = () => {

  //           const dang_wrapper = document.createElement('div');
  //           dang_wrapper.classList.add("progress-wrapper");
  //           dang_wrapper.id = "alert_place";
  //           dang_wrapper.innerHTML = [
  //             `<div class="alert alert-danger alert-dismissible fade show mt-3" role="alert">`,
  //             `   <div>Призошла ошибка сохранения...</div>`,
  //             '</div>'
  //           ].join('');
  //           container.replaceChild(dang_wrapper, wrapper);

  //         }
  //         appendAlert_u();
  //         await new Promise(r => setTimeout(r, 2000));
  //         break;
  //       }
  //       if (Response_message === "finished") {
  //         setFiles([])
  //         break;
  //       }
  //       await new Promise(r => setTimeout(r, 2000));
  //     }
  //     console.log(document.getElementById('progress-container-id'))
  //     document.getElementById('alert_place').remove();
  //     document.getElementById('progress-container-id').remove();
  //   }

  // }

  useEffect(() => {
    return () => {
      // delAllFile()
      setUploadFile([])
    }
  }, [])

  return (
    <Box sx={{ flexDirection: 'column'}}>
      <Box className="dropzone-div">
        {/*вынести стили из css в standart_styles.jsx*/}
            <Box {...getRootProps({ className: 'dropzone' })}>
              <input
                  {...getInputProps()}
                  name="file"
              />
              <Box display="flex" flexDirection="column" alignItems="center" sx={{width: '100%'}} >
                <UploadFileIcon sx={{ fontSize: '50px', color: "text.secondary" }}/>
                {isDragAccept && (
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {t('src.components.Dropzone.releaseToDrag')}
                  </Typography>
                )}
                {isDragReject && (
                  <Typography variant="subtitle1" color="error.dark" gutterBottom>
                    {t('src.components.Dropzone.fileReject')}
                  </Typography>
                )}
                {!isDragActive && (
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    <span dangerouslySetInnerHTML={{ __html: t('src.components.Dropzone.drugAndDrop', { maxFiles }) }}/>
                  </Typography>
                )}
                {/* {isDragAccept && (<p className="text-body-tertiary my-auto">Отпустите, чтобы перетащить Excel-файл сюда</p>)}
              {isDragReject && (<p className="text-body-tertiary my-auto">Некоторые файлы будут отклонены</p>)}
              {!isDragActive && (<p className="text-body-secondary my-auto">Перетащите сюда файл или <u>нажмите</u>, чтобы выбрать</p>)} */}
              </Box>
            </Box>
      </Box>
      {uploadFile.length > 0 &&
          <>
            <Typography mt={2}>
              Загруженные файлы
            </Typography>
            <List sx={{width: '100%'}}>
              {uploadFile.map((file, key) => (
                  <FileDescription file={file} index={key} />
              ))}
            </List>
          </>
      }
    </Box>
  )
}

export default React.memo(Dropzone)
