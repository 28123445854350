import {useCallback, useState} from 'react';
import { Handle, Position } from 'reactflow';
import {TextField, Typography} from "@mui/material";



function StylistNode({ data, isConnectable }) {

    const [text, setText] = useState(data.text ?? "");

    const onChange = useCallback((evt) => {
        data.text = evt.target.value
    }, []);

    return (
        <div className="stylist-node">
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Typography sx={{fontSize: '8pt'}}>
                    Стилист
                </Typography>
                <textarea
                    id="text"
                    name="text"
                    onChange={onChange}
                    className="nodrag"
                    rows="1"
                    defaultValue={text.toString()}
                />
            </div>
            <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
        </div>
    );
}

export default StylistNode;
