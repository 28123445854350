import {IconButton, Tooltip, Typography} from '@mui/material';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneIcon from '@mui/icons-material/Done';
import Stack from '@mui/material/Stack';

const CustomViewField = ({
                           label,
                           text,
                           sx = {},
                           type = 'text',
                           path = '',
                           copyButton = false,
                         }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = renderText();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const renderText = () => {
    if (text && (typeof text === 'string' || typeof text === 'number')) {
      return text;
    }
    // TODO: Добавить перевод
    return 'Не найдено';
  };

  if (type === 'link') {
    return (
      <>
        <Typography variant='caption'>{label}</Typography>
        <Link to={path} target='_blank'>
          <Typography
            sx={{
              mb: 2,
              ...sx,
            }}
          >
            {renderText()}
          </Typography>
        </Link>
      </>
    );
  }

  if (type === 'text') {
    return (
      <>
        <Typography variant='caption'>{label}</Typography>
        <Stack
          direction='row'
          alignItems='center'
          sx={{
            pb: 2,
          }}
        >
          <Typography
            sx={{
              pr: 1,
              ...sx,
            }}
          >
            {renderText()}
          </Typography>
          {copyButton && !copied && (
            // TODO: Добавить перевод
            <Tooltip title='Копировать'>
              <IconButton
                size='small'
                color='inherit'
                onClick={copyToClipboard}
              >
                <FileCopyIcon fontSize='small'/>
              </IconButton>
            </Tooltip>
          )}
          {copyButton && copied && (
            <IconButton
              size='small'
              color='inherit'
            >
              <DoneIcon fontSize='small'/>
            </IconButton>
          )}
        </Stack>
      </>
    );
  }

  return <></>;
};

export default CustomViewField;
