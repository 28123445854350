import React from 'react';
import LoaderTraining from "./loaderTraining";
import {Button, Stack, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";
import {useSelector} from "react-redux";
import {$authHost} from "../http";
import {useTranslation} from "react-i18next";

const TrainingComponent = ({...props}) => {

    return (
        <>
            {(props.asist && props.asist.hasOwnProperty('status') === true)
            && (props.asist.status === 'running' || props.asist.status === 'queued' || props.asist.status === 'validating_files') ?
                <LoaderTraining asist={props.asist}/>
                :
                <>
                    {props.buttondiv}
                </>

            }

        </>
    );
};

export default TrainingComponent