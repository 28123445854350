import CustomTextField from '../../Fields/CustomTextField'
import {useTranslation} from 'react-i18next'
import {Divider} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {Controller, useFormContext} from "react-hook-form";

const AccessSsoSettingsFields = () => {
  const {t} = useTranslation();

  const {
    control,
    setValue,
      getValues
  } = useFormContext()


  return (
    <>
      {t('src.pages.AccessSsoPage.accessSSO')}
      <Controller
        name="is_on"
        control={control}
        render={({ field }) => {
          return (
            <ToggleButtonGroup
              {...field}
                disabled={true}
              onChange={(
                event,
                value
              ) => {
                  if (value !== null) {
                      setValue(field.name, value);
                  }
              }}
              exclusive
              color="primary"
              size="small"
              sx={{pt: 1, mb: 3, ml: 2}}
            >
              <ToggleButton
                value={true}
                sx={{px: 3, py: 1}}
              >
                вкл
              </ToggleButton>
              <ToggleButton
                value={false}
                sx={{px: 3, py: 1}}
              >
                выкл
              </ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />

      <Divider sx={{mb: 3}}/>

      <CustomTextField
        name="client_id"
        label={t('src.pages.AccessSsoPage.clientID')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        sx={{mb: 3}}
        size="small"
        disabled={getValues("is_on") !== true}
        fullWidth
      />
      <CustomTextField
        name="adfs_urls"
        label={t('src.pages.AccessSsoPage.providerUrl')}
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        sx={{mb: 3}}
        size="small"
        disabled={getValues("is_on") !== true}
        fullWidth
      />
      <CustomTextField
        name="signout_url"
        label='SSO Sign Out URL'
        registerOptions={{
          required: t('main.another.requiredField'),
        }}
        sx={{mb: 3}}
        size="small"
        disabled={getValues("is_on") !== true}
        fullWidth
      />
      <CustomTextField
        name="callback_url"
        label={t('src.pages.AccessSsoPage.CallbackUrl')}
        sx={{mb: 3}}
        size="small"
        fullWidth
        disabled
      />
    </>
  )
}

export default AccessSsoSettingsFields;