import {dialoguesActions} from './slice/dialogues.slice'
import {authActions} from './slice/auth.slice'
import {fileActions} from './slice/file.slice'
import {channelsActions} from './slice/channels.slice'
import {intelligensActions} from './slice/intelligens.slice'
import {usersActions} from './slice/users.slice'
import {sandboxDataActions} from './slice/sandboxData.slice'
import {sandboxActions} from './slice/sandbox.slice'
import {serviceActions} from './slice/service.slice'
import {knowledgesActions} from "./slice/knowledges.slice";
import {docsActions} from "./slice/dosc.slice";
import {diagramActions, diagrammSlice} from "./slice/diaframm.slice";
import {tableStatesActions} from "./slice/tableStates.slice";
import {statisticsActions} from "./slice/statistics.slice";

export const rootActions = {
  ...dialoguesActions,
  ...intelligensActions,
  ...channelsActions,
  ...authActions,
  ...fileActions,
  ...sandboxDataActions,
  ...usersActions,
  ...knowledgesActions,
  ...sandboxActions,
  ...serviceActions,
  ...docsActions,
  ...diagramActions,
  ...tableStatesActions,
  ...statisticsActions,
  logOut: () => (dispatch) => {
    dispatch(authActions.logOut())
    dispatch(dialoguesActions.logOut())
    dispatch(intelligensActions.logOut())
    dispatch(channelsActions.logOut())
    dispatch(usersActions.logOut())
    dispatch(docsActions.logOut())
    dispatch(sandboxDataActions.logOut())
    dispatch(sandboxActions.logOut())
    dispatch(knowledgesActions.logOut())
    dispatch(fileActions.delAllFile())
    dispatch(diagramActions.delAllFile())
    dispatch(serviceActions.resetAllAlertMessage())
    dispatch(tableStatesActions.logOut())
    dispatch(statisticsActions.logOut());
  },
}