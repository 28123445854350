import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {Box, Grid, IconButton, Link, ListItemText} from '@mui/material';
import useResponsive from "../hook/useResponsive";
import MenuIcon from "@mui/icons-material/Menu";
import {useSelector} from "react-redux";
import {darkLogo, lightLogo, NAV_WIDTH, NAV_WIDTH_CLOSE} from "../constants";
import {useActions} from "../hook/useActions";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

    const { status } = useSelector(state => state.authReducer)
    const isDesktop = useResponsive('up', 'lg')
    const { isDarkTheme, sseManagerStatus } = useSelector(state => state.serviceReducer)
    const srcLogo = isDarkTheme ? lightLogo : darkLogo
    const { openNav } = useSelector(state => state.serviceReducer)
    const {
        toggleOpenNav,
    } = useActions()

    const isMobile = useResponsive('down', 'sm')

    return (
        <>
            {
                status === 'Active'?
                <Box
                    sx={{
                        p: 0,
                        width: NAV_WIDTH
                    }}
                >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                p: 1,
                                width: isMobile ? NAV_WIDTH_CLOSE : NAV_WIDTH
                            }}
                        >
                            <IconButton
                                onClick={() => toggleOpenNav()}
                                m={0}
                            >
                                <MenuIcon
                                    size='small'
                                    sx={{
                                        color:'layout.element'
                                    }}
                                />
                            </IconButton>
                            {
                                !isMobile &&
                                <Link
                                    href="/"
                                >
                                    <Box
                                        component="img"
                                        src={srcLogo}
                                        sx={{
                                            height: 20,
                                            ml: 1,
                                            cursor: 'pointer',
                                            ...sx,
                                        }}
                                    />
                                </Link>
                            }
                        </Grid>
                </Box>
                :
                <Box
                    sx={{
                        p: 0,
                        width: NAV_WIDTH
                    }}
                >
                    <Box
                        component="img"
                        src={srcLogo}
                        sx={{
                            height: 20,
                            ml: 1,
                            cursor: 'pointer',
                            ...sx,
                        }}
                    />
                </Box>
            }
        </>
    );
});


export default Logo;
