import {
  Box,
  Button, ButtonGroup,
  Card,
  Grid, Popover,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import {useTranslation} from 'react-i18next';
import {TitleStack} from "../theme/standarts_styles";
import React, {useEffect, useState} from "react";
import {$authHost} from "../http";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {MonthCalendar, YearCalendar} from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingIndicator from "../components/LoadingIndicator";
import {useSelector} from "react-redux";
import {useActions} from "../hook/useActions";
import MaterialTable from "../components/MaterialTable";
import {TableCellsWrap} from "../components/TableCellsWrap";

const MainPage = () => {
  const {t, i18n} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);
  const [metriks, setMetriks] = useState({});

  const {details} = useSelector((state) => state.statisticsReducer);
  const {setDetails} = useActions();

  const loadData = async () => {
    setLoading(true);
    try {
      const responseDetails = await $authHost.get('/billing/subscription/details')
      setDetails(responseDetails.data)
      const subscriptionId = responseDetails.data?.SubscriptionID;

      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const responseMetriks = await $authHost.get(`/billing/grouped_by_date?subscription_id=${subscriptionId}&month=${selectedMonth}&year=${selectedYear}`)
      setMetriks(responseMetriks.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [month, year])

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    // plotOptions: {
    //   bar: {
    //     horizontal: false,
    //     dataLabels: {
    //       total: {
    //         enabled: true,
    //         style: {
    //           fontSize: '13px',
    //           fontWeight: 900
    //         }
    //       }
    //     }
    //   },
    // },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'd MMM',
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format('D MMM')
        },
      },
    },
    yaxis: {
      title: {
        text: 'Кол-во токенов'
      },
      decimalsInFloat: 0,
    },
    legend: {
      position: 'right',
      offsetY: 0
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'Нет данных для отображения',
      style: {
        color: '#000000',
        fontSize: '16px',
      }
    },
    responsive: [{
      breakpoint: 900,
      options: {
        legend: {
          position: 'bottom',
        },
      },
    }]
  }

  const renderTokens = (input, output) => {
    if ((input || input === 0) || (output || output === 0)) {
      return `${input} / ${output}`
    }
    return 'Не найдено'
  }

  const tableColumns = [
    {
      accessorKey: 'date',
      header: 'Дата',
      accessorFn: (row) => dayjs(row.date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence',
      header: 'Интеллект',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt35-standard-input-tokens/gpt35-standard-output-tokens',
      header: 'GPT3.5 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt35-standard-input-tokens'], row['gpt35-standard-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt4-standard-input-tokens/gpt4-standard-output-tokens',
      header: 'GPT4 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt4-standard-input-tokens'], row['gpt4-standard-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence',
      header: 'GPT4o (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'gpt35-fine-tuning-input-tokens/gpt35-fine-tuning-output-tokens',
      header: 'ft-GPT3.5 (in/out)',
      accessorFn: (row) => renderTokens(row['gpt35-fine-tuning-input-tokens'], row['gpt35-fine-tuning-output-tokens']),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence',
      header: 'ft-GPT4 (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'intelligence',
      header: 'ft-GPT4o (in/out)',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ]

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, 'month');

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, 'month');

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t('src.pages.MainPage.head')}
        </Typography>
      </TitleStack>
      <Stack spacing={2} pb={2}>
        <Card>
          <Grid container spacing={2} p={2} direction='column'>
            <Grid item>
              <Typography variant="h5">
                {t('src.pages.MainPage.abonentTitle')}
              </Typography>
              <Grid container spacing={2} pt={2} pl={1}>
                <Grid item xs={12} md={6}>
                  <Box pl={2}>
                    <ul style={{listStyle: 'disc'}}>
                      <li>
                        <Typography>ООО ASPEX</Typography>
                      </li>
                      <li>
                        <Typography>Интегратор IT-решений</Typography>
                      </li>
                      <li>
                        <Typography>Вадим Лотков</Typography>
                        <Typography>Генеральный директор</Typography>
                        <Typography>+7 (987) 654-32-10</Typography>
                        <Typography>vadim.lotkov@example.com</Typography>
                      </li>
                    </ul>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    <PhoneInTalkIcon
                      fontSize="small" sx={{mr: 1}}
                    />
                    {t('src.pages.MainPage.support')}
                  </Button>
                  <Typography pt={2}>Режим работы:</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h5">
                {t('src.pages.MainPage.statusTitle')}
              </Typography>
              <Grid container spacing={2} pt={2} pl={1}>
                <Grid item xs={12} md={6}>
                  <Typography>Каналы:</Typography>
                  <Typography>Наименование канала (тип):</Typography>
                  <Typography>Состояние канала:</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Интеллекты:</Typography>
                  <Typography>Наименование интеллекта:</Typography>
                  <Typography>Состояние интеллекта:</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h5">
                {t('src.pages.MainPage.tariffTitle')}
              </Typography>
              <Grid container spacing={2} pt={2} pl={1}>
                <Grid item xs={12} md={6}>
                  <Typography pb={1}>Текущий баланс:</Typography>
                  <Typography>Тарифный план: <b>{details?.Tariffs?.TariffName}</b></Typography>
                  <Typography>Тарификация: <b>{details?.Tariffs?.TariffPaymentType}</b></Typography>
                  <Typography>Стоимость тарифа: <b>{details?.Tariffs?.TariffPrice}</b></Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography pb={1}>Стоимость в текущем календарном месяце:</Typography>
                  <Typography>Количество каналов:</Typography>
                  <Typography>Количество интеллектов:</Typography>
                  <Typography>Объем данных:</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Card>
          <Stack spacing={2} p={2} sx={{position: 'relative'}}>
            {loading && <LoadingIndicator/>}
            <Stack direction='row' alignItems='center'>
              <IconButton onClick={handlePrev}>
                <ArrowCircleLeftIcon fontSize='large'/>
              </IconButton>
              <ButtonGroup variant='outlined'>
                <Button
                  onClick={handleClickMonth}
                  sx={{minWidth: '110px !important'}}
                >
                  {dayjs(month).format('MMMM')}
                </Button>
                <Button
                  onClick={handleClickYear}
                  sx={{minWidth: '70px !important'}}
                >
                  {dayjs(year).format('YYYY')}
                </Button>
              </ButtonGroup>
              <IconButton onClick={handleNext}>
                <ArrowCircleRightIcon fontSize='large'/>
              </IconButton>
              <Popover
                open={!!anchorMonth}
                anchorEl={anchorMonth}
                onClose={handleCloseMonth}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MonthCalendar onChange={(v) => setMonth(v)} value={month}/>
                </LocalizationProvider>
              </Popover>
              <Popover
                open={!!anchorYear}
                anchorEl={anchorYear}
                onClose={handleCloseYear}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <YearCalendar onChange={(v) => setYear(v)} value={year}/>
                </LocalizationProvider>
              </Popover>
            </Stack>

            <ReactApexChart options={options}
                            series={Array.isArray(metriks.resource_metriks) ? metriks.resource_metriks : []}
                            type="bar" height={350}/>


            <MaterialTable data={Array.isArray(metriks.day_metriks) ? metriks.day_metriks : []} columns={tableColumns}
                           enableTopToolbar={false} loading={loading}/>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
};

export default MainPage;
