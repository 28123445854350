import { NavLink as RouterLink } from 'react-router-dom';
import SvgColor from '../svg-color/SvgColor';
import {Box, Grid, List, ListItemButton, ListItemText, Typography} from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {StyledChip, StyledNavItem} from "../../theme/standarts_styles";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
export default function NavSection ({ ...other }) {

  const { t } = useTranslation()
  const { roles } = useSelector(state => state.authReducer)

  const navConfig = [
    {
      title: t('src.components.nav-section.NavSection.home'),
      path: '/',
      roles: [{ name: 'base' }],
      icon: <HomeOutlinedIcon size='small' />,
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.dialogues'),
      path: '/dialogs',
      roles: [{ name: 'BotManager' }],
      icon: <QuestionAnswerOutlinedIcon size='small'/>,
      counterChip: true,
    },
    {
      title: t('src.components.nav-section.NavSection.channel'),
      path: '/channels',
      roles: [{ name: 'BotManager' }],
      icon: <CampaignOutlinedIcon size='small'/>,
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.knowledgeManagement'),
      path: '/knowledgeManagement',
      roles: [{ name: 'BotManager' }],
      icon: <RecentActorsOutlinedIcon size='small'/>,
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.intelligence'),
      path: '/intelligence',
      roles: [{ name: 'BotManager' }],
      icon: <PsychologyOutlinedIcon size='small'/>,
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.sandbox'),
      path: '/sandbox',
      roles: [{ name: 'BotManager' }],
      icon: <BugReportOutlinedIcon size='small'/>,
      counterChip: false,
    },
    {
      title: t('src.components.nav-section.NavSection.accessControl'),
      path: '/access-control',
      roles: [{ name: 'AccessManager' }],
      icon: <GroupOutlinedIcon size='small'/>,
      counterChip: false,
    }
    // {
    //   title: t('src.components.nav-section.NavSection.diagram'),
    //   path: '/diagram',
    //   roles: [{ name: 'base' }],
    //   icon: <InsightsIcon size='small'/>,
    //   counterChip: false,
    // },
    // {
    //   title: t('src.components.nav-section.NavSection.diagram'),
    //   path: '/prompt-constructor',
    //   roles: [{ name: 'base' }],
    //   icon: <InsightsIcon size='small'/>,
    //   counterChip: false,
    // }

  ];
  const chats = useSelector(state => state.dialoguesReducer.chats)
  const unreadChats = useMemo(() => (
    chats.filter(chat => chat.msg_count > 0).length  // TODO Механика новых сообщений
  ), [chats])

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
            roles.some(role => item.roles?.some(r => r.name === role)) && (  // выводим только те элементы у которых есть совпадение между названиями roles в navConfig и authReducer
                <NavItem
                    key={item.title}
                    item={item}
                    unreadChats={unreadChats}
                />
            )
        ))}
      </List>
    </Box>
  )
}

function NavItem({ item, unreadChats }) {

  const { title, path, icon, info, counterChip } = item;
  const { openNav } = useSelector(state => state.serviceReducer)

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        p: 1,
        color: 'layout.element',
        '&.active': {
          bgcolor: 'layout.main',
          fontWeight: 'fontWeightBold',
          borderRadius: '8px'
        },
      }}
    >
      { openNav ?
          <>
            {icon && icon}
            <ListItemText
                disableTypography
                primary={title}
                sx={{
                  ml: 2
                }}
            />
            {counterChip && unreadChats > 0 &&
                <StyledChip label={unreadChats} size="small" />
            }
          </>
          :
          <>
            {/*<Grid*/}
            {/*    container*/}
            {/*    direction="column"*/}
            {/*    justifyContent="center"*/}
            {/*    alignItems="center"*/}
            {/*    sx={{*/}
            {/*      width: '100%'*/}
            {/*    }}*/}
            {/*>*/}
              {icon && icon}
            {/*</Grid>*/}
          </>

      }


    </StyledNavItem>
  )
}
