import React, { useState } from 'react'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AlertMessage from '../../components/Alert/AlertMessage'
import CustomTextField from '../../components/Fields/CustomTextField'
import { useActions } from '../../hook/useActions'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { $authHost } from '../../http'

const ChangePasswordForm = () => {
  const { t } = useTranslation()

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword(prevState => !prevState)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(prevState => !prevState)

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    control,
    handleSubmit,
  } = methods

  const newPassword = useWatch({ control, name: 'newPassword', defaultValue: '' })

  const {
    setErrorAlertMessage,
    updateUser,
  } = useActions()

  const onSubmit = async (data) => {  //TODO доделать после получениея рута
    const { newPassword, confirmPassword, ...restData } = data
    const processedData = {
      ...restData,
      password: newPassword,
    }
    try {
      const response = await $authHost.patch(`/user/change_password`, processedData)
      if (response.status === 201) {
        const { id, last_name, email, avatar, name, roles, status, locked_flags, token } = response.data
        updateUser({ userId: id, last_name, email, avatar, name, roles, status, locked_flags, token })
      }
    } catch (e) {
      // if (e.response.status === 401 && e.response.data.detail === 'This is user already exist') {
      //   setError('newPassword', {
      //     type: 'manual',
      //     message: t('src.pages.CompletionRegistration.differentPassword',
      //       'Новый пароль должен отличаться от временного!'),
      //   })
      // }
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            {t('src.pages.CompletionRegistration.changePassword', 'Необходимо изменить пароль:')}
          </Typography>
          <CustomTextField
            name="newPassword"
            label={t('src.pages.AccountPage.ChangePassword.newPassword')}
            secondErrorText={t('src.pages.AccountPage.ChangePassword.uncorrectedError')}
            registerOptions={{
              pattern: {
                value: /^[!-~]{1,32}$/i,
                message: t('src.pages.AccountPage.ChangePassword.uncorrectedError'),
              },
              required: t('main.another.requiredField'),
            }}
            // required
            sx={{ width: '100%', mb: 3 }}
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <CustomTextField
            name="confirmPassword"
            label={t('src.pages.AccountPage.ChangePassword.confirmPassword')}
            registerOptions={{
              validate: (value) => {
                return value === newPassword || t('src.pages.AccountPage.ChangePassword.validPassword')
              },
              required: t('main.another.requiredField'),
            }}
            // required
            sx={{ width: '100%', mb: 3 }}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('main.another.save')}
          </Button>
          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}

export default ChangePasswordForm