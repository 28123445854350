import React from 'react'
import TextField from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'

const CustomTextField = ({ label, name, required, registerOptions, secondErrorText, ...rest }) => {

  const {
    register,
    formState: {
      errors,
    },
  } = useFormContext()

  const error = errors[name]
  const req = !!registerOptions?.required  // || required

  return (
    <div style={{
        position: 'relative',
        marginBottom: '12px',
        width: '100%'
    }}>
      <TextField
        {...register(name, registerOptions)}
        {...rest}
        label={`${label}${req ? ' *' : ''}`}
        variant="outlined"
        error={!!error} // Установите error на основе наличия ошибки из react-hook-form
        // helperText={errorMessage} // Передайте сообщение об ошибке через helperText
        InputLabelProps={{
          shrink: true,
          //   ...rest, // Передайте остальные пропсы в inputProps
        }}
      />
      <span
        style={{
          position: 'absolute',
          bottom: '4px',
          fontSize: '12px',
          color: 'red',
          left: 14, // Выравнивание по левому краю
        }}
      >
        {error && (error?.message || 'Unknown error!')}
      </span>
    </div>
  )
}

export default CustomTextField
