import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import DynamicTabs from '../../components/Tabs/DynamicTabs'
import AccessUserPage from './AccessUserPage'
import AccessGroupsPage from './AccessGroupsPage'
import AccessSsoSettingsPage from './AccessSsoSettingsPage'
import {TitleStack} from "../../theme/standarts_styles";

const AccessControl = () => {

  const { t } = useTranslation()

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t('src.pages.AccessControl.head')}
        </Typography>
      </TitleStack>
      <DynamicTabs
        components={[
          { label: t('src.pages.AccessControl.tab1'), component: <AccessUserPage/> },
          { label: t('src.pages.AccessControl.tab2'), component: <AccessGroupsPage/> },
          { label: t('src.pages.AccessControl.tab3'), component: <AccessSsoSettingsPage/> },
        ]}
      />
    </Container>
  )
}

export default AccessControl