export const widgetBackFn = () => {
  const settings = {
    title: 'Онлайн чат',
    startTime: '8:00',
    endTime: '20:00',
    logo: 'https://i.ibb.co/FWKNDwm/aspex-logo.jpg',
    colors: {
      button_toggler: '#0AA89A',
      button_send: '#0AA89A',
      message: '#0AA89A',
      main: '#0AA89A',
      background: '#FFFFFF'
    },
  }

// Установка цветов в переменные CSS
  settings?.colors?.main && document.documentElement.style.setProperty('--main-color', settings.colors.main);
  settings?.colors?.message && document.documentElement.style.setProperty('--message-color', settings.colors.message);
  settings?.colors?.button_toggler && document.documentElement.style.setProperty('--button_toggler-color', settings.colors.button_toggler);
  settings?.colors?.button_send && document.documentElement.style.setProperty('--button_send-color', settings.colors.button_send);
  settings?.colors?.background && document.documentElement.style.setProperty('--background-color', settings.colors.background);

// Добавление текста в title
  const title = document.getElementById('title');
  if (title && settings?.title) {
    title.innerText = settings.title;
  }

// Добавление текста в worktime
  const worktime = document.getElementById('worktime');
  if (worktime && settings?.startTime && settings?.endTime) {
    worktime.innerText = `Время работы c ${settings.startTime} до ${settings.endTime}`;
  }

// Добавление текста в worktime
  const logo = document.getElementById('logo');
  if (logo && settings?.logo) {
    logo.src = settings.logo;
  }

// Отображение виджета после применения стилей с бэка
  const widgetBody = document.getElementById('widget-body');
  if (widgetBody) {
    widgetBody.style.display = 'block';
  }
}
