import { actionChannel, call, fork, put, race, select, take } from 'redux-saga/effects'
import { buffers, END, eventChannel } from 'redux-saga'
import { dialoguesActions } from '../slice/dialogues.slice'
import { baseUrl } from '../../http/baseRoute'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { store } from '../store'
import { sandboxActions } from '../slice/sandbox.slice'
import { sandboxDataActions } from '../slice/sandboxData.slice'
import { serviceActions } from '../slice/service.slice'
import {intelligensActions} from "../slice/intelligens.slice";

const { updateManagerInfo } = dialoguesActions
// const { addSandboxMessage } = sandboxActions
// const { addSandboxDataMessage } = sandboxDataActions
const { changeSseManagerStatus, changeSseManagerError } = serviceActions
// const { updateFineTuning } = intelligensActions

// создаем переменную для хранения запущенного SSE
let manager

// функция создания eventChannel
function createSSEManager () {
  return eventChannel((emitter) => {
    var data = store.getState().authReducer.token
    var eventSource = new EventSourcePolyfill(`${baseUrl}/manager_stream`, {
      headers: {
        'authorization': `Bearer ${data}`,
      },
    })

    // eventSource.addEventListener('updateChat', (event) => {
    //   const data = JSON.parse(event.data)
    //   console.log('UPDATE_CHAT ', data)
    //   emitter({ type: 'UPDATE_CHAT', data })
    // })


    eventSource.addEventListener('updateDialogueBusy', (event) => {
      // console.log("xnj nj")
      const data = JSON.parse(event.data)
      // console.log('UPDATE_MANAGER ', data)
      emitter({ type: 'UPDATE_MANAGER', data })
    })
    // eventSource.addEventListener('updatePromptPlayground', (event) => {
    //   const data = JSON.parse(event.data)
    //   console.log('UPDATE_SANDBOX ', data)
    //   emitter({ type: 'UPDATE_SANDBOX', data })
    // })
    // eventSource.addEventListener('updateDataPlayground', (event) => {
    //   const data = JSON.parse(event.data)
    //   console.log('UPDATE_DATA_SANDBOX ', data)
    //   emitter({ type: 'UPDATE_DATA_SANDBOX', data })
    // })
    // eventSource.addEventListener('updateFineTuning', (event) => {
    //   const data = JSON.parse(event.data)
    //   console.log('UPDATE_FINE_TUNING ', data)
    //   emitter({ type: 'UPDATE_FINE_TUNING', data })
    // })

    const closeHandler = () => {
      if (eventSource.readyState === EventSource.CLOSED) {
        emitter(END)
      }
      store.dispatch(changeSseManagerError(false))
    }

    const errorHandler = () => {
      store.dispatch(changeSseManagerError(true))
    }

    const openHandler = () => {
      store.dispatch(changeSseManagerError(false))
    }

    eventSource.addEventListener('error', errorHandler)
    eventSource.addEventListener('close', closeHandler)
    eventSource.addEventListener('open', openHandler)

    return () => {
      eventSource.removeEventListener('error', closeHandler)
      eventSource.removeEventListener('close', closeHandler)
      eventSource.close()
    }
  })
}

// функция для отмены запущенного eventChannel
function * stopSSEManagerWorker () {
  manager.close()
}
//
// function * handleUpdateChat (data) {
//   yield put(addNewMessage(data))
// }
//
// function * handleUpdateSandbox (data) {
//   yield put(addSandboxMessage(data))
// }
//
// function * handleUpdateSandboxData (data) {
//   yield put(addSandboxDataMessage(data))
// }
//
// function * handleUpdateFineTuning (data) {
//   yield put(updateFineTuning(data))
// }

function * handleUpdateDialogues (data) {
  // const chatExists = yield select(state =>
  //   state.chats.some(chat => chat.telegram_id === data.telegram_id),
  // )
  // if (!chatExists) {
  //   yield put(addChat(data))
  // }
  yield put(updateManagerInfo(data))
}

function * watchUpdates (manager, handler) {
  const bufferedManager = yield actionChannel(manager, buffers.expanding())
  while (true) {
    const action = yield take(bufferedManager)
    yield call(handler, action.data)
  }
}

export function * SSEManagerWorker () {
  // yield put(changeSseChannelStatus(createSSEChannel));
  // const sseChannelStatus = yield select((state) => state.dialogues.sseChannelStatus)
  manager = yield call(createSSEManager)
  yield put(changeSseManagerStatus(true))
  // yield fork(watchUpdates, 'UPDATE_CHAT', handleUpdateChat)
  yield fork(watchUpdates, 'UPDATE_MANAGER', handleUpdateDialogues)
  // yield fork(watchUpdates, 'UPDATE_SANDBOX', handleUpdateSandbox)
  // yield fork(watchUpdates, 'UPDATE_DATA_SANDBOX', handleUpdateSandboxData)
  // yield fork(watchUpdates, 'UPDATE_FINE_TUNING', handleUpdateFineTuning)
  try {
    while (true) {
      const action = yield take(manager)
      yield put({ type: action.type, data: action.data })
    }
  } catch (error) {
  } finally {
    manager.close()
    yield put(changeSseManagerStatus(false))
  }
}

// корневая сага, которая слушает экшены для запуска и остановки SSE
export function * SSEManagerWatcher () {
  while (true) {
    const action = yield take('START_SSE_WORKER')
    // console.log("START_SSE_WORKER 2");
    const { stop } = yield race({  //закрытие всех саг, если одна из саг выполнена
      start: SSEManagerWorker(action),
      stop: take('STOP_SSE_WORKER'),
    })
    if (stop) {
      // console.log("STOP_SSE_WORKER 2")
      yield stopSSEManagerWorker()
    }
  }
}
