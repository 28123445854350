import React from 'react';
import {Avatar, Divider, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import {generateColor, generateManagerColor} from "../utils/ColoredChips";
import {StyledChipForChats} from "../theme/standarts_styles";
import StarIcon from "@mui/icons-material/Star";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import BackHandIcon from "@mui/icons-material/BackHand";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {$authHost} from "../http";
import {useActions} from "../hook/useActions";
import AvatarUser from "./AvatarUser";

const ChatListElement = (props) => {
    const {chat, index, listRef, searchMess} = props;

    const { t } = useTranslation()

    const {
        setSelectedCustomer,
        setDialog,
        setChats,
        changeFavoriteChat,
        changeDraftMessage,
        setShowScrollButton,
        setFoundChats,
        setFoundMessages,
        setSearchValue,
        setSearchInsideValue,
        setOpenSearchInsideChat,
        setSearchMessage,
        setFlagFilter,
        setFavoriteFilter,
        setWaitFilter,
        setHandFilter,
        setArchFilter,
        setUnreadFilter,
        setUnreadMessageIndex,
        setChannels,
        setSelectedChannels,
        seIsViewChat,
    } = useActions()

    const {
        dialog,
        chats,
        searchValue,
        searchInsideValue,
        selectedCustomer,
        OpenSearchInsideChat,
        searchMessage,
        flagFilter,
        favoriteFilter,
        waitFilter,
        handFilter,
        unreadFilter,
        archFilter,
        selectedChannels,
        isViewChat
    } = useSelector(state => state.dialoguesReducer)

    const handlerChat = (SelectCustomer, selectMessage = null) => {
        try {
            const newChatList = async () => {
                const response = await $authHost.get(`/dialogues/by_id`, { params: { customer_id: SelectCustomer.id } })
                return response.data
            }
            newChatList().then((data) => {
                setSelectedCustomer(SelectCustomer)
                setDialog(data)
                if (window.innerWidth <= 900) {
                    if(null === selectMessage){
                        handleOpenDialogsList('none', 'block');
                        listRef.current.scrollTop = listRef.current.scrollHeight
                        setShowScrollButton(false)
                    }else{
                        handleOpenDialogsList('none', 'block', selectMessage);
                    }


                    // listRef.current.scrollTop = listRef.current.scrollHeight
                    // if (null === selectMessage) {
                    //     setShowScrollButton(false)
                    // } else {
                    //     setSearchMessage(selectMessage)
                    // }

                }else{
                    listRef.current.scrollTop = listRef.current.scrollHeight
                    if (null === selectMessage) {
                        setShowScrollButton(false)
                    } else {
                        setSearchMessage(selectMessage)
                    }
                }


                // listRef.current.scrollTop = listRef.current.scrollHeight

            })
        } catch (e) {
        }
    }

    function handleOpenDialogsList (dialogsList, dialog, search = null) {
        if (document.getElementById('dialogsListContainer') && document.getElementById('dialogContainer') &&
            window.innerWidth <= 900) {
            document.getElementById('dialogsListContainer').style.display = dialogsList
            document.getElementById('dialogContainer').style.display = dialog
            if(search !== null){
                setSearchMessage(search)
            }
        }
        // if (document.getElementById('channelFilter') && document.getElementById('dialogsListContainer') && document.getElementById('dialogContainer') &&
        //     window.innerWidth <= 900) {
        //     dialog === 'block' ? seIsViewChat(true) : seIsViewChat(false);
        //     document.getElementById('dialogsListContainer').style.display = dialogsList
        //     document.getElementById('dialogContainer').style.display = dialog
        //     document.getElementById('channelFilter').style.display = dialogsList
        //     if(search !== null){
        //         setSearchMessage(search)
        //     }
        // }
    }


    return (
        <React.Fragment key={index}>
            <ListItem
                selected={
                    searchMess === null  && selectedCustomer?.id === chat['id']
                }
                alignItems="center"
                onClick={() =>
                    handlerChat(
                        {
                            id: chat['id'],
                            telegram_id: chat['telegram_id'],
                            full_name: chat['full_name'],
                            avatar: chat['avatar'],
                            is_favorite: chat['is_favorite'],
                            firstRead: true,
                            unreadMessageIndex: null,
                            index,
                            is_handler_bot: chat['is_handler_bot'],
                            channel_id: chat['channel_id'],
                            channel_type: chat['channel_info'].channel_type,
                            channel_name: chat['channel_info'].name,
                        },
                        searchMess !== null ? searchMess['id'] : null
                    )
                }
            >
                <Grid
                    container
                    className="chats"
                    sx={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        maxHeight: 'calc(99vh - 230px)',
                        flexWrap: 'nowrap',
                    }}
                    alignItems="center"
                >
                    <ListItemAvatar>
                        <AvatarUser user={chat['full_name']} />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{overflowX:'hidden'}}
                        primary={
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    sx={{ display: 'inline', fontSize: '18px' }}
                                    component="span"
                                    color={chat['full_name'] === "" ? "text.secondary" : "text.primary"}
                                >
                                    {chat['full_name'] === "" ? t('main.another.unknown') : chat['full_name']}
                                </Typography>
                                {chat['channel_info'].channel_type !== "" &&
                                    <Chip
                                        color={generateColor(chat['channel_info'].channel_type )}
                                        variant="outlined"
                                        sx={{fontSize: '9pt', height: '19px'}}
                                        size="small"
                                        label={chat['channel_info'].channel_type}
                                    />
                                }
                            </Stack>
                        }
                        secondary={
                            <React.Fragment>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Typography
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        component="p"
                                        variant="body2"
                                    >
                                        {  searchMess !== null ? searchMess['text'] : chat['last_message']}
                                    </Typography>
                                    {chat['manager_id'] !== null &&
                                        <>
                                            <Divider flexItem />
                                            <Grid
                                            >
                                                <StyledChipForChats
                                                    colorA={generateManagerColor("dddddd")}
                                                    size="small"
                                                    variant="outlined"
                                                    avatar={
                                                        <Avatar src="/static/images/avatar/1.jpg" />
                                                    }
                                                    label="Alex1"
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Stack>
                            </React.Fragment>
                        }

                    />
                    <div
                        style={{
                            paddingBottom: '8px',
                            paddingTop: '8px',
                            paddingLeft: '5px',
                            display:'flex',
                            direction:"row",
                            justifyContent:"flex-end",
                            alignItems:"center",
                            height: '100%'
                        }}
                    >
                        {String(chat['is_favorite']) === 'true' && <StarIcon color="disabled"/>}
                        {chat['is_waiting_response'] && <HourglassFullIcon color="disabled"/>}
                        {!chat['is_handler_bot'] && <BackHandIcon color="disabled"/>}
                        {!!chat['msg_count'] &&
                            <Chip label={chat['msg_count']} color="primary" sx={{marginLeft:0.5}} size="small"/>
                        }
                    </div>
                </Grid>
            </ListItem>
            <Divider component="li"/>
        </React.Fragment>
    );
};

export default ChatListElement;