import React from 'react';
import {StyledAccount} from "../../theme/standarts_styles";
import {Avatar, Box, Grid, Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const AccountCompany = () => {
    const { t } = useTranslation()
    const { openNav } = useSelector(state => state.serviceReducer)

    return (
        <Link underline="none">
            <StyledAccount>
                {
                    openNav ?
                        <Box
                            sx={{
                                px: 2,
                                py: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar
                                src="/assets/images/covers/aspex_cover.png"
                                alt="photoURL"
                                // variant="square"
                            />
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle1" color="layout.main">
                                    {t('src.layouts.dashboard.nav.index.companyName')}
                                </Typography>
                                <Typography variant="body1" color="layout.main">
                                    {t('src.layouts.dashboard.nav.index.companyID')}
                                </Typography>
                            </Box>
                        </Box>
                        :
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Avatar
                                src="/assets/images/covers/aspex_cover.png"
                                alt="photoURL"
                                sx={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        </Grid>

                }

            </StyledAccount>
        </Link>
    );
};

export default AccountCompany;