import { alpha, styled } from '@mui/material/styles'
import { APP_BAR_DESKTOP, APP_BAR_MOBILE, HEADER_DESKTOP, HEADER_MOBILE } from '../constants'
import {AppBar, Autocomplete, Grid, hexToRgb, ListItem, ListItemButton, rgbToHex, Toolbar} from '@mui/material'
import SimpleBar from 'simplebar-react'
import { bgBlur } from '../utils/cssStyles'
import { Link } from 'react-router-dom'
import Chip from "@mui/material/Chip";
import {useSelector} from "react-redux";
import React from "react";
import Stack from '@mui/material/Stack';

export const StyledRoot = styled('div')({
  backgroundColor: 'layout.default',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

export const Main = styled('div')(({ theme }) => ({
  backgroundColor: 'layout.default',
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: 0,
  [theme.breakpoints.down('md')]: {
    paddingTop: APP_BAR_DESKTOP+5,
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
  },
  // [theme.breakpoints.down('md')]: {
  //   paddingTop: APP_BAR_DESKTOP + 10,
  // },
}))

export const StyledBox = styled('div')(({ theme }) => ({
  mx: 2.5,
  marginBottom: "15px",
  marginTop: APP_BAR_MOBILE + 20,
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    marginTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

export const TitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: 'flex',
  alignItems: "center",
  height: {xl:'60px', lg:'60px', md:'60px', xs: '50px'},
  justifyContent: "flex-start",
  mb: 0
}))
export const ButtonStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: 'flex',
  alignItems: "center",
  height: '70px',
  justifyContent: "flex-start",
  m: 0
}))

export const RowOptionsStack = styled(Stack)(({ Theme }) =>({
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'flex-end',
  alignItems: 'center'
}))

export const StyledContent = styled('div')(({ theme, width }) => ({
  maxWidth: width ? width : 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export const StyledRootAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.layout.main,
  zIndex: 2000,
  // boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}))

export const StyledChip = styled(Chip)(({theme}) => ({
  color: theme.palette.layout.main,
  backgroundColor: theme.palette.layout.element,
  // border: 'solid 1px theme.palette.primary',
}));


export const StyledChipForChats = styled(Chip)(({theme, colorA}) => ({
  color: `rgb(${colorA.R}, ${colorA.G}, ${colorA.B})`,
  // backgroundColor: `rgba(${hexToRgb(colorA)}, 0.5)`,
  backgroundColor: `rgba(${colorA.R}, ${colorA.G}, ${colorA.B}, 0.1)`,
  border: `1px solid rgb(${colorA.R}, ${colorA.G}, ${colorA.B})`,
}));

export const StyledListItemForChats = styled(ListItem)(({theme}) => ({
  backgroundColor: theme.palette.layout.main,
}));

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  // textTransform: 'capitalize',
  color: theme.palette.common.white,
  // borderRadius: theme.shape.borderRadius,
  paddingLeft: '15px'
}));

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      // backgroundColor: alpha(theme.palette.grey[600], 0.48),
      // backgroundColor: 'default.paper',
      backgroundColor: theme.palette.primary
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}))

export const StyledRootScrollbar = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}))

export const StyledRootUpMD = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

export const StyledAccount = styled('div')(({ theme }) => ({
  height: '60px',
  // display: 'flex',
  // alignItems: 'center',
  // padding: theme.spacing(1, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  border: `1px solid ${theme.palette.layout.accent_element}`,
  color: theme.palette.common.white,
  // backgroundColor: alpha(theme.palette.primary.light, 1),
  boxShadow: theme.customShadows.z4,
  backgroundColor: theme.palette.layout.accent_element,
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(0, 1),
  },
}))



export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 4, 0, 0),
  },
  [theme.breakpoints.down('lg')]: {
    minHeight: HEADER_MOBILE,
    padding: theme.spacing(0, 2, 0, 0),
  },
}))

// export const StyledSearchbar = styled('div')(({ theme }) => ({
//   ...bgBlur({ color: theme.palette.background.default }),
//   top: 0,
//   left: 0,
//   zIndex: 99,
//   width: '100%',
//   display: 'flex',
//   position: 'absolute',
//   alignItems: 'center',
//   height: HEADER_MOBILE,
//   padding: theme.spacing(0, 3),
//   // boxShadow: theme.customShadows.z8,
//   [theme.breakpoints.up('md')]: {
//     height: HEADER_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }))

export const SearchInput = styled(Autocomplete)(({theme}) => ({
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: theme.palette.common.black
  },
  "& .MuiAutocomplete-inputRoot": {
    color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.common.white
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.common.white
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.common.white
    }

}))

export const StyledLink = styled(Link)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.secondary,
  textDecoration: 'none',
}))

export const StyledMessage = styled('div')(({ theme, side }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: side === 'right' ? theme.palette.primary['main'] : theme.palette.grey[300],
  color: side === 'right' ? '#FFFFFF' : '#000000',
  borderRadius: theme.spacing(2.5),
  display: 'inline-block',
  wordBreak: 'break-word',
  textAlign: side,
  maxWidth: '100%',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontSize: '14px',
}))

export const BoxMessage = styled('div')(({ theme, side }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: side === 'right' ? 'flex-end' : 'flex-start',
  alignItems: 'center',
}))


export const CustomNodeDiv = styled('div')(({ theme, selected }) => ({
  alignItems: 'center',
  display: 'flex',
  minHeight: '50px',
  [theme.breakpoints.down('md')]: {
    minHeight: '30px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  paddingTop: '10px',
  paddingBottom: '10px',
  backgroundColor: selected ? theme.palette.action.selected : null,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '5px'
  },
}))

export const MultipleDragPreviewDiv = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.layout.accent_element,
  borderRadius: '4px',
  boxShadow: theme.customShadows.z4,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px'
}))

export const CustomNodeEditGrid = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.layout.accent_element}`,
  borderRadius: '10px'
}))


export const PaperDiv = styled('div')(({theme}) => ({
  width: '100%',
  height: '100%',
  // backgroundColor: #f4f9fd;
  backgroundColor: theme.palette.background.neutral,
  /*height: calc(100vh - 77px - 70px - 60px - 60px - 60px);*/
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  position: 'relative'
/*border-left: '1px solid #000';*/
}))


