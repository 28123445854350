import React, {useState} from 'react';
import {Autocomplete, Box, Button, Card, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {StyledBox} from "../../theme/standarts_styles";
import AccountCompany from "../../layouts/nav/AccountCompany";
import SearchFieldInAppBar from "../../layouts/header/SearchFieldInAppBar";
import NavSection from "../nav-section/NavSection";
import CustomTextField from "../Fields/CustomTextField";
import ContentParserComponent from "./forms/ContentParserComponent";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CustomTextFieldAutocomplete from "../Fields/CustomTextFieldAutocomplete";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import ContentGateComponent from "./forms/ContentGateComponent";
import useResponsive from "../../hook/useResponsive";
import ClearIcon from "@mui/icons-material/Clear";
const EditNodeForm = ({...props}) => {

    let selectedNode = props.nodes.find((el) => el.id === props.selectedNodes[props.selectedNodes.length-1])
    let selectedOutputs = props.edges.filter((el) => el.source === selectedNode?.id) || []
    const isMobile = useResponsive('down', 'md')

    const {
        setSelectedParserRule,
        deleteRule,
        setNodes,
        setEdgesState,
        setConnectionsForGates
    } = useActions()

    const {
        nodesState,
        edgesState,
        connectionsForGates
    } = useSelector(state => state.diagramReducer)

    const renderContentGPT = (
        <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Breaker (ChatGPT) - свойства
            </Typography>
            <Autocomplete
                options={[
                    { name:"model1", value: "model1" },
                    { name:"model2", value: "model2" },
                ]}
                getOptionLabel={(option) => option.name}
                size='small'
                sx={{
                    mb: 2
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Выбор модели'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            />
            <TextField
                label="Переменные на входе"
                variant="outlined"
                sx={{ mb: 2 }}
                size="small"
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                label="Системный промпт"
                variant="outlined"
                multiline
                rows={4}
                size="small"
                InputLabelProps={{
                    shrink: true
                }}
            />
        </>
    );

    const renderContentGate = (
        <ContentGateComponent
            selectedNodes={selectedNode}
            selectedOutputs={selectedOutputs}
            nodes={props.nodes}
            edges={props.edges}
        />
    );

    const renderContentParser = (
        <ContentParserComponent
            selectedNodes={selectedNode}
            selectedOutputs={selectedOutputs}
            nodes={props.nodes}
            edges={props.edges}
            OnClearSelection={props.OnClearSelection}
        />
    );

    const renderContentChannel = (
        <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
                Channel - свойства
            </Typography>
            <p>X: {selectedNode?.position.x}</p>
            <p>Y: {selectedNode?.position.y}</p>
        </>
    );

    const renderContentResponse = (
        <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
                Response - свойства
            </Typography>
            <p>X: {selectedNode?.position.x}</p>
            <p>Y: {selectedNode?.position.y}</p>
        </>
    );
    const renderContentInfo = (
        <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
                info - свойства
            </Typography>
            <p>X: {selectedNode?.position.x}</p>
            <p>Y: {selectedNode?.position.y}</p>
        </>
    );
    const renderContentAction = (
        <>
            <Typography variant="subtitle1" sx={{mb: 2}}>
                action - свойства
            </Typography>
            <p>X: {selectedNode?.position.x}</p>
            <p>Y: {selectedNode?.position.y}</p>
        </>
    );

    function renderContent() {
        // console.log("props", props.edges)
        switch (selectedNode?.type) {
            case "chatGpt" :
                return renderContentGPT;
            case "parser":
                return renderContentParser;
            case "gate":
                return renderContentGate;
            case "channel":
                return renderContentChannel;
            case "response":
                return renderContentResponse;
            case "info":
                return renderContentInfo;
            case "action":
                return renderContentAction;
        }
    }

    return (
        <>
            {selectedNode?.type && selectedNode.type === "parser" &&
                <Card
                    sx={{
                        pt: isMobile ? 3 : 2,
                        pb: isMobile ? 1 : 2,
                        px: isMobile ? 2 : 3,
                        whiteSpace: 'pre-line',
                        // backgroundColor: 'background.default'
                }}
                >

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                    >
                        {renderContent()}
                    </Grid>
                </Card>
            }
        </>
    )
};

export default EditNodeForm;