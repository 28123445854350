export const desiredLocations = [  // искомые локации
  '/primary-registration',
  '/new-eula',
  '/reset-password',
  '/change-password',
]
export const acceptFileType = [
  'image/jpeg',
  'image/png',
]
export const acceptFileTypeXL = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]
export const acceptFileTypeBot = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'text/plain',
  // 'text/csv'
]
export const darkLogo = '/assets/icons/navbar/main_logo.png';
export const lightLogo = '/assets/icons/navbar/logo_main_white.png';

export const APP_BAR_MOBILE = 54
export const HEADER_MOBILE = 54
export const APP_BAR_DESKTOP = 70
export const HEADER_DESKTOP = 70
export const NAV_WIDTH = 260

export const NAV_WIDTH_CLOSE = 60
