import { actionChannel, call, fork, put, race, select, take } from 'redux-saga/effects'
import { buffers, END, eventChannel } from 'redux-saga'
import { dialoguesActions } from '../slice/dialogues.slice'
import { baseUrl } from '../../http/baseRoute'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { store } from '../store'
import { sandboxActions } from '../slice/sandbox.slice'
import { sandboxDataActions } from '../slice/sandboxData.slice'
import { serviceActions } from '../slice/service.slice'
import {intelligensActions} from "../slice/intelligens.slice";

const { addChat, addNewMessage } = dialoguesActions
const { addSandboxMessage } = sandboxActions
const { addSandboxDataMessage } = sandboxDataActions
const { changeSseChannelError, changeSseChannelStatus } = serviceActions
const { updateFineTuning } = intelligensActions

// создаем переменную для хранения запущенного SSE
let channel

// функция создания eventChannel
function createSSEChannel () {
  return eventChannel((emitter) => {
    var data = store.getState().authReducer.token
    var eventSource = new EventSourcePolyfill(`${baseUrl}/message-stream`, {
      headers: {
        'authorization': `Bearer ${data}`,
      },
    })

    eventSource.addEventListener('updateChat', (event) => {
      const data = JSON.parse(event.data)
      console.log('UPDATE_CHAT ', data)
      emitter({ type: 'UPDATE_CHAT', data })
    })
    eventSource.addEventListener('updateDialogues', (event) => {
      const data = JSON.parse(event.data)
      console.log('UPDATE_DIALOGUES ', data)
      emitter({ type: 'UPDATE_DIALOGUES', data })
    })
    eventSource.addEventListener('updatePromptPlayground', (event) => {
      const data = JSON.parse(event.data)
      console.log('UPDATE_SANDBOX ', data)
      emitter({ type: 'UPDATE_SANDBOX', data })
    })
    eventSource.addEventListener('updateDataPlayground', (event) => {
      const data = JSON.parse(event.data)
      console.log('UPDATE_DATA_SANDBOX ', data)
      emitter({ type: 'UPDATE_DATA_SANDBOX', data })
    })
    eventSource.addEventListener('updateFineTuning', (event) => {
      const data = JSON.parse(event.data)
      // console.log('UPDATE_FINE_TUNING ', data)
      emitter({ type: 'UPDATE_FINE_TUNING', data })
    })

    const closeHandler = () => {
      if (eventSource.readyState === EventSource.CLOSED) {
        emitter(END)
      }
      store.dispatch(changeSseChannelError(false))
    }

    const errorHandler = () => {
      store.dispatch(changeSseChannelError(true))
    }

    const openHandler = () => {
      store.dispatch(changeSseChannelError(false))
    }

    eventSource.addEventListener('error', errorHandler)
    eventSource.addEventListener('close', closeHandler)
    eventSource.addEventListener('open', openHandler)

    return () => {
      eventSource.removeEventListener('error', closeHandler)
      eventSource.removeEventListener('close', closeHandler)
      eventSource.close()
    }
  })
}

// функция для отмены запущенного eventChannel
function * stopSSEWorker () {
  channel.close()
}

function * handleUpdateChat (data) {
  yield put(addNewMessage(data))
}

function * handleUpdateSandbox (data) {
  yield put(addSandboxMessage(data))
}

function * handleUpdateSandboxData (data) {
  yield put(addSandboxDataMessage(data))
}

function * handleUpdateFineTuning (data) {
  yield put(updateFineTuning(data))
}

function * handleUpdateDialogues (data) {
  const chatExists = yield select(state =>
    state.chats.some(chat => chat.telegram_id === data.telegram_id),
  )
  if (!chatExists) {
    yield put(addChat(data))
  }
}

function * watchUpdates (channel, handler) {
  const bufferedChannel = yield actionChannel(channel, buffers.expanding())
  while (true) {
    const action = yield take(bufferedChannel)
    yield call(handler, action.data)
  }
}

export function * SSEWorker () {
  // yield put(changeSseChannelStatus(createSSEChannel));
  // const sseChannelStatus = yield select((state) => state.dialogues.sseChannelStatus)
  channel = yield call(createSSEChannel)
  yield put(changeSseChannelStatus(true))
  yield fork(watchUpdates, 'UPDATE_CHAT', handleUpdateChat)
  yield fork(watchUpdates, 'UPDATE_DIALOGUES', handleUpdateDialogues)
  yield fork(watchUpdates, 'UPDATE_SANDBOX', handleUpdateSandbox)
  yield fork(watchUpdates, 'UPDATE_DATA_SANDBOX', handleUpdateSandboxData)
  yield fork(watchUpdates, 'UPDATE_FINE_TUNING', handleUpdateFineTuning)
  try {
    while (true) {
      const action = yield take(channel)
      yield put({ type: action.type, data: action.data })
    }
  } catch (error) {
  } finally {
    channel.close()
    yield put(changeSseChannelStatus(false))
  }
}

// корневая сага, которая слушает экшены для запуска и остановки SSE
export function * SSEWatcher () {
  while (true) {
    const action = yield take('START_SSE_WORKER')
    // console.log("START_SSE_WORKER 1");
    const { stop } = yield race({  //закрытие всех саг, если одна из саг выполнена
      start: SSEWorker(action),
      stop: take('STOP_SSE_WORKER'),
    })
    if (stop) {
      yield stopSSEWorker()
    }
  }
}
