import React, { useEffect } from 'react'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import { useActions } from '../../hook/useActions'
import { useSelector } from 'react-redux'

const AlertMessage = () => {

  const {
    resetSuccessAlertMessage,
    resetErrorAlertMessage,
    resetInfoAlertMessage,
    resetAllAlertMessage,
  } = useActions()

  const {
    errorAlertMessage,
    successAlertMessage,
    infoAlertMessage,
  } = useSelector(state => state.serviceReducer)

  const alertTypes = [
    { type: 'success', message: successAlertMessage, reset: resetSuccessAlertMessage },
    { type: 'warning', message: errorAlertMessage, reset: resetErrorAlertMessage },
    { type: 'info', message: infoAlertMessage, reset: resetInfoAlertMessage },
  ]

  useEffect(() => {
    return () => {
      resetAllAlertMessage()
    }
  }, [])

  return (
    <>
      <Collapse in={alertTypes.some(alert => alert.message)}>
        {alertTypes.map(alert => (
          alert.message && (
            <Alert
              key={alert.type}
              severity={alert.type}
              sx={{ mb: 2 }}
              onClose={() => alert.reset()}
            >
              {alert.message}
            </Alert>
          )
        ))}
      </Collapse>
    </>
  )
}

export default React.memo(AlertMessage)