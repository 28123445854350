import { Button, Grid, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import useResponsive from "../../hook/useResponsive";
const AddEditButtonForm = ({
                                    action,
                                    handleClose,
                                    handleDeleteRow,
                                    confirmationForEditForm,
                                    setConfirmationForEditForm, selectedRow, baseGroups
                                }) => {
    const { t } = useTranslation()
    const isMobile = useResponsive('down', 'sm')
    const {
        handleClearFields,
    } = useFormContext()

    return (
        <Grid item sx={{ width: '100%', mb: 2 }}>
            {isMobile ?
                <Stack direction='column' spacing={2}
                       justifyContent="flex-end"
                       alignItems="flex-end"
                >
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: '150px',
                        }}
                        onClick={handleClearFields}
                    >
                        {action === 'Add'
                            ? t('src.components.Intelligence.clear')
                            : t('src.components.Intelligence.reset')
                        }
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{width: '100%'}}
                    >
                        {action === 'Add'
                            ? t('src.components.Intelligence.add')
                            : t('main.another.save')}
                    </Button>
                    {(confirmationForEditForm)
                        ?
                        <Stack width="100%" direction="row" justifyContent="center"
                               alignItems="center"
                               spacing={2}>
                            <Tooltip title="Подтвердить">
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={handleDeleteRow}
                                >
                                    <CheckIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Отмена">
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() => setConfirmationForEditForm(null)}
                                >
                                    <ClearIcon/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        : (action === 'Update' && handleDeleteRow) &&
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            sx={{
                                width: '100%',
                            }}
                            disabled={baseGroups?.includes(selectedRow?.name) || false}
                            onClick={() => setConfirmationForEditForm(true)}
                        >
                            {t('src.components.Intelligence.delete', 'Удалить элемент')}
                        </Button>
                    }
                </Stack>
                :
                <Stack direction='row' spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item direction='row'>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            {action === 'Add'
                                ? t('src.components.Intelligence.add')
                                : t('main.another.save')}
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{ ml: 1 }}
                            onClick={handleClose}
                        >
                            {t('src.components.Intelligence.close')}
                        </Button>
                    </Grid>
                    <Grid item display="flex" justifyContent="end" direction='row'>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{
                                ml: 1
                            }}
                            onClick={handleClearFields}
                        >
                            {action === 'Add'
                                ? t('src.components.Intelligence.clear')
                                : t('src.components.Intelligence.reset')
                            }
                        </Button>
                        {(confirmationForEditForm)
                            ? <>
                                <Tooltip title="Подтвердить">
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        onClick={handleDeleteRow}
                                    >
                                        <CheckIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Отмена">
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        onClick={() => setConfirmationForEditForm(null)}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </Tooltip>
                            </>
                            : (action === 'Update' && handleDeleteRow) &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                sx={{
                                    ml: 1
                                }}
                                disabled={baseGroups?.includes(selectedRow?.name) || false}
                                onClick={() => setConfirmationForEditForm(true)}
                            >
                                {t('src.components.Intelligence.delete', 'Удалить элемент')}
                            </Button>
                        }
                    </Grid>
                </Stack>
            }
        </Grid>
    )
}

export default AddEditButtonForm