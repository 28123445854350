import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[400],
          },
        },
        sizeLarge: {
          height: 48,
        },
        sizeSmall:{
          paddingLeft: 16,
          paddingRight: 16,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z4,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.layout.element,
          boxShadow: theme.customShadows.layout_element,
          '&:hover': {
            backgroundColor: theme.palette.layout.element,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.layout.element}`,
          color: theme.palette.layout.element,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            border: `1px solid ${alpha(theme.palette.grey[500], 0.9)}`,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.layout_element,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.9)}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          color: theme.palette.layout.element,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }
      },
    },
  };
}
