import React, {useCallback, useState} from 'react';
import { Handle, Position } from 'reactflow';
import {Badge, Grid, TextField, Typography} from "@mui/material";
import {Forum} from "@mui/icons-material";
import Iconify from "../../iconify";
import i18next from "i18next";

function ParserNode({ data, isConnectable }) {

    return (
        <Badge
            badgeContent={
                <Iconify

                    icon="pepicons-pop:open"
                    width = {15}
                    sx={{transform: 'translate(-10px, 10px)', color: 'text.secondary'}}
                />
            }
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
            alignItems="center"
            className="retriever-node-puzzle"
            id={data.type}
        >
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <Typography sx={{fontSize: '9pt', fontWeight: 700}} align="center">
                    {i18next.t(data.name)}
                </Typography>
            </div>
            <Handle type="target"  id="L" position={Position.Left} isConnectable={isConnectable} />
            <Handle type="target"  id="R" position={Position.Right} isConnectable={isConnectable} />
            <Handle type="source" id="B" position={Position.Bottom} isConnectable={isConnectable} />
        </Grid>
        </Badge>
    );
}

export default ParserNode;
