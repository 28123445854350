import React, { FC } from 'react';
import {EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, getSmoothStepPath} from 'reactflow';
import i18next from "i18next";

// this is a little helper component to render the actual edge label
function EdgeLabel({ transform, label }) {
    return (
        <div
            style={{
                position: 'absolute',
                background: '#ffffff',
                padding: 0,
                color: '#010101',
                fontSize: 10,
                fontWeight: 500,
                transform
            }}
            className="nodrag nopan"
        >
            {label}
        </div>
    );
}

const CustomEdge = ({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       data,
                                        style= {}
                                   }) => {
    const [edgePath] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} style={style}/>
            <EdgeLabelRenderer>
                {data.startLabel && (
                    <EdgeLabel
                        transform={`translate(-50%, 50%) translate(${sourceX}px,${sourceY}px)`}
                        label={i18next.t(data.startLabel)}
                    />
                )}
            </EdgeLabelRenderer>
        </>
    );
};

export default CustomEdge;
