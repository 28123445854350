import React, {useCallback, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    IconButton, Stack,
    TextField,
    Typography
} from "@mui/material";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CardRule from "./CardRule";
import ParserForms from "./ParserForms";
import {useActions} from "../../../hook/useActions";
import {useSelector} from "react-redux";
import {random} from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTextField from "../../Fields/CustomTextField";
import CustomMultiTextFieldAutocomplete from "../../Fields/CustomMultiTextFieldAutocomplete";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import ClearIcon from "@mui/icons-material/Clear";

const ContentParserComponent = ({...props}) => {

    const {t} = useTranslation()

    const {
        setEditParserRule,
        setParserRules,
        setSelectedParserRule,
        moveRules
    } = useActions()

    const {
        selectedParserRule,
        parserRules,
    } = useSelector(state => state.diagramReducer)

    const {
        setChannels,
        setIntelligens,
        setSelectedIntellegence,
        setSuccessAlertMessage,
        setErrorAlertMessage,
        updateFineTuning,
        updateStudyStatus,
        setBreakerPrompt,
        setBouncerPrompt,
        setStylistPrompt,
        setRetrieverPrompt,

        setModifiedBreaker,
        setModifiedStylist,
        setModifiedRetriever,
        setModifiedBouncer
    } = useActions()

    const {
        selectedIntellegence,
        studiedAssistants,
        modifiedBreaker,
        modifiedStylist,
        modifiedRetriever,
        modifiedBouncer
    } = useSelector(state => state.intelligensReducer)


    const [_rules, _setRules] = useState([])

    const addRule = () => {
        setParserRules([...parserRules,
          {
              id: `${parserRules.length}-${random(1,1000)}`,
              name: `name ${parserRules.length}`
          }
      ])
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        moveRules({ dragIndex: dragIndex, hoverIndex: hoverIndex })
    }, [])

    const renderCard = useCallback((card, index) => {
        return (
            <CardRule
                key={card.id}
                index={index}
                id={card.id}
                moveCard={moveCard}
                children={
                    <ParserForms card={card}/>
                }
            />
        )
    }, [])

    function renderPrompt()  {
        switch (props.selectedNodes.data.type){
            case ('bouncer'): {
                return modifiedBouncer!== null ? modifiedBouncer : selectedIntellegence?.bouncer_assistant.prompt
            }
            case ('stylist'): {
                return modifiedStylist !== null ? modifiedStylist : selectedIntellegence?.output_assistant.prompt
            }
            case ('retriever'): {
                return modifiedRetriever !== null ? modifiedRetriever : selectedIntellegence?.main_assistant.prompt
            }
            case ('breaker'): {
                return modifiedBreaker !== null ? modifiedBreaker : selectedIntellegence?.breaker_assistant.prompt
            }
        }
    }

    const changePrompt = (value) => {
        switch (props.selectedNodes.data.type){
            case ('bouncer'): {
                setModifiedBouncer(value)
            }
            break;
            case ('stylist'): {
                setModifiedStylist(value)
            }
            break;
            case ('retriever'): {
                setModifiedRetriever(value)
            }
            break;
            case ('breaker'): {
                setModifiedBreaker(value)
            }
            break;
        }
    }


    return (
        <>
            <Stack direction="row"
                   justifyContent="space-between"
                   alignItems="center">
                <Typography
                    variant="h5"
                    sx={{ m1: 3 }}
                    color="primary"
                >
                    {i18next.t(props.selectedNodes?.data.name)}
                </Typography>
                <IconButton onClick={props.OnClearSelection}>
                    <ClearIcon/>
                </IconButton>
            </Stack>
            <Accordion
                sx={{
                    width: '100%',
                    '&:before': {
                        display: 'none',
                    }
                }}

            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    sx={{p: 0, flexDirection: 'row-reverse'}}
                >
                    <Typography color="text.secondary" ml={1} variant="headerTable">
                        {t('src.components.Intelligence.description')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                    <Grid container>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ lineHeight: 2}}
                        >
                            {i18next.t(props.selectedNodes?.data.info || "")}
                        </Typography>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <TextField
                label={t('src.pages.ModelManagement.prompt')}
                variant="outlined"
                multiline
                rows={12}
                size="small"
                sx={{ mt:3, mb: 2 }}
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{style: {resize: "vertical"}}}
                value={renderPrompt()}
                onChange={ (e) => changePrompt(e.target.value) }
            />
            {/*{selectedParserRule !== null ?*/}
            {/*    <ParserForms card={selectedParserRule} />*/}
            {/*    :*/}
            {/*    <>*/}
            {/*        <DndProvider backend={HTML5Backend}>*/}
            {/*            {parserRules &&*/}
            {/*                parserRules.map((card, index) =>*/}
            {/*                    renderCard(card, index)*/}
            {/*                )*/}
            {/*            }*/}
            {/*        </DndProvider>*/}
            {/*        <Button variant="contained" onClick={addRule} sx={{mt: 3}}>*/}
            {/*            Добавить правило*/}
            {/*        </Button>*/}
            {/*    </>*/}
            {/*}*/}

        </>
    );
};

export default ContentParserComponent;