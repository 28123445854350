import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Card, Container, IconButton, Stack, Switch, Tooltip, Typography} from '@mui/material'
import Iconify from '../components/iconify'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import {useNavigate} from 'react-router-dom'
import {useActions} from '../hook/useActions'
import {useSelector} from 'react-redux'
import {$authHost} from '../http'
import LoadingSpinner from '../components/modals/loadingSpinner'
import {TableCellsWrap} from '../components/TableCellsWrap'
import MaterialTable from '../components/MaterialTable'
import {ButtonStack, RowOptionsStack, TitleStack} from "../theme/standarts_styles";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import useResponsive from "../hook/useResponsive";

const Intelligence = () => {
  const {t} = useTranslation()
  const [confirmationId, setConfirmationId] = useState(null);
  const isMobile = useResponsive('down', 'md')


  const tableColumns = [
    {
      accessorKey: 'name',
      header: t('src.pages.Intelligence.name'),
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'type',
      header: t('src.pages.ModelManagement.type'),
      Cell: ({ renderedCellValue, row }) => (
          <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'channels',
      header: t('src.pages.Intelligence.channels'),
      accessorFn: (row) => {
        const managerName = row.channels;
        return (
          managerName.map((el, index) => <Chip label={el} sx={{mr: 1}}/>)
        );
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'prompt_main',
    //   header: t('src.pages.Intelligence.retriever'),
    //   Cell: ({ renderedCellValue, row }) => (
    //       <TableCellsWrap>{row.original.main_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    // {
    //   accessorKey: 'prompt_breaker',
    //   header: t('src.pages.Intelligence.breaker'),
    //   Cell: ({ renderedCellValue, row }) => (
    //     <TableCellsWrap>{row.original.breaker_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    // {
    //   accessorKey: 'prompt_output',
    //   header: t('src.pages.Intelligence.stylist'),
    //   Cell: ({ renderedCellValue, row }) => (
    //       <TableCellsWrap>{row.original.output_assistant?.prompt.toString()}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title={t('src.pages.Channels.confirm')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('src.pages.Channels.cancel')}>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title={t('src.pages.Channels.manage')}>
              <IconButton size="large" color="inherit" onClick={() => handleGoToManagePage(row.original.id)}>
                <SettingsIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title={row.original.is_run ? t('src.pages.Channels.turnOff') : t('src.pages.Channels.turnOn')}>
              <Switch checked={String(row.original.is_run) === 'true'} size="small"
                      onChange={(event, checked) => handleIsRun(row.original.id, checked)}/>
            </Tooltip>
            <Tooltip title={t('src.pages.Channels.delete')}>
              <IconButton size="large" color="inherit" onClick={() => setConfirmationId(row.original.id)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </RowOptionsStack>
        )
      },
    },
  ]

  const {
    setIntelligens,
    setSelectedIntellegence,
  } = useActions()

  const {
    intelligences,
    selectedIntellegence,
  } = useSelector(state => state.intelligensReducer)

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/intellect/')
        return response.data
      }
      newChannelList().then((data) => {
        setIntelligens(data)
      })
    } catch (e) {
    }
  }, [])

  const handleAddUser = (event) => {
    navigate(`/model-management/new/settings`)
  }

  async function handleIsRun(id_row, is_run) {
    setLoading(true)
    try {
      await $authHost.patch(`/intellect/set_is_run?id=${id_row}&bool=${is_run}`).then(response => {
        loadData()
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  async function handleDeleteRow(id_row) {
    setLoading(true)
    try {
      await $authHost.delete(`/intellect/delete_row?id=${id_row}`).then(response => {
        loadData()
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }

  }

  const handleGoToManagePage = (id) => {
    navigate(`/model-management/edit/${id}`)
    setSelectedIntellegence(id)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      {loading && <LoadingSpinner/>}
      <Container>
        <TitleStack>
          <Typography variant="h4">
            {t('src.pages.Intelligence.header')}
          </Typography>
        </TitleStack>

        <ButtonStack>
          <Button
            onClick={handleAddUser}
            aria-haspopup="true"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill"/>}
            sx={{mr: 3}}
            size={isMobile ? 'small': 'medium'}
          >
            {t('src.pages.Intelligence.addIntelligence')}
          </Button>
        </ButtonStack>
        <Card sx={{pt: 1}}>
          <MaterialTable data={intelligences} columns={tableColumns}/>
        </Card>
      </Container>
    </>
  )
}

export default Intelligence