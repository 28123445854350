import React from 'react';
import { MarkerType, Position } from 'reactflow';
import ChatGptNode from "../components/DiagramComponent/nodes/ChatgptNode";
import ParserNode from "../components/DiagramComponent/nodes/ParserNode";
import GateNode from "../components/DiagramComponent/nodes/GateNode";
import ChannelNode from "../components/DiagramComponent/nodes/ChannelNode";
import ResponseNode from "../components/DiagramComponent/nodes/ResponseNode";
import i18next from "i18next";

export const nodesInit = [
    {
        id: '1',
        type: 'channel',
        data: {
            label: 'src.pages.Intelligence.usersQuestion',
        },
        position: { x: 250, y: 0 },
    },
    {
        id: '2',
        type: 'info',
        data: {
            label: 'src.pages.Intelligence.infoActionDescription'
        },
        position: { x: 215, y: 100 },
    },
    {
        id: '3',
        type: 'parser',
        data: {
            type:'breaker',
            name: 'src.pages.Intelligence.breaker',
            info: 'src.pages.Intelligence.breakerDescription'
        },
        position: { x: 215, y: 235 },
    },
    {
        id: '4',
        type: 'action',
        position: { x: 215, y: 315 },
        data: {
            label: 'src.pages.Intelligence.checkUserQuestion',
        },
    },
    {
        id: '5',
        type: 'gate',
        data: {
            label: 'src.pages.Intelligence.callingManager'
        },
        position: { x: 230, y: 395 },
    },
    {
        id: '6',
        type: 'action',
        position: { x: 380, y: 500 },
        data: {
            label: 'src.pages.Intelligence.SwitchingToManager',
        },
    },
    {
        id: '7',
        type: 'gate',
        data: {
            label:'src.pages.Intelligence.forbiddenInfo'
        },
        position: { x: 85, y: 585 },
    },
    {
        id: '8',
        position: { x: 415, y: 580},
        type: 'response',
        data: {
            label: 'src.pages.Intelligence.ResponseToUser',
        },
    },
    {
        id: '9',
        type: 'gate',
        data: {
            label:'src.pages.Intelligence.greetingInTheMessage'
        },
        position: { x: 300, y: 736},
    },
    {
        id: '10',
        type: 'parser',
        data: {
            type:'retriever',
            name: 'src.pages.Intelligence.retriever',
            info: 'src.pages.Intelligence.retrieverDescription'
        },
        position: { x: -160, y: 700 },
    },
    {
        id: '11',
        type: 'parser',
        data: {
            type: 'stylist',
            name: 'src.pages.Intelligence.stylist',
            info: 'src.pages.Intelligence.stylistDescription'
        },
        position: { x: -160, y: 800 },
    },
    {
        id: '12',
        position: { x: -125, y: 905 },
        type: 'response',
        data: {
            label: 'src.pages.Intelligence.ResponseToUser',
        },
    },
    {
        id: '13',
        type: 'action',
        position: { x: 450, y: 840 },
        data: {
            label: 'src.pages.Intelligence.greetingInTheAnswer',
        },
    },
    {
        id: '14',
        type: 'action',
        position: { x: 123, y: 850},
        data: {
            label: 'src.pages.Intelligence.noGreetingInTheAnswer',
        },
    },
    {
        id: '15',
        type: 'parser',
        data: {
            type: 'bouncer',
            name: 'src.pages.Intelligence.bouncer',
            info: 'src.pages.Intelligence.bouncerDescription'
        },
        position: { x: 284, y: 940},
    },
    {
        id: '16',
        position: { x: 318, y: 1045 },
        type: 'response',
        data: {
            label: 'src.pages.Intelligence.ResponseToUser',
        },
    },

];

export const edgesInit = [
    { id: 'e1-2', source: '1', target: '2',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-3', source: '2', target: '3',
        type: 'smoothstep' ,
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-4', source: '3', target: '4',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-5', source: '4', target: '5',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-7', source: '6', target: '8',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-90', source: '10', target: '11',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    { id: 'e1-50', source: '11', target: '12',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    {
        id: 'e1-rrf', source: '15', target: '16',
        type: 'smoothstep',
        style: { stroke: '#4d4d4d' }},
    {
        id: 'e4-5',
        source: '5',
        target: '6',
        sourceHandle: 'a',
        data: {
            startLabel: "src.pages.Intelligence.yes"
        },
        type: 'start-end',
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-6',
        source: '5',
        target: '7',
        type: 'start-end',
        sourceHandle: 'b',
        data: {
            startLabel: "src.pages.Intelligence.no"
        },
        style: { stroke: '#4d4d4d', marginLeft: 15 }
    },
    {
        id: 'e4-8',
        source: '7',
        target: '9',
        sourceHandle: 'a',
        type: 'start-end',
        data: {
            startLabel: "src.pages.Intelligence.yes"
        },
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-9',
        source: '7',
        type: 'start-end',
        target: '10',
        sourceHandle: 'b',
        data: {
            startLabel: "src.pages.Intelligence.no"
        },
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-89',
        source: '9',
        target: '13',
        sourceHandle: 'a',
        type: 'start-end',
        data: {
            startLabel: "src.pages.Intelligence.yes"
        },
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-35',
        source: '9',
        type: 'start-end',
        target: '14',
        sourceHandle: 'b',
        data: {
            startLabel: "src.pages.Intelligence.no"
        },
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-89',
        source: '13',
        target: '15',
        type: 'start-end',
        targetHandle: 'R',
        data: {
            startLabel: "src.pages.Intelligence.edgeLabelGreetUser"
        },
        style: { stroke: '#4d4d4d' }
    },
    {
        id: 'e4-35',
        source: '14',
        type: 'start-end',
        targetHandle:'L',
        target: '15',
        data: {
            startLabel: "src.pages.Intelligence.edgeLabelGreetClient"
        },
        style: { stroke: '#4d4d4d'}
    },


];
