import {useEffect} from "react";
import widgetStyles from '../utils/widget/styles/style.css';
import iconsImportScript from '../utils/widget/scripts/iconsImport';
import {widgetBackFn} from "../utils/widget/scripts/back";
import {widgetMainFn} from "../utils/widget/scripts/main";

const WidgetPreview = ({
                         channelType,
                         buttonTogglerColor,
                         messageColor,
                         mainColor,
                         backgroundColor,
                         buttonSendColor
                       }) => {
  useEffect(() => {
    if (channelType === 'Web' || channelType === 'GetCourse') {
      widgetBackFn();
      widgetMainFn();
    }
  }, [channelType])

  useEffect(() => {
    document.documentElement.style.setProperty('--button_toggler-color', buttonTogglerColor);
  }, [buttonTogglerColor]);

  useEffect(() => {
    document.documentElement.style.setProperty('--message-color', messageColor);
  }, [messageColor]);

  useEffect(() => {
    document.documentElement.style.setProperty('--main-color', mainColor);
  }, [mainColor]);

  useEffect(() => {
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  useEffect(() => {
    document.documentElement.style.setProperty('--button_send-color', buttonSendColor);
  }, [buttonSendColor]);

  return (
    <div style={{zIndex: 9}} dangerouslySetInnerHTML={{
      __html: `
    <div id="widget-wrapper">
    <div id="widget-body">
    <button class="chatbot-toggler" type="button">
        <span class="material-symbols-rounded">question_answer</span>
        <span class="material-symbols-outlined">close</span>
    </button>
    <div class="chatbot">
        <header>
            <div class="text">
                <span class="title" id="title"></span>
                <span class="worktime" id="worktime"></span>
            </div>
            <div class="logo">
                <img src="" alt="Logo" id="logo">
            </div>
            <span class="close-btn material-symbols-outlined">close</span>
        </header>
        <ul class="chatbox">
            <li class="chat incoming">
                <span class="material-symbols-outlined">smart_toy</span>
                <p>Привет 👋<br>Чем я могу помочь?</p>
            </li>
        </ul>
        <div class="chat-input">
            <textarea placeholder="Введите текст..." spellcheck="false"></textarea>
            <span id="send-btn" class="material-symbols-rounded">send</span>
        </div>
    </div>
</div>
</div>
                  `
    }}>
    </div>)
}

export default WidgetPreview;