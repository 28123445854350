import React from 'react'
// @mui
import { Container, Link, Typography } from '@mui/material'
import { RegisterForm } from '../components/Forms/RegisterForm'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {StyledContent, StyledRoot, StyledRootUpMD} from '../theme/standarts_styles'

const Register = () => {

  const location = useLocation()
  const currentLocation = location.pathname === '/register'
  const { t } = useTranslation()

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom align="center">
              {
                currentLocation
                  ? t('src.pages.Register.registerPageText')
                  : t('src.pages.Register.changePasswordPageText')
              }
            </Typography>
            <RegisterForm currentLocation={currentLocation}/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  )
}

export default Register
