// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        color: 'default',
        size: 'small',
      }
    }
  };
}
