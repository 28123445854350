import {useCallback, useState} from 'react';
import { Handle, Position } from 'reactflow';
import {Grid, TextField, Typography} from "@mui/material";
import i18next from "i18next";

function ResponseNode({ data, isConnectable }) {
    const [text, setText] = useState(data.text ?? "");

    const onChange = useCallback((evt) => {
        data.text = evt.target.value
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="responze-node-puzzle"
        >
            <div>
                <Typography align="center" sx={{fontSize: '6pt'}}>
                    {i18next.t(data.label)}
                </Typography>
            </div>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </Grid>
    );
}

export default ResponseNode;
