import React, {useState} from 'react';
import {Autocomplete, Button, Card, Stack, TextField, Typography} from "@mui/material";
import CustomTextFieldAutocomplete from "../../Fields/CustomTextFieldAutocomplete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useActions} from "../../../hook/useActions";
import {useSelector} from "react-redux";

const ParserForms = ({...props}) => {

    const _tempTypes = [
        {
            type: "add_text",
            label: "Добавить текст"
        },
        {
            type: "replace_text",
            label: "Заменить текст"
        },
        {
            type: "delete_text",
            label: "Удалить текст"
        },
        {
            type: "fill_variable",
            label: "Заполнить переменную"
        },
    ]

    const {
        setSelectedParserRule,
        deleteRule,
        setSelectedRuleType
    } = useActions()

    const {
        selectedParserRule,
        selectedRuleType
    } = useSelector(state => state.diagramReducer)

    const handlerDeleteRule = () => {
        deleteRule(props.card.id)
    }

    const changeParserType = (value) => {
        // console.log(value)
        setSelectedRuleType(value?.type || null)

    }

    function renderContent() {
        switch (selectedRuleType) {
            case "add_text" :
                return (
                    <>
                        <TextField
                            label="Место добавления"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            label="Текст"
                            variant="outlined"
                            multiline
                            rows={4}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </>
                );
            case "replace_text":
                return (
                    <>
                        <TextField
                            label="Искомый текст"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            label="Заменить на"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </>
                );
            case "delete_text":
                return (
                    <>
                        <TextField
                            label="Искомый текст"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </>
                );
            case "fill_variable":
                return (
                    <>
                        <TextField
                            label="Наименование переменной"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            label="Искомый текст"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </>
                );
            case null:
                return (
                    <>
                    </>
                );

        }
    }


    switch (props.card.id === selectedParserRule?.id) {
        case true:
            return (
            <>
                <TextField
                    label="Наименование правила"
                    variant="outlined"
                    value={props.card.name}
                    sx={{ mb: 2 }}
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <Autocomplete
                    options={_tempTypes}
                    getOptionLabel={option => option.label}
                    size="small"
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Тип правила"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ mb: 2 }}
                        />
                    )}
                    onChange={(event, value)=>{changeParserType(value)}}
                />
                {renderContent()}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Button>
                        Сохранить
                    </Button>
                    <Button onClick={()=>
                        {
                            setSelectedParserRule(null);
                            setSelectedRuleType(null)
                        }
                    } >
                        Отменить
                    </Button>
                </Stack>

            </>
        );
        default:
            return (
                <Card sx={{p:2, background: "text.primary"}}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <div>
                            <Typography variant="subtitle1">
                                {props.card.name}
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={ ()=>{ setSelectedParserRule(props.card.id) } } size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton size="small" onClick={ ()=>{ handlerDeleteRule() } }>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </div>

                    </Stack>
                </Card>
            );
    }


};

export default ParserForms;