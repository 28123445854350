import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  intellegencesTypeFormIsAdd: false,
  intelligences: [],
  selectedIntellegence: null,
  generalData: [],
  selectGeneralData: null,
  manufacturers: [],
  selectedManufacturers: null,
  questionsAndAnswers: [],
  selectedQuestionAndAnswer: null,
  products: [],
  selectedProducts: null,
  productDescription: [],
  selectedProductDescription: null,
  studiedAssistants: [
    // {
    //   customer : "b8b020f8-f041-44b0-9217-16ad5bdd234b",
    //   message: "active"
    // }
  ],
  modifiedBreaker: null,
  modifiedStylist: null,
  modifiedRetriever: null,
  modifiedBouncer: null,
  modelTypeFormIsAdd: false
}

const intelligensSlice = createSlice({
  name: 'intelligens',
  initialState,
  reducers: {
    logOut () { return initialState },
    setModelTypeFormIsAdd (state, { payload }) {
      state.modelTypeFormIsAdd = payload
    },
    updateFineTuning (state, { payload }) {
      const { text } = payload;
      let arr = text.split('#');
      switch (arr[0]) {
        case state.selectedIntellegence.breaker_assistant.id: {
          if(state.selectedIntellegence.breaker_assistant.hasOwnProperty('status'))
          {
            state.selectedIntellegence.breaker_assistant.status = arr[2];
            state.selectedIntellegence.breaker_assistant.events = arr[1];
          }else{
            return{
              ...state,
              selectedIntellegence: {
                ...state.selectedIntellegence,
                breaker_assistant: {
                  ...state.selectedIntellegence.breaker_assistant,
                  status: arr[2],
                  events: arr[1]
                }
              }
            }
          }
          break;
        }
        case state.selectedIntellegence.output_assistant.id: {
          if(state.selectedIntellegence.output_assistant.hasOwnProperty('status'))
          {
            state.selectedIntellegence.output_assistant.status = arr[2];
            state.selectedIntellegence.output_assistant.events = arr[1];
          }else{
            return{
              ...state,
              selectedIntellegence: {
                ...state.selectedIntellegence,
                output_assistant: {
                  ...state.selectedIntellegence.output_assistant,
                  status: arr[2],
                  events: arr[1]
                }
              }
            }
          }
          break;
        }
        default :
          break;
      }
    },
    updateStudyStatus (state, { payload }) {
      switch (payload.assistant_id) {
        case state.selectedIntellegence.breaker_assistant.id: {
          if(state.selectedIntellegence.breaker_assistant.hasOwnProperty('status'))
          {
            state.selectedIntellegence.breaker_assistant.status = payload.status;
            state.selectedIntellegence.breaker_assistant.events = payload.events[payload.events.length - 1];
          }else{
            return{
              ...state,
              selectedIntellegence: {
                ...state.selectedIntellegence,
                breaker_assistant: {
                  ...state.selectedIntellegence.breaker_assistant,
                  status: payload.status,
                  events: payload.events[payload.events.length - 1]
                }
              }
            }
          }
          break;
        }
        case state.selectedIntellegence.output_assistant.id: {
          if(state.selectedIntellegence.output_assistant.hasOwnProperty('status'))
          {
            state.selectedIntellegence.output_assistant.status = payload.status;
            state.selectedIntellegence.output_assistant.events = payload.events[payload.events.length - 1];
          }else{
            return{
              ...state,
              selectedIntellegence: {
                ...state.selectedIntellegence,
                output_assistant: {
                  ...state.selectedIntellegence.output_assistant,
                  status: payload.status,
                  events: payload.events[payload.events.length - 1]
                }
              }
            }
          }
          break;
        }
        default :
          break;
      }
    },
    setIntelligens (state, { payload }) {
      state.intelligences = payload
    },
    setModifiedBreaker (state, { payload }) {state.modifiedBreaker = payload},
    setModifiedStylist (state, { payload }) {state.modifiedStylist = payload},
    setModifiedRetriever (state, { payload }) {state.modifiedRetriever = payload},
    setModifiedBouncer (state, { payload }) {state.modifiedBouncer = payload},
    setGeneralData (state, { payload }) {state.generalData = payload},
    setManufacturers (state, { payload }) {state.manufacturers = payload},
    setProducts (state, { payload }) {state.products = payload},
    setQuestionsAndAnswers (state, { payload }) {state.questionsAndAnswers = payload},
    setProductDescription (state, { payload }) {state.productDescription = payload},
    setSelectedQuestionAndAnswer (state, { payload }) {
      const index = state.questionsAndAnswers.findIndex(
          (intellegence) => intellegence.id === payload,
      )
      state.selectedQuestionAndAnswer = state.questionsAndAnswers[index]
    },
    setSelectedIntellegence (state, { payload }) {
      const index = state.intelligences.findIndex(
        (intellegence) => intellegence.id === payload,
      )
      state.selectedIntellegence = state.intelligences[index] || null
    },
    setSelectGeneralData (state, { payload }) {
      const index = state.generalData.findIndex(
        (generalData) => generalData.id === payload,
      )
      state.selectGeneralData = state.generalData[index]
    },
    setSelectedManufacturers (state, { payload }) {
      const index = state.manufacturers.findIndex(
        (generalData) => generalData.id === payload,
      )
      state.selectedManufacturers = state.manufacturers[index]
    },
    setSelectedProducts (state, { payload }) {
      const index = state.products.findIndex(
        (products) => products.id === payload,
      )
      state.selectedProducts = state.products[index]
    },
    setSelectedProductDescription (state, { payload }) {
      const index = state.productDescription.findIndex(
        (productDescription) => productDescription.id === payload,
      )
      state.selectedProductDescription = state.productDescription[index]
    },

    addQuestions (state, { payload }) {
      state.questions = [...state.questions, payload]
    },
    deleteQuestions (state, { payload }) {
      const find_index = state.questions.findIndex(
        (question) => question.index === payload.index,
      )
      state.questions = state.questions.slice(0, find_index).concat(state.questions.slice(find_index + 1))
    },
    setSelectedChannel (state, { payload }) { state.selectedChannel = payload },
    setIntellegencesTypeFormIsAdd (state, { payload }) { state.intellegencesTypeFormIsAdd = payload },

  },
})

export const { actions: intelligensActions, reducer: intelligensReducer } = intelligensSlice
// export const { addNewMessage, addChat } = dialoguesSlice
export default intelligensSlice.reducer
