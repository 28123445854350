import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Card, Container, Grid, IconButton, Paper, Popover, Stack, Switch, Tooltip} from '@mui/material'
import Label from '../../components/label/Label'
import Iconify from '../../components/iconify'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../hook/useActions'
import { useSelector } from 'react-redux'
import { $authHost } from '../../http'
import { TableCellsWrap } from '../../components/TableCellsWrap'
import EditIcon from '@mui/icons-material/Edit'
import LoadingSpinner from '../../components/modals/loadingSpinner'
import MaterialTable from '../../components/MaterialTable'
import { fDateTime } from '../../utils/formatTime'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CustomTextField from '../../components/Fields/CustomTextField'
import { FormProvider, useForm } from 'react-hook-form'
import AlertMessage from '../../components/Alert/AlertMessage'
import {ButtonStack, RowOptionsStack} from "../../theme/standarts_styles";
import DeleteIcon from '@mui/icons-material/Delete'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import BaseAddEditForm from "../../components/Forms/BaseAddEditForm";
import AddEditFormButtonGroup from "../../components/Buttons/AddEditFormButtonGroup";
import AccessUserFields from "../../components/Forms/FormFields/AccessUserFields";
import AddEditForm from "../../components/Forms/AddEditForm";
import AddEditButtonForm from "../../components/Forms/AddEditButtonForm";

export default function AccessUserPage () {

  const { t } = useTranslation()

  const [openForm, setOpenForm] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [groups, setGroups] = useState(null)
  const [confirmationId, setConfirmationId] = useState(null)
  const [confirmationForEditForm, setConfirmationForEditForm] = useState(null)
  const fieldsRef = useRef(null)
  const fieldsHeight = fieldsRef?.current?.clientHeight || null

  const tableColumns = [
    {
      accessorKey: 'email',
      header: t('src.pages.UserManage.table_email'),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },

    {
      accessorKey: 'name',
      header: t('src.pages.UserManage.table_name'),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'groups',
      header: t('src.pages.UserManage.table_group'),
      filterVariant: 'select',
      filterSelectOptions: ['Менеджер', 'Админ'],
      Cell: ({ renderedCellValue, row }) => (
          <TableCellsWrap>
            {/*{renderedCellValue.map(group => group.description).join(', ')}*/}
            <div dangerouslySetInnerHTML={{
              __html: [...renderedCellValue].map(group => group.name).sort().join(';<br />'),
            }}/>
          </TableCellsWrap>
      ),
    },
    {
      accessorKey: 'created_at',
      header: t('src.pages.UserManage.table_created_at'),
      accessorFn: (row) => new Date(row.created_at),
      filterFn: 'lessThanOrEqualTo',
      sortingFn: 'datetime',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{fDateTime(renderedCellValue)}</TableCellsWrap>
      ),
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue)
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: 'status',
      header: t('src.pages.UserManage.table_status'),
      Cell: ({ renderedCellValue, row }) => (
        <Label color="secondary">{renderedCellValue}</Label>
      ),
    },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
              <>
                <Tooltip title="Подтвердить">
                  <IconButton
                      size="large"
                      color="inherit"
                      onClick={() => handleDeleteRow(row.original.id)}
                  >
                    <CheckIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Отмена">
                  <IconButton
                      size="large"
                      color="inherit"
                      onClick={() => setConfirmationId(null)}
                  >
                    <ClearIcon/>
                  </IconButton>
                </Tooltip>
              </>
          )
        }
        return(
            <RowOptionsStack>
              <IconButton size="large" color="inherit" onClick={() => handleGoToEditPage(row.original)}>
                <EditIcon/>
              </IconButton>
              <Tooltip title="Отключить учетную запись" arrow>
                <Switch checked={row.original.status !== 'Disabled'}
                        size="small"
                        onChange={(event, checked) => handleIsRun(row.original, !checked)}/>
              </Tooltip>
              <IconButton size="large" color="inherit" onClick={() => setConfirmationId(row.original.id)}>
                <DeleteIcon/>
              </IconButton>
            </RowOptionsStack>
        )
      },
    },
  ]


  const {
    setInfoAlertMessage,
    resetAllAlertMessage,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setUsers,
  } = useActions()

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
    reset,
  } = methods

  const {
    users,
  } = useSelector(state => state.usersReducer)

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/user/get_all')
        return response.data
      }
      newChannelList().then((data) => {
        setUsers(data)
      })
    } catch (e) {
    }
  }, [])



  const onSubmitAddUser = async (data) => {
    const { email } = data
    setLoading(true)
    try {
      // await $authHost.post(`/user/invite_user?email=${email}`)
      await $authHost.post(`/user/invite_access_management`, data)
      resetAllAlertMessage()
      setSuccessAlertMessage(
        t('src.pages.UserManage.userInvited', { email }),
      )
      loadData()
    } catch (e) {
      if (e.response.data.detail === 'This is user already exist') {
        setInfoAlertMessage(t('src.pages.UserManage.alreadyExist', { email }))
      }
    } finally {
      handleAddUserClose()
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    console.log(data)
    if (openForm === 'Update') {
      setLoading(true)
      const { updated_at, created_at, ...update_data } = data
      if (!update_data.id && !!update_data._id) {
        update_data.id = update_data._id  //Костыль для монго дб
      }
      try {
        await $authHost.patch(`/user/${data.id}/update`, update_data)
        loadData()
        setOpenForm('')
      } catch (e) {
        if (e.response.status === 400 && e.response.data.detail === 'Cannot disable the only admin') {
          setConfirmationId(null)
          setErrorAlertMessage(
              t('src.pages.UserManage.disableAdmin',
                  'Вы не можете отключить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.'))
        }
        if (e.response.status === 400 && e.response.data.detail === 'Cannot delete the only admin') {
          setConfirmationId(null)
          setErrorAlertMessage(
              t('src.pages.UserManage.removeAdmin',
                  'Вы не можете удалить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.'))
        }
        if (e.response.status === 400 && e.response.data.detail === 'Cannot remove User role') {
          setConfirmationId(null)
          setErrorAlertMessage(
              t('src.pages.UserManage.disableUser',
                  'Вы не можете отключить группу "Пользователь"!'))
        }
      } finally {
        setLoading(false)
      }
    } else if (openForm === 'Add') {
    }
  }

  async function handleDeleteRow (id_row) {
    setLoading(true)
    try {
      // await $authHost.delete(`/user/${id_row}/delete`)
      await $authHost.delete(`/user/${id_row}/delete`)
      loadData()
      if (id_row === selectedRow.id) {
        setOpenForm('')
      }
    } catch (e) {
      if (e.response.status === 400 && e.response.data.detail === 'Cannot delete the only admin') {
        setConfirmationId(null)
        setConfirmationForEditForm(null)
        setErrorAlertMessage(
            t('src.pages.UserManage.removeAdmin',
                'Вы не можете удалить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.'))
      }
    } finally {
      setLoading(false)
    }
  }

  function handleGoToEditPage (row) {
    //редактирование пользователя
    setSelectedRow(row)
    setOpenForm('Update')
  }

  async function handleIsRun (row, is_run) {
    //заблокировать пользователя
    setLoading(true)
    try {
      const { id, ...update_data } = row
      update_data.status = is_run ? 'Disabled' : (update_data.locked_flags === null ? 'Active' : 'Locked')
      await $authHost.patch(`/user/${id}/update`, update_data)
      loadData()
    } catch (e) {
      if (e.response.status === 400 && e.response.data.detail === 'Cannot disable the only admin') {
        setConfirmationId(null)
        setErrorAlertMessage(
            t('src.pages.UserManage.disablelastAdmin',
                'Вы не можете отключить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.'))
      }
    } finally {
      setLoading(false)
    }
  }

  const getDropdownData = useCallback(async () => {
    try {
      const response = await $authHost.get(`/group/get_all`)
      setGroups(response.data)
    } catch (e) {
    } finally {
    }
  }, [])

  const handleAddUser = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleAddUserClose = () => {
    reset({ email: '' })
    // setValue('email', '')
    setAnchorEl(null)
  }
  const openAddUser = Boolean(anchorEl)

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    getDropdownData()
  }, [getDropdownData])

  return (
    <>
      {loading && <LoadingSpinner/>}
      <>
        <ButtonStack>
          <Button
            onClick={handleAddUser}
            aria-haspopup="true"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill"/>}
            sx={{ mr: 3 }}
          >
            {t('src.pages.UserManage.inviteUser')}
          </Button>
          <Popover
            id={'addUser'}
            open={openAddUser}
            anchorEl={anchorEl}
            onClose={handleAddUserClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Paper sx={{ p: 2 }}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmitAddUser)}>
                  <Stack
                    direction="column"
                    spacing={0}
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                  >
                    <CustomTextField
                      name="email"
                      label={t('src.pages.UserManage.emailLabel')}
                      registerOptions={{
                        required: t('main.another.requiredField'),
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t('main.another.wrongInput'),
                        },
                      }}
                      sx={{ width: '100%', fontSize: '12px', mb: 3 }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t('src.pages.UserManage.sendInvitation')}
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            </Paper>
          </Popover>
        </ButtonStack>
        <AlertMessage/>
        {(openForm) &&
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ p: 3, mb: 2 }}>
                  <AddEditForm onSubmit={onSubmit}
                                   selectedRow={selectedRow}
                                   action={openForm}
                                   fieldsRef={fieldsRef}
                                   handleClose={() => setOpenForm('')}
                  >
                    <AccessUserFields autocompleteOptions={groups}/>
                    <AddEditButtonForm action={openForm}
                                            handleClose={() => setOpenForm('')}
                                            handleDeleteRow={() => handleDeleteRow(selectedRow.id)}
                                            confirmationForEditForm={confirmationForEditForm}
                                            setConfirmationForEditForm={setConfirmationForEditForm}
                    />
                  </AddEditForm>
                </Card>
              </Grid>
            </Grid>
        }
        <Card sx={{ pt: 1 }}>
          <MaterialTable data={users} columns={tableColumns}/>
        </Card>
      </>
    </>
  )
}
