import {all} from "redux-saga/effects"
import {SSEWatcher} from "./getSSESaga";
import { watchTokenChanges } from './authSaga'
import {SSEManagerWatcher} from "./getSSEManagerSaga";

export function* rootWatcher() {
    yield all([
        SSEWatcher(),
        SSEManagerWatcher(),
        watchTokenChanges(),
        // sandboxSSEWatcher()
    ])
}
