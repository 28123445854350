import { createSlice } from '@reduxjs/toolkit'
// import { LOG_OUT } from "../auth/authReducer";

const initialState = {
  selectedCustomer: null,
  dialog: [],
  chats: [],
  draftMessage: '',
  showScrollButton: false,
  foundChats: [],
  foundMessages: [],
  searchValue: '',
  searchInsideValue: '',
  searchMessage: null,
  OpenSearchInsideChat: false,
  flagFilter: false,
  favoriteFilter: false,
  waitFilter: false,
  handFilter: false,
  archFilter: false,
  unreadFilter: false,
  selectedChannels: [],
  showSearch: false
}

const dialoguesSlice = createSlice({
  name: 'dialogues',
  initialState,
  reducers: {
    logOut () {
      return initialState
    },
    setShowSearch (state, { payload }) {
      state.showSearch = payload
    },
    setChats (state, { payload }) {
      state.chats = payload
    },
    setFlagFilter (state, { payload }) {
      state.flagFilter = payload
    },
    setFavoriteFilter (state, { payload }) {
      state.favoriteFilter = payload
    },
    setWaitFilter (state, { payload }) {
      state.waitFilter = payload
    },
    setHandFilter (state, { payload }) {
      state.handFilter = payload
    },
    setArchFilter (state, { payload }) {
      state.archFilter = payload
    },
    setSelectedChannels (state, { payload }) {
      state.selectedChannels = payload
    },
    addChat (state, { payload }) {
      state.chats.unshift(payload)
    },
    changeFavoriteChat (state, { payload }) {
      const chatsIndex = state.chats.findIndex(
        (chats) => chats.id === payload,
      )
      const boolean_value = !state.chats[chatsIndex].is_favorite
      state.chats[chatsIndex].is_favorite = boolean_value
      state.selectedCustomer.is_favorite = boolean_value
    },
    setDialog (state, { payload }) {
      state.dialog = payload
    },
    setSelectedCustomer (state, { payload }) {
      state.selectedCustomer = payload
    },
    setFirstRead (state, { payload }) {
      state.selectedCustomer.firstRead = payload
    },
    // setFirstRead (state, { payload }) {state.selectedCustomer = { ...state.selectedCustomer, firstRead: payload }},
    setUnreadMessageIndex (state, { payload }) {
      state.selectedCustomer.unreadMessageIndex = payload
    },
    setSearchValue (state, { payload }) {
      state.searchValue = payload
    },
    setSearchInsideValue (state, { payload }) {
      state.searchInsideValue = payload
    },
    setFoundChats (state, { payload }) {
      state.foundChats = payload
    },
    setOpenSearchInsideChat (state, { payload }) {
      state.OpenSearchInsideChat = payload
    },
    setFoundMessages (state, { payload }) {
      state.foundMessages = payload
    },
    setSearchMessage (state, { payload }) {
      state.searchMessage = payload
    },
    setUnreadFilter (state, { payload }) {
      state.unreadFilter = payload
    },
    changeDraftMessage (state, { payload }) {
      state.draftMessage = payload
    },
    answerAdded (state, { payload }) {
      const chatsIndex = state.chats.findIndex(
        (chats) => chats.telegram_id === payload,
      )
      state.chats[chatsIndex].answerAwaiting = false
      state.chats[chatsIndex].callManager = false
    },
    setShowScrollButton (state, { payload }) {
      state.showScrollButton = payload
    },
    changeMessageFlag (state, { payload }) {
      const flag_index = state.dialog.findIndex(
        (mess) => mess.id === payload,
      )
      state.dialog[flag_index].is_favorite = !state.dialog[flag_index].is_favorite
    },
    ClearUnreadMessages (state, { payload }) {
      const find_index = state.chats.findIndex(
        (chat) => chat.id === payload,
      )
      state.chats[find_index].msg_count = 0
    },
    setReadMessage (state, { payload }) {
      const { id } = payload
      const Index = state.dialog.findIndex(
        (mess) => mess.id === id,
      )
      state.dialog[Index].is_read = true
      state.chats = state.chats.map((chat) => {
        const msg_count = chat.msg_count || 0
        return (chat.id === state.selectedCustomer?.id)
          ? {
            ...chat,
            msg_count: msg_count - 1,
          }
          : chat
      })
    },
    addNewMessage (state, { payload }) {
      const { customer, message } = payload;
      if(customer === null)
      {
        const { sender, text, recipient, is_read, channel_id } = message;
        const is_chat = state.chats.findIndex(
            chat => (chat.id === sender) || (chat.id === recipient),
        )
        const selected_chat = state.chats[is_chat] //current диалог
        if (is_chat > 0) {
          const item = state.chats[is_chat]
          state.chats.splice(is_chat, 1)
          state.chats.unshift(item)
        }
        state.dialog =
            (state.selectedCustomer?.id === selected_chat?.id)
                ? [...state.dialog, message]
                : state.dialog
        const this_chat = state.chats.findIndex(
            chat => (chat.id === sender) || (chat.id === recipient),
        )
        state.chats[this_chat].is_waiting_response = false;
      }
      else {
        const { is_favorite, is_handler_bot, is_waiting_response, count_unread_msg } = customer;
        const { sender, text, recipient, is_read, channel_id } = message;

        state.chats = state.chats.map((chat) => {
          const isRecipient = chat.id === recipient
          const isSender = chat.id === sender
          return ((isSender) || (isRecipient))
              ? {
                ...chat,
                last_message: text,
                is_favorite: is_favorite,
                is_handler_bot: is_handler_bot,
                is_waiting_response: is_waiting_response,
                msg_count: count_unread_msg
              }
              : chat
        })

        const is_chat = state.chats.findIndex(
            chat => (chat.id === sender) || (chat.id === recipient),
        )
        const selected_chat = state.chats[is_chat];
        if (is_chat > 0) {
          const item = state.chats[is_chat]
          state.chats.splice(is_chat, 1)
          state.chats.unshift(item)
        }
        state.dialog =
            (state.selectedCustomer?.id === selected_chat?.id)
                ? [...state.dialog, message]
                : state.dialog
        if(state.selectedCustomer?.id === selected_chat?.id){
          state.selectedCustomer = {...state.selectedCustomer, is_handler_bot: is_handler_bot}
        }
      }
    },
    updateManagerInfo (state, { payload }){
      // console.log("payload ", payload)
    }

  },
})

export const { actions: dialoguesActions, reducer: dialoguesReducer } = dialoguesSlice
// export const { addNewMessage, addChat } = dialoguesSlice
export default dialoguesSlice.reducer
